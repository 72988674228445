<!-- DESKTOP -->
<section class="container section">
  <div class="section__tile section__tile--first">
    <img src="/assets/testimonials/rectangle.svg" alt="">
    <p class="section__title" i18n="@@testimonialsTitle">To whom Mytitle helps to protect their work?</p>
    <a [href]="getBlog()" class="section__link" target="_blank"><span i18n="@@learnMore">Learn more</span>
      <img src="/assets/testimonials/arrow.svg" alt="blog link">
    </a>
  </div>
  <div class="section__tile section__tile--business">
    <a [href]="getEntrepreneurArticle()" rel="noreferrer" class="section__anchor" target="_blank">
      <p class="section__name" i18n="@@entrepreneur">Entrepreneur</p>
      <div class="section__overlay">
        <p class="section__description" i18n="@@entrepreneurText">
          Entrepreneurs protect their Business ideas, patents, industrial designs, program codes etc.
        </p>
      </div>
    </a>
  </div>
  <div class="section__tile section__tile--design">
    <a [href]="getDesignerArticle()" rel="noreferrer" class="section__anchor" target="_blank">
      <p class="section__name" i18n="@@designer">Designer</p>
      <div class="section__overlay">
        <p class="section__description" i18n="@@designerText">
          Designers protect their grafic design, web design, social media assets, logos etc.
        </p>
      </div>
    </a>
  </div>
  <div class="section__tile section__tile--musician">
    <a [href]="getMusicianArticle()" rel="noreferrer" class="section__anchor" target="_blank">
      <p class="section__name" i18n="@@musician">Musician</p>
      <div class="section__overlay">
        <p class="section__description" i18n="@@musicianText">
          Musicians protect their songs, melodies, tracks, clips etc.
        </p>
      </div>
    </a>
  </div>
  <div class="section__tile section__tile--writing">
    <a [href]="getWriterArticle()" rel="noreferrer" class="section__anchor" target="_blank">
      <p class="section__name" i18n="@@writer">Writer</p>
      <div class="section__overlay">
        <p class="section__description" i18n="@@writerText">
          Writers protect their books, poems, articles, screenplays etc.
        </p>
      </div>
    </a>
  </div>
  <div class="section__tile section__tile--entrepreneurship">
    <a [href]="getDeveloperArticle()" rel="noreferrer" class="section__anchor" target="_blank">
      <p class="section__name" i18n="@@developer">Developer</p>
      <div class="section__overlay">
        <p class="section__description" i18n="@@developerText">
          Developers protect their ideas, patents, industrial designs, code snippets etc.
        </p>
      </div>
    </a>
  </div>
</section>
<!-- MOBILE -->
<section class="mobile">
  <a class="mobile__tile" [href]="getBlog()">
    <img src="/assets/testimonials/rectangle.svg" alt="blog link" class="mobile__tile--first">
    <p class="mobile__title" i18n="@@testimonialsTitle">To whom Mytitle helps to protect their work?</p>
    <a [href]="getBlog()" class="mobile__link"><span i18n="@@learnMore">Learn more</span>
      <img src="/assets/testimonials/arrow.svg" alt="blog link">
    </a>
  </a>
  <div class="mobile__tile">
    <a [href]="getEntrepreneurArticle()">
      <img src="assets/testimonials/business.png" alt="business article" class="mobile__tile">
      <p class="mobile__name" i18n="@@entrepreneur">Entrepreneur</p>
    </a>
  </div>
  <div class="mobile__tile">
    <a [href]="getDesignerArticle()">
      <img src="assets/testimonials/design.png" alt="designer article" class="mobile__tile">
      <p class="mobile__name" i18n="@@designer">Designer</p>
    </a>
  </div>
  <div class="mobile__tile">
    <a [href]="getMusicianArticle()">
      <img src="assets/testimonials/music.png" alt="musician article" class="mobile__tile">
      <p class="mobile__name" i18n="@@musician">Musician</p>
    </a>
  </div>
  <div class="mobile__tile">
    <a [href]="getWriterArticle()">
      <img src="assets/testimonials/writing.png" alt="writer article" class="mobile__tile">
      <p class="mobile__name" i18n="@@writer">Writer</p>
    </a>
  </div>
  <div class="mobile__tile">
    <a [href]="getDeveloperArticle()">
      <img src="assets/testimonials/entrepreneurship.png" alt="developer article" class="mobile__tile">
      <p class="mobile__name" i18n="@@developer">Developer</p>
    </a>
  </div>
</section>