/**
 * My documents store module which is eager loaded upon app init
 */

import { Action } from '@ngrx/store';
import { FileAction } from 'src/app/app/states/actions/file.action';

import { MytitleError } from 'src/app/client/models/client.models';
import {
  Document,
  Folder,
  FolderPath,
  FolderWithChildren,
} from 'src/app/client/models/mytitle-service.models';
import {
  FolderAction,
  FolderActions,
} from 'src/app/pages/my-documents/store/actions/folder.actions';
import {
  ChangeDocumentAction,
  ChangeDocumentActions,
  LoadDocumentsAction,
  LoadDocumentsActions,
  LoadDocumentsSuccess,
} from '../actions/my-documents.actions';
import {
  SharingAction,
  SharingActions,
} from '../actions/sharing.actions';
import {
  SharingsState,
} from './sharing.reducer';

export interface MyDocumentsState {
  documents: Document[];
  documentsPagination: {
    count: number;
    offset: number;
    totalCount: number;
  };
  documentsLoading: boolean;
  additionalDocumentsLoading: boolean;
  rollbackCopies: { [guid: string]: Document };
  treeFolders: FolderWithChildren[];
  treeFoldersLoading: boolean;
  flatFolders: Folder[];
  flatFolderPath: FolderPath[];
  folderLoading: boolean;
  error: MytitleError[];
}

export const myDocumentsInitialState: MyDocumentsState = {
  documents: [],
  documentsPagination: {
    count: null,
    offset: null,
    totalCount: null,
  },
  documentsLoading: false,
  additionalDocumentsLoading: false,
  rollbackCopies: {},
  treeFolders: [],
  treeFoldersLoading: false,
  flatFolders: [],
  flatFolderPath: [],
  folderLoading: false,
  error: null,
};

export function myDocumentsReducer(
  state = myDocumentsInitialState,
  generic: Action,
): MyDocumentsState {
  const action = generic as LoadDocumentsAction |
                            ChangeDocumentAction |
                            FolderAction |
                            FileAction |
                            SharingAction;

  switch (action.type) {
    case (LoadDocumentsActions.LOAD_DOCUMENTS_START):
      if (action.payload.offset) return { ...state, additionalDocumentsLoading: true };
      else return { ...state, documentsLoading: true };

    case (LoadDocumentsActions.LOAD_DOCUMENTS_SUCCESS):
      return {
        ...state,
        documentsLoading: false,
        additionalDocumentsLoading: false,
        documents: getDocumentsState(state, action),
        documentsPagination: {
          count: action.payload.results_len,
          offset: action.payload.offset,
          totalCount: action.payload.total,
        },
      };

    case (LoadDocumentsActions.LOAD_DOCUMENTS_ERROR):
      return { ...state, documentsLoading: false };

    case (ChangeDocumentActions.CHANGE_DOCUMENT_START):
      const { guid, ...changes } = action.payload;
      const orig = state.documents.find(doc => doc.guid === guid);
      return {
        ...state,
        documents: state.documents
          .map(doc => doc.guid === guid ? { ...doc, ...changes } : doc),
        rollbackCopies: { ...state.rollbackCopies, [guid]: orig },
      };

    case (ChangeDocumentActions.CHANGE_DOCUMENT_SUCCESS):
      return {
        ...state,
        documents: state.documents
          .map(doc => doc.guid === action.payload.document.guid ? action.payload.document : doc),
      };

    case (SharingActions.SHARING_DIALOG_CLOSED):
      return {
        ...state,
        documents: updateDocumentShareStatus(state, action.payload),
        flatFolders: updateFolderShareStatus(state, action.payload),
      };

    case (ChangeDocumentActions.CHANGE_DOCUMENT_ERROR):
      return {
        ...state,
        error: action.payload,
      };

    case (FolderActions.GET_TREE_FOLDER_LIST_START):
      return { ...state, treeFoldersLoading: true };

    case (FolderActions.GET_TREE_FOLDER_LIST_SUCCESS):
      return { ...state, treeFoldersLoading: false, treeFolders: action.payload };

    case (FolderActions.GET_FLAT_FOLDER_LIST_START):
      return { ...state, folderLoading: true };

    case (FolderActions.GET_FLAT_FOLDER_LIST_SUCCESS):
      return {
        ...state,
        folderLoading: false,
        flatFolders: action.payload.folders,
        flatFolderPath: action.payload.folder_path,
      };

    case (FolderActions.GET_FOLDER_START):
      return { ...state, folderLoading: true };

    case (FolderActions.GET_FOLDER_SUCCESS):
      return { ...state, folderLoading: false, flatFolders: [action.payload] };

    case (LoadDocumentsActions.CLEAN_MY_DOCUMENTS):
      return myDocumentsInitialState;
  }
  return state;
}

function getDocumentsState(state: MyDocumentsState, action: LoadDocumentsSuccess) {
  // if returned request is already with offset check if there are alread some
  // documents in store and if they are concat those two arrays otherwise store returned one
  // if returned request has offset 0, wipe store and store these results
  if (action.payload.offset > 0) {
    return state.documents.length > 0 ?
      state.documents.concat(action.payload.results) :
      action.payload.results;
  } else return action.payload.results;
}

function updateDocumentShareStatus(
  state: MyDocumentsState, sharingDialogState: SharingsState): Document[] {

  if (sharingDialogState.documentGuid) {
    const documents = [...state.documents];
    const fileIndex = documents.findIndex(x => x.guid === sharingDialogState.documentGuid);

    if (!sharingDialogState.allSharings || !sharingDialogState.allSharings.length) {
      documents[fileIndex].is_shared = false;
    } else documents[fileIndex].is_shared = true;

    return documents;
  } else return state.documents;

}

function updateFolderShareStatus(
  state: MyDocumentsState, sharingDialogState: SharingsState): Folder[] {

  if (sharingDialogState.folderGuid) {
    const folders = [...state.flatFolders];
    const folderIndex = folders.findIndex(x => x.guid === sharingDialogState.folderGuid);

    if (!sharingDialogState.allSharings || !sharingDialogState.allSharings.length) {
      folders[folderIndex].is_shared = false;
    } else folders[folderIndex].is_shared = true;

    return folders;
  } else return state.flatFolders;
}
