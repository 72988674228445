import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FileUpdated } from '../states/actions/file.action';
import { DisplayFolderCreditConfirmation } from '../states/actions/upload-folder.actions';
import { FileExt } from '../states/reducers/upload-folder.reducer';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  public parentFolder: string;

  constructor( private store: Store<any>) {
  }

  public fileAdded(file: File, parentFolder: string = null) {
    this.store.dispatch(new FileUpdated({
      file,
      folder: parentFolder,
      is_free: false,
      uploadNow: true,
    }));
  }


  //TODO: check parent fodler functionality
  public folderUpload(files: FileExt[]) {
    this.store.dispatch(
      new DisplayFolderCreditConfirmation({
        parentGuid: this.parentFolder,
        files,
      }),
    );
  }

}
