/* eslint-disable max-len, max-classes-per-file */
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { CreatePaymentRequest, CreatePaymentResponse } from '../../../client/models/payment.models';

export enum PaymentActions {
  CREATE_PAYMENT_START = '[Payment] Create Start',
  CREATE_PAYMENT_SUCCESS = '[Payment] Create Success',
  CREATE_PAYMENT_ERROR = '[Payment] Create Error',
  CREATE_ISIC_PAYMENT_ERROR = '[Payment] Create ISIC Error',
  CREATE_ISIC_BUYING_STANDARD_ERROR = '[Payment] Create ISIC buying Standard Error',
}

export class CreatePaymentStart implements Action {
  public readonly type = PaymentActions.CREATE_PAYMENT_START;

  constructor(public payload: CreatePaymentRequest) {
  }
}

export class CreatePaymentSuccess implements Action {
  public readonly type = PaymentActions.CREATE_PAYMENT_SUCCESS;

  constructor(public payload: CreatePaymentResponse) {
  }
}

export class CreatePaymentError implements Action {
  public readonly type = PaymentActions.CREATE_PAYMENT_ERROR;

  constructor(public payload: HttpErrorResponse) {
  }
}

export class CreateISICPaymentError implements Action {
  public readonly type = PaymentActions.CREATE_ISIC_PAYMENT_ERROR;

  constructor(public payload: HttpErrorResponse) {
  }
}

export class CreateISICBuyingStandardError implements Action {
  public readonly type = PaymentActions.CREATE_ISIC_BUYING_STANDARD_ERROR;

  constructor(public payload: HttpErrorResponse) {
  }
}

export type PaymentAction = CreatePaymentStart | CreatePaymentSuccess | CreatePaymentError;
