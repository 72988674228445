import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { fromEvent, Observable, Subject, timer } from 'rxjs';
import { map, repeatWhen, takeUntil } from 'rxjs/operators';
import { UniversalService } from 'src/app/shared/universal/universal.service';

@Component({
  selector: 'mt-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
})
export class HowItWorksComponent implements AfterViewInit, OnDestroy {
  public selectedContainerId = 0;
  public carouselImages = [
    '/assets/how-it-works/timeline-first.png',
    '/assets/how-it-works/timeline-second.png',
    '/assets/how-it-works/timeline-third.png',
    '/assets/how-it-works/timeline-fourth.png',
    '/assets/how-it-works/timeline-fifth.png',
  ];
  private observable$: Observable<any>;
  private readonly _stop = new Subject<void>();
  private readonly _start = new Subject<void>();
  private readonly ngDestroy = new Subject<void>();
  private readonly interval = 3500;

  constructor(
    private universalService: UniversalService,
  ) {}

  public ngAfterViewInit() {
    if (!this.universalService.isPlatformServer()) {
    const element = document.getElementsByClassName('section__step');
    const mouseenter$ = fromEvent(element, 'mouseenter');
    const mouseleave$ = fromEvent(element, 'mouseleave');

    mouseenter$.subscribe(() => this.stop());
    mouseleave$.subscribe(() => this.start());
      this.observable$ = timer(this.interval, this.interval)
      .pipe(
        map(thing => thing as any),
        takeUntil(this._stop),
        repeatWhen(() => this._start),
      );

    this.observable$.subscribe(() => this.next());
    }
  }

  public selectContainer(selected: number) {
    this.selectedContainerId = selected;
  }

  public getCorrectImagePosition(index: number) {
    if (index === 0) {
      return 'section__image--first';
    } else if (index === 1) {
      return 'section__image--second';
    } else if (index === 2) {
      return 'section__image--third';
    } else if (index === 3) {
      return 'section__image--fourth';
    } else if (index === 4) {
      return 'section__image--fifth';
    } else return '';
  }

  public ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  private start(): void {
    this._start.next();
  }

  private stop(): void {
    this._stop.next();
  }

  private next() {
    if (this.selectedContainerId === 4) {
      this.selectedContainerId = 0;
    } else {
      this.selectedContainerId = this.selectedContainerId + 1;
    }
  }
}
