/* eslint-disable max-len */

import { Route } from '@angular/router';
import { FaqComponent } from '../pages/faq/faq.component';
import { AppRoutes } from './app.routes.misc';
import { HomeComponent } from './home/home.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ConfirmEmailGuardService } from './services/confirm-email-guard.service';
import { CreditFileGuardService } from './services/credit-file-guard.service';
import { DocumentGuardService } from './services/document-guard.service';
import { PreventAuthorizedVisitorGuardService } from './services/prevent-authorized-visitor.service';

/**
 * Root app routes.
 */
export const appRoutes: Route[] = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: {
        en: 'Mytitle | Blockchain notary',
        cs: 'Mytitle | Digitální notář',
      },
      description: {
        en: 'Mytitle provides encrypted document management and timestamps your files and ideas using the blockchain technology. Validate your ownership in just a minute!',
        cs: 'Mytitle poskytuje šifrovanou správu dokumentů a časová razítka vašich souborů a nápadů pomocí technologie blockchain. Ověřte své vlastnictví ještě dnes!',
      },
    },
  },
  {
    path: AppRoutes.MyDocuments,
    loadChildren: () => import('../pages/my-documents/my-documents.module').then(m => m.MyDocumentsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: AppRoutes.FAQ,
   component: FaqComponent,
  },
  {
    path: AppRoutes.SignUp,
    loadChildren: () => import('../pages/sign-up/sign-up.module').then(m => m.SignUpModule),
    canActivate: [PreventAuthorizedVisitorGuardService],
  },
  {
    path: AppRoutes.Login,
    loadChildren: () => import('../pages/login/login.module').then(m => m.LoginModule),
    canActivate: [PreventAuthorizedVisitorGuardService],
  },
  {
    path: AppRoutes.Settings,
    loadChildren: () => import('../pages/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: AppRoutes.EmailConfirmation,
    loadChildren: () => import('../pages/email-confirmation/email-confirmation.module').then(m => m.EmailConfirmationModule),
    canActivate: [ConfirmEmailGuardService],
  },
  {
    path: AppRoutes.CreditBundleChoice,
    loadChildren: () => import('../pages/credit-bundle-choice/credit-bundle-choice.module').then(m => m.CreditBundleChoiceModule),
    canActivate: [CreditFileGuardService],
  },
  {
    path: AppRoutes.CreditOrderSummary,
    loadChildren: () => import('../pages/credit-order-summary/credit-order-summary.module').then(m => m.CreditOrderSummaryModule),
    canActivate: [CreditFileGuardService],
  },
  {
    path: AppRoutes.PaymentConfirmation,
    loadChildren: () => import('../pages/payment-confirmation/payment-confirmation.module').then(m => m.PaymentConfirmationModule),
    canActivate: [AuthGuardService],
  },
  {
    path: AppRoutes.DocumentProgress,
    loadChildren: () => import('../pages/document-progress/document-progress.module').then(m => m.DocumentProgressModule),
    canActivate: [DocumentGuardService, AuthGuardService],
  },
  {
    path: AppRoutes.Verification,
    loadChildren: () => import('../pages/verification/verification.module').then(m => m.VerificationModule),
  },
  {
    path: AppRoutes.DocumentCredentials,
    loadChildren: () => import('../pages/document-credentials/document-credentials.module').then(m => m.DocumentCredentialsModule),
    canActivate: [AuthGuardService, DocumentGuardService],
  },
  {
    path: AppRoutes.FolderCredentials,
    loadChildren: () => import('../pages/folder-credentials/folder-credentials.module').then(m => m.FolderCredentialsModule),
    canActivate: [AuthGuardService, DocumentGuardService],
  },
  {
    path: AppRoutes.FolderUploadConfirmation,
    loadChildren: () => import('../pages/folder-upload-confirmation/folder-upload-confirmation.module').then(m => m.FolderUploadConfirmationModule),
    canActivate: [AuthGuardService],
  },
  {
    path: AppRoutes.FolderUploadCredentials,
    loadChildren: () => import('../pages/folder-upload-credentials/folder-upload-credentials.module').then(m => m.FolderUploadCredentialsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: AppRoutes.FolderUploadProgress,
    loadChildren: () => import('../pages/folder-upload-progress/folder-upload-progress.module').then(m => m.FolderUploadProgressModule),
    canActivate: [AuthGuardService],
  },
  {
    path: AppRoutes.DeletionDocumentsConfirm,
    loadChildren: () => import('../pages/delete-documents-confirm/delete-documents-confirm.module').then(m => m.DeleteDocumentsConfirmModule),
    canActivate: [AuthGuardService],
  },
  {
    path: AppRoutes.WatermarkSelection,
    loadChildren: () => import('../pages/watermark-selection/watermark-selection.module').then(m => m.WatermarkSelectionModule),
    canActivate: [AuthGuardService],
  },
  {
    path: AppRoutes.About,
    loadChildren: () => import('../pages/about/about.module').then(m => m.AboutModule),
  },
  {
    path: AppRoutes.ResetPassword,
    loadChildren: () => import('../pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: AppRoutes.ResetPasswordConfirm,
    loadChildren: () => import('../pages/reset-password-confirm/reset-password-confirm.module').then(m => m.ResetPasswordConfirmModule),
  },
  {
    path: AppRoutes.Error,
    loadChildren: () => import('../pages/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: AppRoutes.NotFound,
    loadChildren: () => import('../pages/error/error.module').then(m => m.ErrorModule),
  },
];
