import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { getRegistered } from 'src/app/auth/store/selectors/register.selector';
import { LoginErrorsEnum } from '../../auth/store/models/user';
import { getLoginError } from '../../pages/login/store/login.selector';
import { AppRoutes } from '../app.routes.misc';

@Injectable({
  providedIn: 'root',
})
export class ConfirmEmailGuardService implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<any>,
  ) {
  }

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store.pipe(
      switchMap(() => forkJoin([
          this.store.select(getRegistered).pipe(first()),
          this.store.select(getLoginError).pipe(first()),
        ])),
      map(([registered, loginError]) => {
        if (loginError && loginError[0].code === LoginErrorsEnum.REGISTRATION_NOT_FINISHED) {
          return true;
        } else if (registered) {
          localStorage.removeItem('campaign_code');
          return true;
        } else {
          return this.router.createUrlTree(['/', AppRoutes.SignUp]);
        }
      }),
    );
  }
}
