import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  CreditOrderTokenResponse,
  DownloadCertificateTokenResponse,
} from '../models/render-service.models';

@Injectable()
export class RenderServiceClient {

  private serviceUrl = '/api/v1/render';

  constructor(private http: HttpClient) { }

  /*
  * Download token for file certificate
  */
  public getDownloadToken(guid: string): Observable<DownloadCertificateTokenResponse> {
    return this.http
      .get<DownloadCertificateTokenResponse>
      (`${this.serviceUrl}/certificate/${guid}/get_download_token`);
  }

  /*
  * Download token for folder certificate
  */
  public getDownloadFolderToken(folder_guid: string): Observable<DownloadCertificateTokenResponse> {
    return this.http
      .get<DownloadCertificateTokenResponse>
      (`${this.serviceUrl}/folder_certificate/${folder_guid}/get_download_token`);
  }

  /*
  * Download token for credit order receipt
  */
  public getCreditOrderDownloadToken(guid: string):
    Observable<CreditOrderTokenResponse> {
    return this.http.get<CreditOrderTokenResponse>(`/api/v1/render/credit-order-receipt/${guid}/get_download_token`);
  }
}
