import { Component } from '@angular/core';
import { UniversalService } from 'src/app/shared/universal/universal.service';

@Component({
  selector: 'mt-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent {
  public isFinished = false;
  public readonly mediumPinNumberArray = [...Array(19).keys()];
  public readonly largePinNumberArray = [...Array(4).keys()];
  public readonly smallPinNumberArray = [...Array(16).keys()];

  constructor(
    public uniService: UniversalService,
  ) {}

  public isInViewport(element: Element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

}
