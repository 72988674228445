<div class="section">
  <img [src]="carouselImages[selectedContainerId]" alt="" class="section__image">
  <div class="section__column container">
    <h3 class="section__title">{{ carouselHeadline[selectedContainerId] }}</h3>
    <mat-tab-group [disableRipple]="true" (selectedTabChange)="selectContainer($event)">
      <mat-tab [label]="hashtags[0]" class="section__subtitle">
        <p class="section__text" i18n="@@blogOne">The moment a new idea finds its form is something that creatives live for.
          Let's make sure that the original idea stays protected.</p>
      </mat-tab>
      <mat-tab [label]="hashtags[1]" class="section__subtitle">
        <p class="section__text" i18n="@@blogTwo">Whether it is possible to copyright ideas and how to protect your work from copyright infringement.</p>
      </mat-tab>
      <mat-tab [label]="hashtags[2]" class="section__subtitle">
        <p class="section__text" i18n="@@blogThree">Has someone else taken credits for your creations? Yes.
          Will you be able to prove that that was actually yours to start with?</p>
      </mat-tab>
      <mat-tab [label]="hashtags[3]" class="section__subtitle">
        <p class="section__text" i18n="@@blogFour">Wandering in your inner bubble of creativity?
          Take care of the safety of your artwork so you can give it further purpose.</p>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
