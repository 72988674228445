import { createSelector } from '@ngrx/store';
import { getAuthState } from '../auth.reducers';

export const getRegisterState = createSelector(
  getAuthState,
  state => state.register,
);

export const getRegistering = createSelector(
  getRegisterState,
  state => state.registering,
);

export const getRegistered = createSelector(
  getRegisterState,
  state => state.registered,
);

export const getRegisterError = createSelector(
  getRegisterState,
  state => state.registerError,
);

export const getRegisterEmail = createSelector(
  getRegisterState,
  state => state.email,
);
