import { Component, Input } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppRoutes } from 'src/app/app/app.routes.misc';
import { AppState } from 'src/app/app/states/models/app.models';
import { Logout } from 'src/app/auth/store/actions/logout.action';
import {
  getCreditBalance,
  getIsAuthorized,
} from 'src/app/auth/store/selectors/user.selector';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { UniversalService } from 'src/app/shared/universal/universal.service';

@Component({
  selector: 'mt-navbar-mobile',
  templateUrl: './navbar-mobile.component.html',
  styleUrls: ['./navbar-mobile.component.scss'],
})
export class NavbarMobileComponent {

  @Input() public initializeHeaderElement = false;
  public readonly appRoutes = AppRoutes;
  public readonly isLoggedIn: Observable<boolean>;
  public readonly creditBalance: Observable<number>;
  private _expanded = false;

  constructor(
    private store: Store<AppState>,
    private uniService: UniversalService,
    private enviService: EnvironmentService,
  ) {
    this.isLoggedIn = this.store.pipe(select(getIsAuthorized));
    this.creditBalance = this.store.pipe(select(getCreditBalance));
  }

  get expanded() {
    return this._expanded;
  }

  set expanded(value: boolean) {
    this._expanded = value;
  }

  public toggleNavbar() {
    this._expanded = !this._expanded;
  }

  public logout() {
    this.store.dispatch(new Logout());
  }

  public getBusiness() {
    if (!this.uniService.isPlatformServer()) {
      if (location.hostname.includes('localhost') || location.hostname.includes('dev')) {
        return 'https://business.dev2.mytitle.com';
      } else {
        return 'https://business.mytitle.com';
      }
    } else {
      return 'https://business.mytitle.com';
    }
  }

  public getBlog() {
    if (!this.uniService.isPlatformServer()) {
      if (this.enviService.isCzechEnvironment) {
        return 'https://mytitle.cz/blog/';
      } else {
        return 'https://mytitle.com/blog/';
      }
    } else {
      return 'https://mytitle.com/blog/';
    }
  }
}
