import { DEFAULT_ROUTER_FEATURENAME, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterStateUrl } from '../models/app.models';

export const getRouterFeautureState
  = createFeatureSelector<RouterReducerState<RouterStateUrl>>(DEFAULT_ROUTER_FEATURENAME);

export const getRouterState = createSelector(
  getRouterFeautureState,
  ({ state }) => state,
);
