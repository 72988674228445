/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { CreateFolderOrderError, FileOrderResponse } from 'src/app/client/models/order.models';

export enum OrderFileActions {
  CREATE_FILE_ORDER = '[File order] Create',
  FILE_ORDER_SUCCESS = '[File order] Success',
  FILE_ORDER_ERROR = '[File order] Error',
  CLEAR_FILE_ORDER = '[File order] Clear',
  HIGHLIGHT_UPDATE = '[File order] Highlight update',
}

export class CreateFileOrder implements Action {
  public readonly type = OrderFileActions.CREATE_FILE_ORDER;
}

export class FileOrderSuccess implements Action {
  public readonly type = OrderFileActions.FILE_ORDER_SUCCESS;
  constructor(public payload: FileOrderResponse) {}
}

export class FileOrderError implements Action {
  public readonly type = OrderFileActions.FILE_ORDER_ERROR;
  constructor(public payload: CreateFolderOrderError) {}
}

export class ClearFileOrder implements Action {
  public readonly type = OrderFileActions.CLEAR_FILE_ORDER;
}

export class HighlightUpdate implements Action {
  public readonly type = OrderFileActions.HIGHLIGHT_UPDATE;
  constructor(public payload: boolean) {}
}

export type OrderFileAction = CreateFileOrder |
  FileOrderSuccess |
  FileOrderError |
  ClearFileOrder |
  HighlightUpdate;
