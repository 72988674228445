import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as Sentry from '@sentry/angular-ivy';
import { forkJoin } from 'rxjs';
import { filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { UserPricingChanged } from 'src/app/app/states/actions/credit-bundle-choice.actions';
import { getGeoIPState, getUserState } from 'src/app/auth/store/selectors/user.selector';
import { UsersServiceClient } from 'src/app/client/services/users-service.client';
import { VideoDialogComponent } from 'src/app/partials/video-dialog/video-dialog.component';
import { ErrorHelperService } from 'src/app/shared/services/error-helper.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { CheckFreeLimit } from '../actions/file-limit.action';
import { GetUserInfo, GetUserInfoActions, GetUserInfoSuccess,
  OpenVideoDialog, VideoDialogClosed } from '../actions/getUserInfo.action';
import { LocationVerified } from '../actions/locationVerify';
import { UserCurrencyChange } from '../models/user';
import { UserState } from '../reducers/user.reducer';

@Injectable()
export class GetUserInfoEffects {

  public getUserInfo = createEffect(() => this.storeActions.pipe(
    ofType<GetUserInfo>(GetUserInfoActions.GET_USER_INFO),
    switchMap(() => this.usersServiceClient.getUserInfo()
      .pipe(
        map(({
               country,
               email,
               full_name: fullName,
               isic_number: isicNumber,
               isic_expiration: isicExpiration,
               is_valid_academy: isValidAcademy,
               is_eu,
               two_factor_auth,
               affiliate_code,
             }) => new GetUserInfoSuccess({
          country,
          email,
          fullName,
          isicNumber,
          isicExpiration,
          isValidAcademy,
          is_eu,
          two_factor_auth,
          affiliate_code,
        })),
        this.errorHelperService.raiseError(),
      ),
    ),
  ));

  /**
   * Effect which is being proccessed only when getUserInfo end-point
   * is called right after login operation
   */

  public readonly verifyUserLocalization = createEffect(() => this.storeActions.pipe(
    ofType<GetUserInfoSuccess>(GetUserInfoActions.GET_USER_INFO_SUCCESS),
    switchMap(() => forkJoin([
        this.store.select(getUserState).pipe(first()),
        this.store.select(getGeoIPState).pipe(first()),
      ])),
    filter(([userState]) => userState.user.isInLoginProcess),
    tap( ([userState]) => {
      Sentry.configureScope(scope => {
        scope.setUser({ email: userState.user.email });
      });
    }),
    map(([userState, geoState]) => {
      let userPricingChanged = false;

      if (geoState.is_eu && !userState.user.is_eu) {
        userPricingChanged = true;
        this.snackbarService.queueSnackBar(UserCurrencyChange.FROM_EUR_TO_USD);
      }
      if (!geoState.is_eu && userState.user.is_eu) {
        userPricingChanged = true;
        this.snackbarService.queueSnackBar(UserCurrencyChange.FROM_USD_TO_EUR);
      }

      return userPricingChanged;
    }),
    // to clean-up store and isInLoginProcess property after login
    mergeMap(userPricingChanged => {
      if (userPricingChanged) {
        return [
          new UserPricingChanged(),
          new LocationVerified(),
        ];
      } else return [new LocationVerified()];
    }),
  ));


  public readonly getLimitCheck = createEffect(() => this.storeActions.pipe(
    ofType<GetUserInfoSuccess>(GetUserInfoActions.GET_USER_INFO_SUCCESS),
    map(() => new CheckFreeLimit()),
  ));


  public readonly openVideoDialog = createEffect(() => this.storeActions.pipe(
    ofType<OpenVideoDialog>(GetUserInfoActions.OPEN_VIDEO_DIALOG),
    switchMap(() => this.matDialog
      .open<VideoDialogComponent>(VideoDialogComponent)
      .afterClosed()),
    map(() => new VideoDialogClosed()),
  ));

  constructor(
    private store: Store<UserState>,
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
    private errorHelperService: ErrorHelperService,
    private snackbarService: SnackbarService,
    private matDialog: MatDialog,
  ) {
  }
}
