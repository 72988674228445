/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { GetLimitExceededResponse } from 'src/app/client/models/mytitle-service.models';

export enum LimitActions {
  CHECK_FREE_LIMIT = '[limit] check free limit',
  CHECK_FREE_LIMIT_SUCCESS = '[limit] check free limit success',
  FREE_LIMIT_WARNING_SHOWN = '[limit] free limit warning shown',
}

export class CheckFreeLimit implements Action {
  public readonly type = LimitActions.CHECK_FREE_LIMIT;
}

export class CheckFreeLimitExceededSuccess implements Action {
  public readonly type = LimitActions.CHECK_FREE_LIMIT_SUCCESS;
  constructor(public payload: GetLimitExceededResponse) {
  }
}

export class FreeLimitWarningShown implements Action {
  public readonly type = LimitActions.FREE_LIMIT_WARNING_SHOWN;
}

export type LimitAction = CheckFreeLimit | CheckFreeLimitExceededSuccess | FreeLimitWarningShown;
