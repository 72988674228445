import { createSelector } from '@ngrx/store';
import { getAuthState } from '../auth.reducers';

export const getUserState = createSelector(
  getAuthState,
  state => state.user,
);

export const getAuthToken = createSelector(
  getUserState,
  userState => userState.token,
);

export const getIsAuthorized = createSelector(
  getAuthToken,
  token => !!token,
);

export const getFullName = createSelector(
  getUserState,
  state => state.user && state.user.fullName,
);

export const getIsicNumber = createSelector(
  getUserState,
  state => state.user && state.user.isicNumber,
);

export const getIsicExpiration = createSelector(
  getUserState,
  state => state.user && state.user.isicExpiration,
);

export const getIsValidAcademy = createSelector(
  getUserState,
  state => state.user && state.user.isValidAcademy,
);


export const getUserInfo = createSelector(
  getUserState,
  state => state.user,
);

export const getIsLoadingUserInfo = createSelector(
  getUserState,
  state => state.isLoadingUser,
);

export const getEmail = createSelector(
  getUserState,
  state => state.user && state.user.email,
);

export const getCreditBalance = createSelector(
  getUserState,
  state => state.credit,
);

export const getGeoIPState = createSelector(
  getUserState,
  state => state.geoIP,
);

export const getIsEU = createSelector(
  getUserState,
  state => {
    if (state.user) return state.user.is_eu;
    else if (state.geoIP) return state.geoIP.is_eu;
    else return false;
  },
);

export const hasUserEnabledTwoFA = createSelector(
  getUserState,
  state => {
    if (state.user) return state.user.two_factor_auth;
    else return false;
  },
);

export const limitExceededState = createSelector(getUserState, state => state.limitExceeded);
