<div (click)="clicked($event)" class="h-100 w-100">
  <input type="file"
         #folderInput
         webkitdirectory
         mozdirectory
         msdirectory
         odirectory
         directory
         (change)="folderChanged($event.target.files); $event.preventDefault()"/>
  <ng-content></ng-content>
</div>