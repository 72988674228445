/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import {
  CreateCreditOrderParams,
  GetCreditOrderConfigResponse,
} from 'src/app/client/models/credit.models';

export enum CreditBundleChoiceActions {
  USER_PRICING_CHANGED = '[credit] User pricing changed due to localization',
  REPEAT_CREDIT_CHOICE = '[credit] Redirecting back to credit bundle choice page',
  GET_PRICE_RANGE = '[credit] Requesting price range',
  GET_PRICE_RANGE_SUCCESS = '[credit] Successfully retreived price range',
  GET_CREDIT_BUNDLES = '[credit] Requesting credit bundles',
  GET_CREDIT_BUNDLES_SUCCESS = '[credit] Successfully retreived credit bundles',
  GET_CREDIT_ORDER_SUMMARY = '[credit] Successfully retreived credit order summary',
}

export class UserPricingChanged implements Action {
  public readonly type = CreditBundleChoiceActions.USER_PRICING_CHANGED;
}

export class RepeatCreditChoice implements Action {
  public readonly type = CreditBundleChoiceActions.REPEAT_CREDIT_CHOICE;
}

export class GetPriceRange implements Action {
  public readonly type = CreditBundleChoiceActions.GET_PRICE_RANGE;
}

export class GetPriceRangeSuccess implements Action {
  public readonly type = CreditBundleChoiceActions.GET_PRICE_RANGE_SUCCESS;
  constructor(public payload: string[]) {}
}

export class GetCreditBundles implements Action {
  public readonly type = CreditBundleChoiceActions.GET_CREDIT_BUNDLES;
}

export class GetCreditBundlesSuccess implements Action {
  public readonly type = CreditBundleChoiceActions.GET_CREDIT_BUNDLES_SUCCESS;
  constructor(public payload: GetCreditOrderConfigResponse) {}
}

export class GetCreditOrderSummary implements Action {
  public readonly type = CreditBundleChoiceActions.GET_CREDIT_ORDER_SUMMARY;
  constructor(public payload: CreateCreditOrderParams) {
  }
}

export type CreditBundleChoiceAction =
  GetCreditBundles |
  GetCreditBundlesSuccess |
  GetCreditOrderSummary |
  UserPricingChanged |
  RepeatCreditChoice |
  GetPriceRange |
  GetPriceRangeSuccess;
