import { NgModule } from '@angular/core';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';

@NgModule({
  declarations: [PaymentOptionsComponent],
  imports: [
  ],
  exports: [
    PaymentOptionsComponent,
  ],
})
export class PaymentOptionsModule { }
