<h4 mat-dialog-title i18n="@@confirmation">Confirmation</h4>

<div class="confirmation__content" mat-dialog-content>
  {{ text }}
</div>

<div mat-dialog-actions class="confirmation__actions">
  <button
    (click)="sendResult(false)"
    mat-button
    type="button"
    i18n="@@cancel">
    Cancel
  </button>
  <button
    (click)="sendResult(true)"
    mat-button
    type="button"
    i18n="@@confirm">
    Confirm
  </button>
</div>
