/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';

import {
  FolderWithChildren,
  GetFlatFolderListParams,
  GetFlatFolderListResponse,
  GetTreeFolderListParams,
} from 'src/app/client/models/mytitle-service.models';

export enum SharedFolderActions {
  GET_TREE_FOLDER_LIST_START = '[Shared folders] Get tree folder list Start',
  GET_TREE_FOLDER_LIST_SUCCESS = '[Shared folders] Get tree folder list Success',

  GET_FLAT_FOLDER_LIST_START = '[Shared folders] Get flat folder list Start',
  GET_FLAT_FOLDER_LIST_SUCCESS = '[Shared folders] Get flat folder list Success',
}

export class GetSharedTreeFolderListStart implements Action {
  public readonly type = SharedFolderActions.GET_TREE_FOLDER_LIST_START;
  constructor(public payload: GetTreeFolderListParams) { }
}

export class GetSharedTreeFolderListSuccess implements Action {
  public readonly type = SharedFolderActions.GET_TREE_FOLDER_LIST_SUCCESS;
  constructor(public payload: FolderWithChildren[]) { }
}

export class GetSharedFlatFolderListStart implements Action {
  public readonly type = SharedFolderActions.GET_FLAT_FOLDER_LIST_START;
  constructor(public payload: GetFlatFolderListParams) { }
}

export class GetSharedFlatFolderListSuccess implements Action {
  public readonly type = SharedFolderActions.GET_FLAT_FOLDER_LIST_SUCCESS;
  constructor(public payload: GetFlatFolderListResponse) { }
}

export type SharedFolderAction = GetSharedTreeFolderListStart |
                           GetSharedTreeFolderListSuccess |
                           GetSharedFlatFolderListStart |
                           GetSharedFlatFolderListSuccess;
