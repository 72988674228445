export interface QuestionAndAnswer {
  question: string;
  answer: string;
}

 export const myTitleServiceFAQEn: QuestionAndAnswer[] = [
  {
    question: 'What service do you provide?',
    answer: 'Mytitle provides digital proof of your data ownership. Meaning, it helps to protect your intellectual property.',
  },
  {
    question: 'How does it work? ',
    answer: 'Simply log in to your Mytitle account. Uploaded files will be stored in our storage and encrypted right away. After the comprised detail of  your files is submited to the Litecoin blockchain, your ownership certificate gets generated. All is done in just a few minutes.',
  },
  {
    question: 'What is the output of your service?',
    answer: 'A lifelong certificate, secured backup storage of your files for 10 years where you can share all your files and send verification links from.',
  },
  {
    question: 'Will my files be stored forever?',
    answer: 'No, we are obliged to maintain the storage for 10 years but information about your stored data will be trackable on Litecoin forever.',
  },
  {
    question: 'Where do my documents "go" after trial period?',
    answer: 'We can\'t recover your files after that period of time. But the certificates are valid basically forever and can be found on your email address. For keeping your files in Mytitle, please upgrade your file before the trial period ends',
  },
  {
    question: 'How many files can I upload at once?',
    answer: 'It´s even possible to upload the whole folder. You will get certificates not just for the folder with files but also for each file inside.',
  },
];

export const protectionFAQEn: QuestionAndAnswer[] = [
  {
    question: 'What does Mytitle protect?',
    answer: 'Mytitle helps to protect one\'s ownership of any creation, therefore your claim of ownership of a particular subject.',
  },
  {
    question: 'Why should I protect my files?',
    answer: 'Anytime you share your work publicly or with a second party there is always a danger of copyright infringement. If you want to prevent yourself from losing the claim to your own property, you should certify your ownership first. That way you can safely share your work with the world.',
  },
  {
    question: 'What could be the subject of protection?',
    answer: 'It could be filed contracts, scripts, research data, porfolios, trade secrets and know-hows, scanned documents of graphic content like fashion designs, architectural sketches, videos, etc. Basicaly any form of digital documents.',
  },
  {
    question: 'What form of a document can be protected?',
    answer: 'Anything.\n' +
      'For example: .doc, .pdf, .vcf, .zip, .mp4, .mov, .sol, .odt, .html, to .wav but also .py, .js, .css, .cpp, .c, .h, .java, .hs, .collada, .fbx and others.\n' +
      'You can also submit a file without any suffix. We will manage.',
  },
  {
    question: 'How can I securely share the subjected documents?',
    answer: 'Before your uploaded documents reach the storage, they are encrypted.\n' +
      'So your data is protected from the very beginning of the process.',
  },
  {
    question: 'Can Mytitle steal my intellectual property?',
    answer: 'Nobody from Mytitle has access to your files or the content.\n' +
      'It\'s just not possible.',
  },
];

export const blockchainFAQEn: QuestionAndAnswer[] = [
  {
    question: 'What is blockchain?',
    answer: 'It´s a decentralized technology of distributed ledger improving many areas of digital world. It´s used for crypto payment and software applications.',
  },
  {
    question: 'What is Litecoin blockchain?',
    answer: 'Litecoin blockchain is decentralized, immutable and transparent platfom where applications are build. It tracks all records submitted and the detail of your "signed" data is built into it. Here, nobody can change it or extract it.',
  },
  {
    question: 'Why is blockchain involved?',
    answer: 'It´s the only platform where we can securely incorporate your data to receive an immutable proof that you hold a digital document at certain time & date.',
  },
];

export const ownershipCertificationFAQEn: QuestionAndAnswer[] = [
  {
    question: 'How long until getting my ownership certification?',
    answer: 'Minutes. Simply register yourself, upload your document, fill in the important info and wait a few minutes to receive the certificate. It takes about 10 minutes and your documents are ready to share.',
  },
  {
    question: 'Can I submit my website portfolio for certification?',
    answer: 'Only in case your portfolio hasn\'t gone public or hasn\'t been shared with any other party. The onwnership certificaton makes sense only if subject hasn\'t been previously exposed.',
  },
  {
    question: 'Can I use the certificate in a legal dispute?',
    answer: 'Of course. There are many countries which confirm blockchain timestamp as a legal proof. Other countries haven\'t incorporated blockchain in their legal ground yet, but the information from certificate can work as an indirect proof of your authorship and ownership. See more information on our blog.',
  },
  {
    question: 'Can other person verify my IP ownership?',
    answer: 'Anybody can verify that your files are really yours in case of doubts. Either 1) share the verification link connected to the file with the person, or 2) send the hash code from certificate (or send the whole certificate) to that person so they can verify it themselves.',
  },
];

export const questionsAndAnswersCS: QuestionAndAnswer[] = [
  {
    question: 'Jak bezpečné je vaše úložiště?',
    answer: 'Vaše bezpečnost je naše nejvyšší priorita. Proto je každý nově nahraný soubor, a to hned ve chvíli kdy dorazí na náš server, důkladně zašifrován speciálním klíčem, teprve pak Mytitle soubor uloží. Klíč je poté uschován do našeho vysoce zabezpečeného Vaultu. Tak je nahraný soubor zajištěn a chráněn před potenciálním bezpečnostním narušením.',
  },
  {
    question: 'Kdo má přístup k mému souboru?',
    answer: 'Obsah Vašeho souboru je zcela nedostupný veřejnosti. Mytitle využívá blockchain technologii, jež je založena na vzájemně propojených data blocích a šifrování dat. Je zcela nemožné tento systém narušit a zpřístupnit Vaše soubory. Pokud však chcete sami dokázat vlastnictví nahraných souborů, bez sdílení jejich obsahu, lze jednoduše sdílet Váš certifikát. Certifikát zpřístupňuje data jako např. kdy byl soubor nahrán nebo kdo je jeho majitelem. Pokud byste chtěli sdílet samotný soubor, je to také možné. Stačí určit příjemce a nastavit časové rozmezí, ve kterém mohou příjemci soubor otevřít. Jakmile čas vyprší, přístupové okno se zavře.',
  },
  {
    question: 'Mohu si ponechat klíč sám pro sebe?',
    answer: 'Zákazníci si v tuto chvíli nemají možnost klíč ponechat. Toto bezpečnostní opatření bylo implementováno z důvodu ochrany klíče a předejití jeho možné ztráty a tedy i nenávratné ztráty uložených souborů.',
  },
  {
    question: 'K čemu slouží "Certificate of Holding of the Digital File"?',
    answer: 'Certifikát Vám poskytuje informace potřebné k prokázání vlastnictví zaznamenaných dat. Obsahuje datum a čas nahrání, unikátní identifikaci souboru a návod, jak si zobrazit tato identifikační data na samotném blockchainu. To vám umožňuje dokázat, že určitý soubor byl opravdu ve vašem držení a vámi nahrán v určitém čase. A díky povaze blockchain technologie, jsou tato data neměnitelná.',
  },
  {
    question: 'Může si někdo přečíst má osobní data uložená na blockchainu?',
    answer: 'Ne! Vaše osobní data jsou na blockchainu viditelná pouze jako hash (unikátní kód). Vaše osobní informace jako jméno atd. jsou viditelné pouze na Vaší kopii certifikátu, kde také najdete zmíněný hash kód. Pokud by někdo chtěl ověřit autenticitu certifikátu, stačí vyhledat hash kód na něm napsaný a poté porovnat klíčová data jako datum nahrání souboru. Ale v žádném případě se nikdy nezobrazí Vaše soukromá data.',
  },
  {
    question: 'Co kdybych musel potvrdit vlastnictví souboru u soudu?',
    answer: 'Mytitle certifikát dokazuje, že jste právě vy měli v držení určitá data, v určitou chvíli. Taková informace může významně pomoci dokázat, že jste byli původními majiteli dávno předtím, než byla data neprávem jakkoli zneužita. My poskytujeme deklarované vlastnictví a nezávislé ověření Vašeho souboru. Vaším úkolem je pak dokázat, že právě vy vlastníte autorská práva k souboru, který jste na Mytitle nahráli. Úzce spolupracujeme s mezinárodním týmem právníků a moc rádi vám pomůžeme, pokud bude potřeba.',
  },
];


export const faqStructure = [myTitleServiceFAQEn, protectionFAQEn, blockchainFAQEn, ownershipCertificationFAQEn];
