<section>
  <video #video
         (canplay)="video.play()"
         (loadedmetadata)="video.muted = true"
         loop
         playsinline
         muted
         class="video">
    <source src="/assets/videos/header-video.mp4" type="video/mp4">
  </video>
  <img src="/assets/quoted-header/mobile-bg.png" alt="" class="mobile-bg">
  <div class="overlay"></div>
  <div class="section">
    <div>
      <h1 class="section__title">
        <span class="section__title section__title--first" i18n="@@headerOne">Intellectual property protection</span>
        <span i18n="@@headerTwo">& Ownership certification of your files</span>
      </h1>
    </div>
    <div>
      <div class="section__strip">
        <img src="/assets/quoted-header/image-icon.svg"
            alt="image-icon"
            class="strip__item">
        <img src="/assets/quoted-header/code-icon.svg"
            alt="code-icon"
            class="strip__item">
        <img src="/assets/quoted-header/pdf-icon.svg"
            alt="pdf-icon"
            class="strip__item">
        <img src="/assets/quoted-header/excel-icon.svg"
            alt="excel-icon"
            class="strip__item">
        <img src="/assets/quoted-header/video-icon.svg"
            alt="doc-icon"
            class="strip__item">
        <img src="/assets/quoted-header/psd-icon.svg"
            alt="doc-icon"
            class="strip__item">
        <img src="/assets/quoted-header/doc-icon.svg"
            alt="doc-icon"
            class="strip__item">
        <img src="/assets/quoted-header/camera-icon.svg"
            alt="camera-icon"
            class="strip__item">
      </div>
      <div class="section__buttons">
        <mt-drop-zone (select)="fileAdded($event, true)">
          <button
            data-cy="try-for-free-button"
            class="section__button"
            *ngIf="(isLoggedIn | async) === false"
            i18n="@@tryForFree">
            Try for free
          </button>
        </mt-drop-zone>
        <mt-drop-zone (select)="fileAdded($event, false)">
          <button
            data-cy="mytitle-it-button"
            class="section__button"
            *ngIf="(isLoggedIn | async) === true"
            i18n="@@mytitleIt">
            Mytitle it
          </button>
        </mt-drop-zone>
      </div>
    </div>
  </div>
</section>

