import { APP_INITIALIZER, NgModule } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app/states/models/app.models';
import { ImportSsrStateAction } from './import.actions';

/** Transfer key name for TransferState */
export const TRANSFER_STATE_KEY = 'state';

/**
 * Upon application start, the function for inserting server-side-rendered state into the client
 * app is triggered
 */
export function importSsrStateFactory(store: Store<AppState>, transferState: TransferState) {
  return function importSsrState() {
    const serverState = transferState.get(makeStateKey<AppState>(TRANSFER_STATE_KEY), null);
    store.dispatch(new ImportSsrStateAction(serverState));
  };
}

/**
 * BrowserImportStateModule inserts the ssr generated state to the ngrx store by dispatching
 * ImportServerStateAction action upon app startup.
 */
@NgModule({
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: importSsrStateFactory,
    deps: [Store, TransferState],
    multi: true,
  }],
})
export class BrowserImportSsrStateModule {
}
