// eslint-disable  max-classes-per-file
import { Action } from '@ngrx/store';
import { LayoutState } from '../reducers/layout.reducers';

export enum LayoutActions {
  CHANGE_LAYOUT_STATE = '[layout] Template changed',
}

export class ChangeLayoutState implements Action {
  public readonly type = LayoutActions.CHANGE_LAYOUT_STATE;
  constructor(public payload: LayoutState) {}
}
