export function getCurrencySelector(isEU: boolean): string {
  if (isEU) {
    return 'EUR';
  } else return 'USD';
}

export function getCurrencySign(currency: string): string {
  if (currency === 'CZK') return ' Kč';
  else if (currency === 'JPY') return '¥';
  else if (currency === 'EUR') return '€';
  else return '$';
}

export function removeDecimals(bundlePrice: string): string {
  return bundlePrice.slice(0, bundlePrice.length - 3);
}
