import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateUrl } from './models/app.models';

export class RouterSerializer implements RouterStateSerializer {
  public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;

    let state: ActivatedRouteSnapshot = routerState.root;

    while (state.firstChild) state = state.firstChild;

    const { params } = state;
    const { queryParams } = state;
    const { component } = state;

    return {
      url,
      component,
      queryParams,
      params,
    };
  }
}
