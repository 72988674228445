export enum GoogleAuthErrorCodes {
  CLOSED = 'popup_closed_by_user',
  DENIED = 'access_denied',
  FAILED = 'immediate_failed', // it is not possible to get this one for us
}

export enum GoogleRegistrationErrorCodes {
  ALREADY_REGISTRED = 'USER_ALREADY_FINISHED_REGISTRATION',
  GOOGLE_AUTH_FAILED = 'GOOGLE_AUTH_FAILED',
}

export interface GoogleErrorResponse {
  error: GoogleAuthErrorCodes;
}

export interface GoogleAuthParams {
  idToken: string;
}
