// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';

import { MytitleError } from 'src/app/client/models/client.models';
import {
  CreateSharingParams,
  ListAllSharingsParams,
  SharingObject,
  UpdateSharingParams,
} from 'src/app/client/models/mytitle-service.models';
import { ShareData } from '../../partials/sharing-dialog/sharing-dialog.component';
import { SharingsState } from './../reducers/sharing.reducer';

export enum SharingActions {
  GET_ALL_SHARINGS = '[MyDocuments] Get all sharings',
  GET_ALL_SHARINGS_SUCCESS = '[MyDocuments] Get all sharings success',
  CREATE_SHARING = '[MyDocuments] Create sharing',
  CREATE_SHARING_SUCCESS = '[MyDocuments] Create sharing success',
  CREATE_SHARING_ERROR = '[MyDocuments] Create sharing error',
  UPDATE_SHARING = '[MyDocuments] Update sharing',
  UPDATE_SHARING_SUCCESS = '[MyDocuments] Update sharing success',
  DELETE_SHARING = '[MyDocuments] Delete sharing',
  DELETE_SHARING_SUCCESS = '[MyDocuments] Delete sharing success',
  OPEN_SHARING_DIALOG = '[MyDocuments] Open sharing dialog',
  SHARING_DIALOG_CLOSED = '[MyDocuments] Sharing dialog closed',
}

export class GetAllSharings implements Action {
  public readonly type = SharingActions.GET_ALL_SHARINGS;
  constructor(public payload: ListAllSharingsParams) {}
}

export class GetAllSharingsSuccess implements Action {
  public readonly type = SharingActions.GET_ALL_SHARINGS_SUCCESS;
  constructor(public payload: SharingObject[]) {}
}

export class CreateSharing implements Action {
  public readonly type = SharingActions.CREATE_SHARING;
  constructor(public payload: CreateSharingParams) {}
}

export class CreateSharingSuccess implements Action {
  public readonly type = SharingActions.CREATE_SHARING_SUCCESS;
  constructor(public payload: SharingObject) {}
}

export class CreateSharingError implements Action {
  public readonly type = SharingActions.CREATE_SHARING_ERROR;
  constructor(public payload: MytitleError[]) {}
}

export class UpdateSharing implements Action {
  public readonly type = SharingActions.UPDATE_SHARING;
  constructor(public payload: { params: UpdateSharingParams; sharingGuid: string}) {}
}

export class UpdateSharingSuccess implements Action {
  public readonly type = SharingActions.UPDATE_SHARING_SUCCESS;
  constructor(public payload: SharingObject) {}
}

export class DeleteSharing implements Action {
  public readonly type = SharingActions.DELETE_SHARING;
  constructor(public payload: string) {}
}

export class DeleteSharingSuccess implements Action {
  public readonly type = SharingActions.DELETE_SHARING_SUCCESS;
  constructor(public payload: string) {}
}

export class OpenSharingDialog implements Action {
  public readonly type = SharingActions.OPEN_SHARING_DIALOG;
  constructor(public payload: ShareData) {}
}

export class SharingDialogClosed implements Action {
  public readonly type = SharingActions.SHARING_DIALOG_CLOSED;
  constructor(public payload: SharingsState) {}
}

export type SharingAction =
  GetAllSharings |
  GetAllSharingsSuccess |
  CreateSharing |
  CreateSharingSuccess |
  CreateSharingError |
  UpdateSharing |
  UpdateSharingSuccess |
  DeleteSharing |
  DeleteSharingSuccess |
  OpenSharingDialog |
  SharingDialogClosed;
