import { Component, HostListener } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NavigationEvent } from '../shared/services/data-layer.events';
import { DataLayerService } from '../shared/services/data-layer.service';

import { UniversalService } from '../shared/universal/universal.service';
import { MetaTagService } from './services/meta-tag.service';
import { AppState } from './states/models/app.models';
import { ColorTheme } from './states/reducers/layout.reducers';
import {
  applyRouterOutletContainer,
  displayFooterLogo,
  getColorTheme,
  initializeHeaderElement,
} from './states/selectors/layout.selectors';

@Component({
  selector: 'mt-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  public readonly colorTheme: Observable<ColorTheme>;
  public readonly displayFooterLogo: Observable<boolean>;
  public readonly applyRouterOutletContainer: Observable<boolean>;
  public readonly initializeHeaderElement: Observable<boolean>;
  public isSticky = false;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private dataLayerService: DataLayerService,
    private uniService: UniversalService,
    private metaTagService: MetaTagService,
  ) {

    // https://github.com/angular/angular/issues/19420#issuecomment-417050238
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
      map((event: any) => {
        let data = null;
        let route = event.snapshot.root;

        while (route) {
          data = route.data || data;
          route = route.firstChild;
        }
        return data;
      }),
    ).subscribe(data => {
      this.metaTagService.updateMultilingualTitleTag(data.title);
      this.metaTagService.updateMultilinguaDescriptionTag(data.description);
      this.metaTagService.updateOgImageTag(data.image);
      this.metaTagService.updateOgUrlTag(data.url);
      this.metaTagService.updateOgLocaleTag();
    });
    this.router.events.forEach(event => {
      if (event instanceof NavigationEnd) {
          this.dataLayerService.push(new NavigationEvent(event.url));
      }
  });

    this.colorTheme = this.store.pipe(select(getColorTheme));
    this.displayFooterLogo = this.store.pipe(select(displayFooterLogo));
    this.applyRouterOutletContainer = this.store.pipe(select(applyRouterOutletContainer));
    this.initializeHeaderElement = this.store.pipe(select(initializeHeaderElement));
  }

  @HostListener('window:scroll')
  public onWindowScroll() {
    if (!this.uniService.isPlatformServer()) {
      if (!this.router.url.includes('about')) {
        if (window.pageYOffset >= 758) {
          this.isSticky = true;
        } else {
          this.isSticky = false;
        }
      } else {
        this.isSticky = false;
      }
    }
  }
}
