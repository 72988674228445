import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ClientModule } from '../client/client.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { PersistenceService } from './services/persistence.service';
import { authEffects } from './store/auth.effects';
import { authReducers, authStoreName } from './store/auth.reducers';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(authStoreName, authReducers),
    EffectsModule.forFeature(authEffects),
    ClientModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    PersistenceService,
  ],
})
export class AuthModule { }
