/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';

import { HttpErrorResponse } from '@angular/common/http';
import { MytitleError } from 'src/app/client/models/client.models';
import { CheckFolderResponse,
  Folder,
  FolderWithChildren,
  GetFlatFolderListParams,
  GetFlatFolderListResponse,
  GetTreeFolderListParams,
  PatchFolderParams,
} from 'src/app/client/models/mytitle-service.models';
import { FolderOrderRequestParams, FolderOrderResponse } from 'src/app/client/models/order.models';
import { DownloadFolderRequest } from '../../my-documents.models';

export enum FolderActions {
  GET_TREE_FOLDER_LIST_START = '[Folders] Get tree folder list Start',
  GET_TREE_FOLDER_LIST_SUCCESS = '[Folders] Get tree folder list Success',

  GET_FLAT_FOLDER_LIST_START = '[Folders] Get flat folder list Start',
  GET_FLAT_FOLDER_LIST_SUCCESS = '[Folders] Get flat folder list Success',

  CREATE_FOLDER_START = '[Folders] Create folder Start',
  CREATE_FOLDER_SUCCESS = '[Folders] Create folder Success',

  GET_FOLDER_START = '[Folders] Get folder Start',
  GET_FOLDER_SUCCESS = '[Folders] Get folder Success',

  PATCH_FOLDER_START = '[Folders] Patch folder Start',
  PATCH_FOLDER_SUCCESS = '[Folders] Patch folder Success',
  PATCH_FOLDER_ERROR = '[Folders] Patch folder Error',
  CLEAN_PATCH_FOLDER = '[Folders] Clean patch folder',

  RENAME_FOLDER = '[Folders] Rename folder',
  RENAME_FOLDER_SUCCESS = '[Folders] Rename folder success',

  CHECK_FOLDER_FILES = '[Folders] Check folder files',
  CHECK_FOLDER_FILES_SUCCESS = '[Folders] Check folder files success',

  CREATE_FOLDER_ORDER = '[Folders] Create',
  FOLDER_ORDER_START = '[Folders] Start',
  FOLDER_ORDER_SUCCESS = '[Folders] Success',
  FOLDER_ORDER_ERROR = '[Folders] Error',

  GET_FOLDER_DOWNLOAD_TOKEN = '[Folders] Get folder download token',
  GET_FOLDER_DOWNLOAD_TOKEN_SUCCESS = '[Folders] Get folder download token success',
}

export class GetTreeFolderListStart implements Action {
  public readonly type = FolderActions.GET_TREE_FOLDER_LIST_START;
  constructor(public payload: GetTreeFolderListParams) { }
}

export class GetTreeFolderListSuccess implements Action {
  public readonly type = FolderActions.GET_TREE_FOLDER_LIST_SUCCESS;
  constructor(public payload: FolderWithChildren[]) { }
}

export class GetFlatFolderListStart implements Action {
  public readonly type = FolderActions.GET_FLAT_FOLDER_LIST_START;
  constructor(public payload: GetFlatFolderListParams) { }
}

export class GetFlatFolderListSuccess implements Action {
  public readonly type = FolderActions.GET_FLAT_FOLDER_LIST_SUCCESS;
  constructor(public payload: GetFlatFolderListResponse) { }
}

export class CreateFolderStart implements Action {
  public readonly type = FolderActions.CREATE_FOLDER_START;
  constructor(public payload: Folder) { }
}

export class CreateFolderSuccess implements Action {
  public readonly type = FolderActions.CREATE_FOLDER_SUCCESS;
  constructor(public payload: Folder) { }
}

export class GetFolderStart implements Action {
  public readonly type = FolderActions.GET_FOLDER_START;
  constructor(public payload: string) { }
}

export class GetFolderSuccess implements Action {
  public readonly type = FolderActions.GET_FOLDER_SUCCESS;
  constructor(public payload: Folder) { }
}

export class RenameFolder implements Action {
  public readonly type = FolderActions.RENAME_FOLDER;
  constructor(public payload: PatchFolderParams) { }
}

export class RenameFolderSuccess implements Action {
  public readonly type = FolderActions.RENAME_FOLDER_SUCCESS;
  constructor(public payload: PatchFolderParams) { }
}

export class PatchFolderStart implements Action {
  public readonly type = FolderActions.PATCH_FOLDER_START;
  constructor(public payload: PatchFolderParams) { }
}

export class PatchFolderSuccess implements Action {
  public readonly type = FolderActions.PATCH_FOLDER_SUCCESS;
  constructor(public payload: PatchFolderParams) { }
}

export class PatchFolderError implements Action {
  public readonly type = FolderActions.PATCH_FOLDER_ERROR;
  constructor(public payload: MytitleError[]) { }
}

export class CleanPatchFolder implements Action {
  public readonly type = FolderActions.CLEAN_PATCH_FOLDER;
}

export class CheckFolderFiles implements Action {
  public readonly type = FolderActions.CHECK_FOLDER_FILES;
  constructor(public payload: string) { }
}

export class CheckFolderFilesSuccess implements Action {
  public readonly type = FolderActions.CHECK_FOLDER_FILES_SUCCESS;
  constructor(public payload: CheckFolderResponse) { }
}

export class CreateFolderOrder implements Action {
  public readonly type = FolderActions.CREATE_FOLDER_ORDER;
  constructor(public payload: Folder) {}
}

export class FolderOrderStart implements Action {
  public readonly type = FolderActions.FOLDER_ORDER_START;
  constructor(public payload: FolderOrderRequestParams) {
  }
}

export class FolderOrderSuccess implements Action {
  public readonly type = FolderActions.FOLDER_ORDER_SUCCESS;
  constructor(public payload: FolderOrderResponse) {
  }
}

export class FolderOrderError implements Action {
  public readonly type = FolderActions.FOLDER_ORDER_ERROR;
  constructor(public payload: HttpErrorResponse) {
  }
}

export class GetFolderDownloadToken implements Action {
  public readonly type = FolderActions.GET_FOLDER_DOWNLOAD_TOKEN;
  constructor(public payload: string) {}
}

export class GetFolderDownloadTokenSuccess implements Action {
  public readonly type = FolderActions.GET_FOLDER_DOWNLOAD_TOKEN_SUCCESS;
  constructor(public payload: DownloadFolderRequest) {}
}

export type FolderAction = GetTreeFolderListStart
  | GetTreeFolderListSuccess
  | GetFlatFolderListStart
  | GetFlatFolderListSuccess
  | CreateFolderStart
  | CreateFolderSuccess
  | GetFolderStart
  | GetFolderSuccess
  | PatchFolderStart
  | PatchFolderSuccess
  | PatchFolderError
  | CleanPatchFolder
  | RenameFolder
  | RenameFolderSuccess
  | CheckFolderFiles
  | CheckFolderFilesSuccess
  | CreateFolderOrder
  | FolderOrderStart
  | FolderOrderSuccess
  | FolderOrderError
  | GetFolderDownloadToken
  | GetFolderDownloadTokenSuccess;
