/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';

export enum LocationVerifyActions {
  LOCATION_VERIFIED = '[Auth] Location verified',
}

export class LocationVerified implements Action {
  public readonly type = LocationVerifyActions.LOCATION_VERIFIED;
}

export type LocationVerifyAction = LocationVerified;
