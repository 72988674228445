import { Action, ActionReducer } from '@ngrx/store';
import { AppState } from '../../../../app/states/models/app.models';
import { ImportAction, ImportActions } from './import.actions';

// eslint-disable-next-line max-len
export function importSsrStateMetaReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state: AppState, generic: Action): AppState => {
    const action = generic as ImportAction;

    switch (action.type) {
      case ImportActions.IMPORT_SSR_STATE:
        return action.payload ? { ...action.payload } : reducer(state, action);
      default:
        return reducer(state, action);
    }
  };
}
