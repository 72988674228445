<div class="dialogue" mat-dialog-content>
  <div class="dialogue__content">
    <iframe
      src="https://player.vimeo.com/video/420705392?autoplay=0&color=00A6E2&title=0&byline=0&portrait=0"
      title="Blockchain timestamping explained"
      frameborder="0"
      class="dialogue__iframe"
    ></iframe>
  </div>
</div>

<div mat-dialog-actions class="dialogue__actions">
  <button
    (click)="close()"
    mat-button
    type="button"
    i18n="@@close">
    Close
  </button>
</div>
