<!-- DESKTOP -->
<footer class="container">
  <div class="desktop">

    <div class="col-auto d-flex justify-content-center justify-content-md-start mr-md-auto">
      <nav class="mt-nav d-flex flex-column flex-sm-row">

        <div class="col-4 d-flex flex-column mr-0 mr-sm-4">

          <a class="nav-link pt-md-0"
             [routerLink]="['/']" i18n="@@home">Home</a>

          <a class="nav-link pt-md-0"
             [href]="getBlog()"
             rel="noreferrer"
             i18n="@@blog">Blog</a>

          <a class="nav-link pt-md-0"
            href="https://business.mytitle.com/"
            target="_blank" 
            rel="noreferrer"
            i18n="@@forBusiness">Mytitle for Business</a>

          <a *ngIf="(isLoggedIn | async) === false"
            class="nav-link pt-md-0"
            [routerLink]="['/', appRoutes.Login]"
            i18n="@@logIn">Log In</a>

          <a *ngIf="isLoggedIn | async"
            class="nav-link pt-md-0"
            [routerLink]="['/', appRoutes.MyDocuments]"
            i18n="@@myDocuments">My Documents</a>

        </div>

        <div class="col-4 d-flex flex-column mr-0 mr-sm-4">

          <a [routerLink]="['/', appRoutes.About, aboutRoutes.Contact]"
            class="nav-link pt-md-0"
            i18n="@@contact">Contact</a>

          <a [routerLink]="['/', appRoutes.About, aboutRoutes.AboutUs]"
            class="nav-link pt-md-0"
            i18n="@@aboutUs">About Us</a>

          <a [routerLink]="['/', appRoutes.About, aboutRoutes.Legal]"
             class="nav-link pt-md-0"
             i18n="@@legal">Legal Statement</a>

          <a [routerLink]="['/', appRoutes.About, aboutRoutes.Terms]"
            class="nav-link pt-md-0"
            i18n="@@terms">Terms of Use</a>
        </div>
        <div class="col-4 d-flex flex-column">
          <a [routerLink]="['/', appRoutes.About, aboutRoutes.Gdpr]"
            class="nav-link pt-md-0"
            i18n="@@gdpr">GDPR Policy</a>
          <a class="nav-link pt-md-0" href="/assets/whitepaper/mytitle_whitepaper.pdf">
            Mytitle Whitepaper
          </a>
        </div>

        <div class="col-4 d-flex flex-column">
          <div *ngIf="!noLogo" class="col-auto d-flex justify-content-center">
            <a routerLink="/"
              class="mb-3 ml-3">
              <svg class="mt-main-logo">
                <use xlink:href="/assets/img/sprite.svg#logo"></use>
              </svg>
            </a>
          </div>
          <div class="d-flex flex-row justify-content-center">

            <a href="https://www.facebook.com/mytitleforme/"
               target="_blank"
               class="mr-2"
               data-cy="facebook"
               rel="noreferrer">

               <img src="/assets/icons/facebook.svg" alt="facebook" class="mt-icon mt-icon-md">
            </a>
    
            <a href="https://www.linkedin.com/company/mytitle/about/"
               target="_blank"
               data-cy="linkedin"
               class="mr-2"
               rel="noreferrer">
              <img src="/assets/icons/linkedin.svg" alt="linkedin" class="mt-icon mt-icon-md">
            </a>

            <a href="https://www.instagram.com/mytitle4me/"
               target="_blank"
               data-cy="linkedin"
               rel="noreferrer">
              <img src="/assets/icons/instagram.svg" alt="instagram" class="mt-icon mt-icon-md">
            </a>
    
          </div>

          <div class="mt-2 mt-beta-disclaimer d-flex justify-content-center" i18n="@@betaDisclaimer">
            Mytitle service is in open-beta testing phase.
          </div>

          <div class="mt-2 mt-copyright d-flex justify-content-center">
            Mytitle &copy; {{ currentDate | date:"yyyy" }}
          </div>
        </div>

      </nav>
      
    </div>
  </div>
  <!-- MOBILE -->
<div class="mobile mt-nav">
    <div class="mobile__wrapper">
      <div class="mobile__column">
        <a class="nav-link pt-md-0"
           [routerLink]="['/']" i18n="@@home">Home</a>
  
        <a class="nav-link pt-md-0"
           [href]="getBlog()"
           rel="noreferrer"
           i18n="@@blog">Blog</a>
  
        <a class="nav-link pt-md-0"
          href="https://business.mytitle.com/"
          target="_blank" 
          rel="noreferrer"
          i18n="@@forBusiness">Mytitle for Business</a>
  
        <a *ngIf="(isLoggedIn | async) === false"
          class="nav-link pt-md-0"
          [routerLink]="['/', appRoutes.Login]"
          i18n="@@logIn">Log In</a>
  
        <a *ngIf="isLoggedIn | async"
          class="nav-link pt-md-0"
          [routerLink]="['/', appRoutes.MyDocuments]"
          i18n="@@myDocuments">My Documents</a>
  
      </div>
      <div class="mobile__column">
  
        <a [routerLink]="['/', appRoutes.About, aboutRoutes.Contact]"
          class="nav-link pt-md-0"
          i18n="@@contact">Contact</a>
  
        <a [routerLink]="['/', appRoutes.About, aboutRoutes.AboutUs]"
          class="nav-link pt-md-0"
          i18n="@@aboutUs">About Us</a>
  
        <a [routerLink]="['/', appRoutes.About, aboutRoutes.Legal]"
           class="nav-link pt-md-0"
           i18n="@@legal">Legal Statement</a>
  
        <a [routerLink]="['/', appRoutes.About, aboutRoutes.Terms]"
          class="nav-link pt-md-0"
          i18n="@@terms">Terms of Use</a>
      </div>
    </div>
    <div class="mobile__wrapper">
      <div class="mobile__column">
        <a [routerLink]="['/', appRoutes.About, aboutRoutes.Gdpr]"
          class="nav-link pt-md-0"
          i18n="@@gdpr">GDPR Policy</a>
      <a class="nav-link pt-md-0" href="/assets/whitepaper/mytitle_whitepaper.pdf">
        Mytitle Whitepaper
      </a>
      </div>
      <div class="mobile__column">
        <a routerLink="/"
          class=""
          *ngIf="!noLogo">
          <svg class="mt-main-logo">
            <use xlink:href="/assets/img/sprite.svg#logo"></use>
          </svg>
        </a>
        <div class="mt-2 mt-beta-disclaimer d-flex">
          Mytitle service is in open-beta testing phase.
        </div>
        <div class="mt-2 mt-copyright d-flex">
          Mytitle &copy; {{ currentDate | date:"yyyy" }}
        </div>
        <div>
          <a href="https://www.facebook.com/mytitleforme/"
             target="_blank"
             class=""
             data-cy="facebook"
             rel="noreferrer">
            <svg class="mt-icon mt-icon-md">
              <use xlink:href="/assets/img/sprite.svg#facebook-link"></use>
            </svg>
          </a>
    
          <a href="https://www.linkedin.com/company/mytitle/about/"
             target="_blank"
             data-cy="linkedin"
             rel="noreferrer">
            <svg class="mt-icon mt-icon-md">
              <use xlink:href="/assets/img/sprite.svg#linkedin-link"></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
