import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { getUpgradingFolder } from 'src/app/pages/my-documents/store/selectors/folder.selectors';
import { getFileState, isUpgradingFile } from '../states/selectors/file.selectors';

@Injectable({
  providedIn: 'root',
})
export class DocumentGuardService implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<any>,
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return forkJoin([
      this.store
        .select(getFileState)
        .pipe(
          map(fileState => !!fileState.file),
          first()),
      this.store
        .select(isUpgradingFile)
        .pipe(
          first()),
      this.store
        .select(getUpgradingFolder)
        .pipe(
          map(folder => !!folder),
          first()),
    ]).pipe(
      take(1),
      map(([isFilePresent, isBeingUpgraded, folder]) => {
        if (!isFilePresent && !isBeingUpgraded && !folder) return this.router.createUrlTree(['/']);
        else return true;
      }),
    );
  }
}
