/* eslint-disable max-classes-per-file */
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { RefreshTokenRequest } from 'src/app/client/models/users.modules';
import { TokenInfo } from '../models/tokenInfo';

export enum RefreshTokenActions {
  REFRESH_TOKEN = '[Auth] Refresh token',
  REFRESH_TOKEN_SUCCESS = '[Auth] Refresh token success',
  REFRESH_TOKEN_ERROR = '[Auth] Refresh token error',
}

export class RefreshToken implements Action {
  public readonly type = RefreshTokenActions.REFRESH_TOKEN;
  constructor(public payload: RefreshTokenRequest) { }
}

export class RefreshTokenSuccess implements Action {
  public readonly type = RefreshTokenActions.REFRESH_TOKEN_SUCCESS;
  constructor(public payload: {
    token: TokenInfo;
  }) { }
}

export class RefreshTokenError implements Action {
  public readonly type = RefreshTokenActions.REFRESH_TOKEN_ERROR;
  constructor(public payload: HttpErrorResponse) { }
}

export type RefreshTokenAction = RefreshToken | RefreshTokenSuccess | RefreshTokenError;
