/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import { LoginParams } from 'src/app/client/models/users.modules';
import { TokenInfo } from '../models/tokenInfo';

export enum LoginActions {
  LOGIN = '[Auth] Login',
  LOGIN_TWO_FA = '[Auth] Login with 2FA',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_ERROR = '[Auth] Login Error',
  CLEAR_LOGIN_ERROR = '[Auth] Cleared Login Error',
}

export class Login implements Action {
  public readonly type = LoginActions.LOGIN;
  constructor(public payload: LoginParams) { }
}

export class LoginTwoFA implements Action {
  public readonly type = LoginActions.LOGIN_TWO_FA;
  constructor(public payload: string) {}
}

export class LoginSuccess implements Action {
  public readonly type = LoginActions.LOGIN_SUCCESS;
  constructor(public payload: {
    email: string;
    token: TokenInfo;
  }) { }
}

export class LoginError implements Action {
  public readonly type = LoginActions.LOGIN_ERROR;
  constructor(public payload: MytitleError[]) { }
}

export class ClearLoginError implements Action {
  public readonly type = LoginActions.CLEAR_LOGIN_ERROR;
}

export type LoginAction = Login | LoginSuccess | LoginError | LoginTwoFA | ClearLoginError;
