import { HttpErrorResponse } from '@angular/common/http';
import { Action, createFeatureSelector } from '@ngrx/store';
import { GopayPaymentCreated } from '../../../client/models/gopay.models';
import { PaymentAction, PaymentActions } from '../actions/payment.actions';

export interface PaymentState {
  gopay: GopayPaymentCreated;
  paymentLoading: boolean;
  paymentError: HttpErrorResponse;
}

export const initialPaymentState: PaymentState = {
  gopay: null,
  paymentLoading: false,
  paymentError: null,
};

export const paymentSelectorName = 'payment';
export const getPaymentStateSelector = createFeatureSelector<PaymentState>(
  paymentSelectorName);

export function paymentReducer(
  state: PaymentState = initialPaymentState,
  generic: Action,
): PaymentState {
  const action = generic as PaymentAction;
  switch (action.type) {
    case PaymentActions.CREATE_PAYMENT_START:
      return {
        ...state,
        paymentLoading: true,
        gopay: null,
      };
    case PaymentActions.CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        gopay: action.payload.gopay,
        paymentLoading: false,
      };
    case PaymentActions.CREATE_PAYMENT_ERROR:
      return {
        ...state,
        paymentError: action.payload,
        gopay: null,
        paymentLoading: false,
      };
    default:
      return state;
  }
}
