import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Params, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { AppRoutes } from 'src/app/app/app.routes.misc';
import { GetCreditBalance } from 'src/app/auth/store/actions/getCreditBalance.actions';
import { getCreditBalance, getIsValidAcademy } from 'src/app/auth/store/selectors/user.selector';
import { BillingServiceClient } from 'src/app/client/services/billing-service.client';
import { Notifications } from 'src/app/shared/models/notifications';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { getFileCreditsValue } from '../../../shared/utils/get-file-credits-value';
import { ClearFileState } from '../actions/file.action';
import {
  ClearFileOrder,
  CreateFileOrder,
  FileOrderError,
  FileOrderSuccess,
  HighlightUpdate,
  OrderFileAction,
  OrderFileActions,
} from '../actions/order-file.actions';
import { AppState } from '../models/app.models';
import {
  getSelectedBundle,
  isCreatingOrder,
} from '../selectors/credit-bundle-choice.selectors';
import { getFileState } from '../selectors/file.selectors';
import { getSavedVoucher } from '../selectors/voucher.selectors';
import { CreateCreditOrder } from '../actions/order-credit.actions';

@Injectable()
export class FileOrderEffects {

  public readonly fileOrderCreate$ = createEffect(() => this.storeActions.pipe(
    ofType<CreateFileOrder>(OrderFileActions.CREATE_FILE_ORDER),
    switchMap(() => this.store
      .pipe(
        select(getFileState),
        first(fileState => !!fileState && !!fileState.document),
      ),
    ),
    switchMap(fileState => this.store.pipe(
      select(getIsValidAcademy),
      first(isAcademy => isAcademy !== undefined),
      map(isAcademy => ({ fileState, isAcademy }))
    )),
    switchMap(({ fileState, isAcademy }) => this.billingServiceClient.createFileOrder({
        order_items: [
          {
            is_free: fileState.is_free,
            document_guid: fileState.document.guid,
            credit_value: getFileCreditsValue(fileState.document.filesize_original, isAcademy)
          },
        ],
      })
        .pipe(
          map(result => new FileOrderSuccess(result)),
          catchError((error: HttpErrorResponse) => of(new FileOrderError(error.error))),
        ),
    ),
  ));


  public readonly handleFileOrderError$ = createEffect(() => this.storeActions.pipe(
    ofType(OrderFileActions.FILE_ORDER_ERROR),
    tap(() => {
      setTimeout(() => {
        this.snackbarService.queueSnackBar(Notifications.FileOrderError);
        this.router.navigate([AppRoutes.MyDocuments]);
      }, 2000);
    }),
    map(() => new ClearFileOrder()),
  ));


  public readonly createCreditOrder = createEffect(() => this.storeActions.pipe(
    ofType<FileOrderSuccess>(OrderFileActions.FILE_ORDER_SUCCESS),
    filter(action => !action.payload.is_free),
    switchMap(action => forkJoin([
      this.store.select(getCreditBalance).pipe(first()),
      this.store.select(isCreatingOrder).pipe(first()),
      this.store.select(getSelectedBundle).pipe(first()),
      this.store.select(getFileState).pipe(first()),
      this.store.select(getSavedVoucher).pipe(first()),
      of(action),
    ])),
    mergeMap(([credits, creatingOrder, selectedBundle, fileState, voucher, action]) => {
      if (!credits && creatingOrder) {
        if (voucher) {
          return [
            new CreateCreditOrder({
              config_guid: selectedBundle,
              file_order_guid: action.payload.guid,
              voucher_code: voucher,
            })];
        } else {
          return [
            new CreateCreditOrder({
              config_guid: selectedBundle,
              file_order_guid: action.payload.guid,
            })];
        }
      } else {
        const queryParams: Params = {folder: fileState.folder};

        this.router.navigate([AppRoutes.MyDocuments], {queryParams});
        return [
          new ClearFileState(),
          new GetCreditBalance(),
          new HighlightUpdate(true),
        ];
      }
    }),
  ));


  public readonly navigateIfOrderCreatedFree = createEffect(() => this.storeActions.pipe(
    ofType(OrderFileActions.FILE_ORDER_SUCCESS),
    filter(action => action.payload.is_free),
    mergeMap(action => {
      if (action.payload.is_free) {
        return [new ClearFileState(), new GetCreditBalance()];
      } else {
        return [new GetCreditBalance()];
      }
    }),
  ));


  public readonly navigateIfOrderCreated = createEffect(() => this.storeActions.pipe(
    ofType(OrderFileActions.FILE_ORDER_SUCCESS),
    switchMap(action => forkJoin([
      this.store.select(getCreditBalance).pipe(first()),
      this.store.select(getFileState).pipe(first()),
      of(action),
    ])),
    mergeMap(([creditBalance, fileState, action]) => {
      if (action.payload.is_free || creditBalance) {
        this.dataLayerService.push({event: 'free-order-created'});

        const queryParams: Params = {folder: fileState.folder};
        this.router.navigate([AppRoutes.MyDocuments], {queryParams});

        return [
          new HighlightUpdate(true),
        ];
      } else return [];
    }),
  ));

  constructor(
    private dataLayerService: DataLayerService,
    private storeActions: Actions<OrderFileAction>,
    private billingServiceClient: BillingServiceClient,
    private snackbarService: SnackbarService,
    private store: Store<AppState>,
    private router: Router,
  ) {
  }
}
