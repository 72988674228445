/* eslint-disable max-classes-per-file */
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { User } from '../models/user';

export enum GetUserInfoActions {
  GET_USER_INFO = '[Auth] GetUserInfo',
  GET_USER_INFO_SUCCESS = '[Auth] GetUserInfo Success',
  GET_USER_INFO_ERROR = '[Auth] GetUserInfo Error',

  OPEN_VIDEO_DIALOG = '[Auth] Open video dialog',
  VIDEO_DIALOG_CLOSED = '[Auth] Video dialog closed',
}

export class GetUserInfo implements Action {
  public readonly type = GetUserInfoActions.GET_USER_INFO;
}

export class GetUserInfoSuccess implements Action {
  public readonly type = GetUserInfoActions.GET_USER_INFO_SUCCESS;
  constructor(public payload: User) { }
}

export class GetUserInfoError implements Action {
  public readonly type = GetUserInfoActions.GET_USER_INFO_ERROR;
  constructor(public payload: HttpErrorResponse) { }
}

export class OpenVideoDialog implements Action {
  public readonly type = GetUserInfoActions.OPEN_VIDEO_DIALOG;
}

export class VideoDialogClosed implements Action {
  public readonly type = GetUserInfoActions.VIDEO_DIALOG_CLOSED;
}

export type GetUserInfoAction =
GetUserInfo |
GetUserInfoSuccess |
GetUserInfoError |
OpenVideoDialog |
VideoDialogClosed;
