import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { getHttpParams } from 'src/app/app/utils/get-http-params';
import { DownloadFolderResponse } from 'src/app/pages/my-documents/my-documents.models';
import {
  CheckFolderResponse,
  CreateDocumentParams,
  CreateDocumentsDeletionParams,
  CreateSharingParams,
  Document,
  DocumentActivityResponse,
  DownloadDocumentTokenResponse,
  DownloadSharedDocumentTokenResponse,
  FinishDocumentsDeletionResponse,
  Folder,
  FolderWithChildren,
  GetFlatFolderListParams,
  GetFlatFolderListResponse,
  GetLimitExceededResponse,
  GetTreeFolderListParams,
  ListAllSharingsParams,
  ListDocumentsParams,
  ListDocumentsResponse,
  PatchDocumentRequest,
  PatchFolderParams,
  PublicDocument,
  SharingObject,
  UpdateSharingParams, WatermarkPreview,
} from '../models/mytitle-service.models';

@Injectable()
export class MytitleServiceClient {

  private serviceUrl = '/api/v1/mytitle';

  constructor(private http: HttpClient) {}

  public uploadFile(params: CreateDocumentParams): Observable<HttpEvent<Document>> {
    const formDataParams = new FormData();
    formDataParams.set('file', params.file);
    formDataParams.set('folder', params.folder);
    formDataParams.set('is_free', '' + params.is_free);
    const req = new HttpRequest(
      'POST',
      `${this.serviceUrl}/document`,
      formDataParams,
      { reportProgress: true },
    );
    return this.http.request<Document>(req);
  }

  /**
   * Lists all user's documents.
   */
  public listDocuments(params: ListDocumentsParams): Observable<ListDocumentsResponse> {
    return this.http
      .get<ListDocumentsResponse>(`${this.serviceUrl}/document`, { params: getHttpParams(params) });
  }

  /**
   * Get sharing activity for files.
   */
  public getFileActivity(document_guid: string): Observable<DocumentActivityResponse[]> {
    return this.http
      .get<DocumentActivityResponse[]>(`${this.serviceUrl}/document/${document_guid}/activity`);
  }

  /**
   * Get sharing activity for folders.
   */
  public getFolderActivity(folder_guid: string): Observable<DocumentActivityResponse[]> {
    return this.http
  .get<DocumentActivityResponse[]>(
   `/api/v1/mytitle/folder/${folder_guid}/activity`);
  }


  /**
   * Patch document for verification.
   */
  public patchDocument(
    document_guid: string,
    params: PatchDocumentRequest,
  ): Observable<Document> {
    console.log(params);
    return this.http.patch<Document>(`${this.serviceUrl}/document/${document_guid}`, params);
  }

  /**
   * Get public document for verification.
   */
  public getPublicDocument(document_guid: string): Observable<PublicDocument> {
    return this.http.get<PublicDocument>(`${this.serviceUrl}/public-document/${document_guid}`);
  }

  /**
   * Get access token to download document file.
   * Token is valid only for 1 minute and can be used only once.
   */
  public getDownloadToken(document_guid: string): Observable<DownloadDocumentTokenResponse> {
    return this.http
      .get<DownloadDocumentTokenResponse>
        (`${this.serviceUrl}/document/${document_guid}/get_download_token`);
  }

  /**
   * Get user's tree folder list
   */
  public getTreeFolderList(params: GetTreeFolderListParams): Observable<FolderWithChildren[]> {
    return this.http.get<FolderWithChildren[]>
      (`${this.serviceUrl}/folder/tree`, { params: getHttpParams(params) } );
  }

  /**
   * Get user's flat folder list
   */
  public getFlatFolderList(params: GetFlatFolderListParams): Observable<GetFlatFolderListResponse> {
    return this.http
      .get<GetFlatFolderListResponse>
        (`${this.serviceUrl}/folder/flat`, { params: getHttpParams(params) } );
  }

  /**
   * Create folder
   */
  public createFolder(params: Folder): Observable<Folder> {
    return this.http
      .post<Folder>(`${this.serviceUrl}/folder`, params );
  }

  /**
   * Get folder
   */
  public getFolder(folder_guid: string): Observable<Folder> {
    return this.http
      .get<Folder>(`${this.serviceUrl}/folder/${folder_guid}`);
  }

  /**
   * Patch folder
   */
  public patchFolder(params: PatchFolderParams): Observable<Folder> {
    const folder_guid = params.folder.guid;
    return this.http
      .patch<Folder>(`${this.serviceUrl}/folder/${folder_guid}`, params.folder);
  }

  /**
   * Checks how many documents (total and free) are inside this folder and it's descendants
   */
  public checkFilesInFolder(folder_guid: string): Observable<CheckFolderResponse> {
    return this.http.get<CheckFolderResponse>(`${this.serviceUrl}/folder/${folder_guid}/check`);
  }

  /**
   * Creates order for deleting document, needs to be confirmed by user via email
   */
  public createDocumentsDeletion(guids: CreateDocumentsDeletionParams):
  Observable<CreateDocumentsDeletionParams> {
    return this.http
      .post<CreateDocumentsDeletionParams>(`${this.serviceUrl}/deletion`, guids);
  }

  /**
   * Confirms guid sent to user's email and deletes marked documents/folders pernamently
   */
  public finishDocumentsDeletion(deletion_guid: string):
  Observable<FinishDocumentsDeletionResponse> {
    return this.http.delete<FinishDocumentsDeletionResponse>
      (`${this.serviceUrl}/deletion/${deletion_guid}`);
  }

  /**
   * Get all sharing objects for provided document/folder.
   */
  public listAllSharings(params: ListAllSharingsParams): Observable<SharingObject[]> {
    return this.http
      .get<SharingObject[]>(`${this.serviceUrl}/sharing`, { params: getHttpParams(params) });
  }

  /**
   * Create new sharing object for provided document/folder
   */
  public createSharing(params: CreateSharingParams): Observable<SharingObject> {
    return this.http
      .post<SharingObject>(`${this.serviceUrl}/sharing`, params);
  }

  /**
   * Update sharing object for provided document/folder
   */
  public updateSharing(params: UpdateSharingParams, sharing_guid: string):
    Observable<SharingObject> {
      return this.http.patch<SharingObject>(`${this.serviceUrl}/sharing/${sharing_guid}`, params);
  }

  /**
   * Delete sharing object for provided document/folder
   */
  public deleteSharing(sharing_guid: string): Observable<void> {
    return this.http.delete<void>(`${this.serviceUrl}/sharing/${sharing_guid}`);
  }

  /**
   * Gets all documents
   */
  public listSharedDocuments(params: ListDocumentsParams): Observable<ListDocumentsResponse> {
    return this.http.get<ListDocumentsResponse>
      (`${this.serviceUrl}/documents_shared_with_me`, { params: getHttpParams(params) });
  }

  /**
   * Get access token to download shared document
   * Token is valid only for 1 minute and can be used only once.
   */
  public getSharedDownloadToken(document_guid: string):
    Observable<DownloadSharedDocumentTokenResponse> {
      return this.http
        .get<DownloadDocumentTokenResponse>
          (`${this.serviceUrl}/documents_shared_with_me/${document_guid}/get_download_token`);
  }

  /**
   * Gets all folders shared with user in tree structure
   */
  public getSharedTreeFolderList(): Observable<FolderWithChildren[]> {
    return this.http
      .get<FolderWithChildren[]>(`${this.serviceUrl}/folders_shared_with_me/tree`);
  }

  /**
   * gets all folders shared with user in flat structure
   */
  public getSharedFlatFolderList(params: GetFlatFolderListParams):
    Observable<GetFlatFolderListResponse> {
      return this.http
        .get<GetFlatFolderListResponse>
          (`${this.serviceUrl}/folders_shared_with_me/flat`, { params: getHttpParams(params) } );
  }

  /**
   * Checks whether the free plan is exceeded
   */
  public checkLimit(): Observable<GetLimitExceededResponse> {
    return this.http.get<GetLimitExceededResponse>(`${this.serviceUrl}/rate-limit-check`);
  }

  /**
   * Gets download token for folder downloading (.zip)
   */
  public getFolderDownloadToken(folder_guid: string): Observable<DownloadFolderResponse> {
    return this.http.get<DownloadFolderResponse>(`${this.serviceUrl}/folder/${folder_guid}/get_download_token`);
  }

  public getWatermarkPreview(params: WatermarkPreview) {
    const formDataParams = new FormData();
    const headers = new HttpHeaders();
    formDataParams.set('file', params.file);
    params.watermark_types.forEach(type => formDataParams.append('watermark_types', type));
    formDataParams.set('watermark_opacity', '' + params.watermark_opacity);
    return this.http.post(`${this.serviceUrl}/watermark_preview`,formDataParams, {headers, responseType: 'blob'});
  }

}
