import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { OpenVideoDialog } from 'src/app/auth/store/actions/getUserInfo.action';
import { AppState } from '../../states/models/app.models';

@Component({
  selector: 'mt-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateComponent {
  public selectedContainerId: number;

  constructor(
    public store: Store<AppState>,
  ) {}

  public openVideoDialog() {
    this.store.dispatch(new OpenVideoDialog());
  }

  public selectContainer(selected: number) {
    this.selectedContainerId = selected;
  }

  public isContainerSelected(selected: number) {
    return this.selectedContainerId === selected;
  }
}
