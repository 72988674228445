import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import {
  GetCreditBundles,
  GetCreditOrderSummary,
} from 'src/app/app/states/actions/credit-bundle-choice.actions';
import { FileUpdated, SelectDocumentPrice } from 'src/app/app/states/actions/file.action';
import { AppState } from 'src/app/app/states/models/app.models';
import { getCreditBundles } from 'src/app/app/states/selectors/credit-bundle-choice.selectors';
import { GetUserInfo } from 'src/app/auth/store/actions/getUserInfo.action';
import { LimitExceeded } from 'src/app/auth/store/models/user';
import {
  getGeoIPState,
  getIsAuthorized,
  getIsValidAcademy,
  getIsEU,
  limitExceededState,
} from 'src/app/auth/store/selectors/user.selector';
import { CreateCreditOrderParams, CreditConfig } from 'src/app/client/models/credit.models';
import {
  getCurrencySign,
  removeDecimals,
} from 'src/app/pages/credit-bundle-choice/credit-bundle-choice.utils';
import { Notifications } from 'src/app/shared/models/notifications';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'mt-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnDestroy {
  public readonly isLoadingBundles: Observable<boolean>;
  public readonly isLoggedIn$: Observable<boolean>;
  public readonly getCurrencySign = getCurrencySign;
  public readonly removeDecimals = removeDecimals;
  public readonly appRoutes = AppRoutes;
  public limitExceededState: LimitExceeded;
  public bundles: CreditConfig[];
  public filteredBundles: CreditConfig[];
  public selectedContainerId = 0;
  public showBestOffer = false;
  public isSmallScreen = false;
  public oneCredit = '5 €';
  public bestOfferCreditIndex = 0;
  public pricingTables = ['Standard', 'Academy'];
  public selectedPricing: Number;

  private ngDestroy$ = new Subject<void>();

  constructor(
    public enviService: EnvironmentService,
    private media: MediaMatcher,
    private store: Store<AppState>,
    private snackbarService: SnackbarService,
  ) {
    this.isLoggedIn$ = this.store.pipe(select(getIsAuthorized));
    this.isSmallScreen = this.media.matchMedia('(max-width: 768px)').matches;

    this.store.pipe(
      select(limitExceededState),
    ).subscribe(s => this.limitExceededState = s);

    this.store.dispatch(new GetUserInfo());
    this.store.dispatch(new GetCreditBundles());

    combineLatest([
      this.store.select(getCreditBundles).pipe(
        filter(bundles => !!bundles),
      ),
      this.store.select(getGeoIPState),
      this.store.select(getIsEU),
      this.store.select(getIsValidAcademy),
    ]).pipe(
      map(([bundles, _, isEU, isValidAcademy]) => {
          this.selectedPricing = isValidAcademy ? 1 : 0
          let filteredBundles = [];
          if (this.enviService.isCzechEnvironment) {
            filteredBundles = bundles.filter(bundle =>
              bundle.currency === 'CZK');
            this.oneCredit = '100 Kč';
          } else {
            let currency = '';
            if (isEU) {
              currency = 'EUR';
              this.oneCredit = '5 €';
            } else {
              currency = 'USD';
              this.oneCredit = '5 $';
            }
            filteredBundles = bundles.filter(bundle => bundle.currency === currency);
          }
          return [filteredBundles] as [CreditConfig[]];
        },
        takeUntil(this.ngDestroy$)),
    ).subscribe(([bundles]) => {
      this.bundles = bundles;
      this.filterBundles();
    });
  }

  public fileAdded(file: File, isFree: boolean) {
    if (this.limitExceededState.isLimitExceeded && isFree) {
      if (!this.limitExceededState.wasWarningShown) {
        this.snackbarService.queueSnackBar(
          'Free quota exceeded', { triggerAction: 'FREE_LIMIT_WARNING_SHOWN' });
      } else {
        this.snackbarService.queueSnackBar(Notifications.QuotaExceeded);
      }
    } else {
      this.store.dispatch(new FileUpdated({
          file,
          folder: null,
          is_free: isFree,
          uploadNow: true,
        },
      ));
      if (isFree) {
        this.store.dispatch(new SelectDocumentPrice({ is_free: isFree }));
      }
    }
  }

  public pickCreditBundle(bundleGuid: string) {
    const params: CreateCreditOrderParams = {
      config_guid: bundleGuid,
    };
    this.store.dispatch(new GetCreditOrderSummary(params));
  }

  public selectContainer(id: number) {
    this.selectedContainerId = id;
    this.showBestOffer = id === this.bestOfferCreditIndex;
  }

  public isContainerSelected(id: number) {
    return id === this.selectedContainerId;
  }

  public ngOnDestroy() {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  private getBestOfferCredit() {
    const results: number[] = [];
    this.filteredBundles.forEach(x => {
      results.push(this.getOneCreditPrice(x));
    });

    this.bestOfferCreditIndex = results.indexOf(Math.min(...results));
  }

  private getOneCreditPrice(bundle: CreditConfig): number {
    const bundlePrice = parseFloat(bundle.total_price);
    return bundlePrice / bundle.credits_count;
  }

  public defineBestOffer() {
    this.getBestOfferCredit();
    this.selectedContainerId = this.bestOfferCreditIndex;
    this.showBestOffer = this.selectedContainerId >= 0
  }

  public filterBundles() {
    this.filteredBundles = this.bundles.filter(bundle => bundle.is_academy === (this.selectedPricing === 1))
    this.defineBestOffer()
  }
}
