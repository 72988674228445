<div class="user-circle"
     [matMenuTriggerFor]="menu">
  {{ initial | async }}
</div>
<mat-menu class="mt-2" #menu="matMenu">
  <div class="px-3 mat-body-strong">{{ name | async }}</div>
  <div class="px-3 mat-small">{{ email | async }}</div>
  <mat-divider class="mt-1"></mat-divider>

  <button mat-menu-item [routerLink]="['/', 'settings']" i18n="@@settings">
    Settings
  </button>

  <button mat-menu-item (click)="logOut()" i18n="@@logOut">
    Log out
  </button>
</mat-menu>
