import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { getIsAuthorized, getIsEU } from 'src/app/auth/store/selectors/user.selector';
import { AboutRoutes } from 'src/app/pages/about/about.routes.misc';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { UniversalService } from 'src/app/shared/universal/universal.service';

@Component({
  selector: 'mt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input()
  public noLogo = false;
  public appRoutes = AppRoutes;
  public aboutRoutes = AboutRoutes;
  public isEU: Observable<boolean>;
  public currentDate = new Date();
  public isLoggedIn: Observable<boolean>;

  constructor(
    private store: Store<any>,
    private uniService: UniversalService,
    public enviService: EnvironmentService,
  ) {
    this.isLoggedIn = this.store.pipe(select(getIsAuthorized));
    this.isEU = this.store.pipe(select(getIsEU));
  }

  public getBlog() {
    if (!this.uniService.isPlatformServer()) {
      if (this.enviService.isCzechEnvironment) {
        return 'https://mytitle.cz/blog/';
      } else {
        return 'https://mytitle.com/blog/';
      }
    } else {
      return 'https://mytitle.com/blog/';
    }
  }
}
