import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { filter, first, map, switchMap, takeWhile, tap } from 'rxjs/operators';

import { AppRoutes } from 'src/app/app/app.routes.misc';
import { RepeatCreditChoice } from 'src/app/app/states/actions/credit-bundle-choice.actions';
import {
  CreateCreditOrder,
  CreditOrderAuthorized,
  CreditOrderSuccess,
  CreditOrderUnauthorized,
  OrderCreditActions,
} from 'src/app/app/states/actions/order-credit.actions';
import {
  CreateISICBuyingStandardError,
  CreateISICPaymentError,
  CreatePaymentStart
} from 'src/app/app/states/actions/payment.actions';
import { AppState } from 'src/app/app/states/models/app.models';
import {
  getIsSelectedBundleAcademy,
  getSelectedBundle,
  hasPricingChanged
} from 'src/app/app/states/selectors/credit-bundle-choice.selectors';
import { getPaymentGateway } from 'src/app/app/utils/get-payment-gateway';
import { LocationVerified, LocationVerifyActions } from 'src/app/auth/store/actions/locationVerify';
import { getIsAuthorized, getIsValidAcademy } from 'src/app/auth/store/selectors/user.selector';
import { BillingServiceClient } from 'src/app/client/services/billing-service.client';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { ErrorHelperService } from 'src/app/shared/services/error-helper.service';
import { getSavedVoucher } from '../selectors/voucher.selectors';
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class CreditOrderEffects {


  public readonly authorizeRequest = createEffect(() => this.storeActions.pipe(
    ofType<CreateCreditOrder>(OrderCreditActions.CREATE_CREDIT_ORDER),
    switchMap(action => this.store.pipe(
      select(getIsAuthorized),
      first(),
      map(isAuthorized => [action, isAuthorized] as [CreateCreditOrder, boolean]),
    )),
    map(([action, isAuthorized]) => isAuthorized ?
        new CreditOrderAuthorized(action.payload) : new CreditOrderUnauthorized()),
  ));


  public creditOrderCreate = createEffect(() => this.storeActions.pipe(
    ofType<CreditOrderAuthorized>(OrderCreditActions.CREDIT_ORDER_AUTHORIZED),
    tap(action => {
      if (!action.payload.file_order_guid) {
        this.router.navigate([AppRoutes.CreditOrderSummary]);
      }
    }),
    switchMap(
      (action: CreditOrderAuthorized) =>
        this.billingServiceClient.createCreditOrder(action.payload)
          .pipe(
            map(result => new CreditOrderSuccess(result)),
            this.errorHelperService.raiseError(),
          ),
    ),
  ));


  public readonly returnToCreditBundlesAfterAuthentication = createEffect(() => this.storeActions.pipe(
    ofType<CreditOrderUnauthorized>(OrderCreditActions.CREDIT_ORDER_UNAUTHORIZED),
    switchMap(() => this.store.pipe(
      select(getIsAuthorized),
      takeWhile(isAuthorized => !isAuthorized, true),
    )),
    tap(isAuthorized => {
      if (!isAuthorized) {
        this.router.navigate([AppRoutes.SignUp], { queryParams: { creditFlow: true } });
      }
    }),
    filter(Boolean),
    switchMap(() => this.storeActions.pipe(
        // wait until user location and account settings location is verified
        ofType<LocationVerified>(LocationVerifyActions.LOCATION_VERIFIED),
      )),
    switchMap(() => forkJoin([
        this.store.select(hasPricingChanged).pipe(first()),
        this.store.select(getSelectedBundle).pipe(first()),
        this.store.select(getIsSelectedBundleAcademy).pipe(first()),
        this.store.select(getSavedVoucher).pipe(first()),
        this.store.select(getIsValidAcademy).pipe(first()),
      ])),
    map(([pricingChanged, selectedBundle, isBundleAcademy, voucher, isUserAcademy]) => {
      if (isBundleAcademy && !isUserAcademy) {
        return new CreateISICPaymentError(new HttpErrorResponse({}))
      } else if (!isBundleAcademy && isUserAcademy) {
        return new CreateISICBuyingStandardError(new HttpErrorResponse({}))
      } else if (pricingChanged) {
        return new RepeatCreditChoice();
      } else {
          if (voucher) {
            return new CreateCreditOrder({
              config_guid: selectedBundle,
              voucher_code: voucher,
            });
          } else {
            return new CreateCreditOrder({
              config_guid: selectedBundle,
            });
          }
      }
    }),
  ));


  public triggerPaymentOnCreditOrderSuccess = createEffect(() => this.storeActions.pipe(
    ofType<CreditOrderSuccess>(OrderCreditActions.CREDIT_ORDER_SUCCESS),
    map(response => {
      this.dataLayerService.push({ event: 'paid-order-created' });
      return new CreatePaymentStart({
        credit_order_guid: response.payload.credit_order_guid,
        payment_gateway: getPaymentGateway(),
      });
    }),
  ));

  constructor(
    private dataLayerService: DataLayerService,
    private storeActions: Actions,
    private router: Router,
    private store: Store<AppState>,
    private errorHelperService: ErrorHelperService,
    private billingServiceClient: BillingServiceClient,
  ) {
  }
}
