import { Component } from '@angular/core';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { EnvironmentService } from '../../shared/services/environment.service';
import { faqStructure, questionsAndAnswersCS } from './faq';

@Component({
  selector: 'mt-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  public items;
  public tabs = ['Mytitle service', 'Protection', 'Blockchain', 'Ownership certification'];

  constructor(private enviService: EnvironmentService) {
    this.items = faqStructure[0];
    if (this.enviService.isCzechEnvironment) {
      this.tabs = ['Časté dotazy'];
      this.items = questionsAndAnswersCS;
    }
  }

  selectFaq(changeEvent: MatTabChangeEvent) {
    this.items = faqStructure[changeEvent.index];
  }
}
