import { Action } from '@ngrx/store';

import { Document } from 'src/app/client/models/mytitle-service.models';
import { SharedDocumentAction, SharedDocumentActions } from '../actions/shared-document.actions';
import { GetSharedDocumentsSuccess } from './../actions/shared-document.actions';

export interface SharedDocumentsState {
  documents: Document[];
  documentsPagination: {
    count: number;
    offset: number;
    totalCount: number;
  };
  documentsLoading: boolean;
  additionalDocumentsLoading: boolean;
}

export const sharedDocumentsInitialState: SharedDocumentsState = {
  documents: [],
  documentsPagination: {
    count: null,
    offset: null,
    totalCount: null,
  },
  documentsLoading: false,
  additionalDocumentsLoading: false,
};

export function sharedDocumentsReducer(
  state = sharedDocumentsInitialState,
  generic: Action,
): SharedDocumentsState {

  const action = generic as SharedDocumentAction;
  switch (action.type) {

    case SharedDocumentActions.GET_SHARED_DOCUMENTS: {
      if (action.payload.offset) {
        return {
          ...state,
          additionalDocumentsLoading: true,
        };
      } else {
        return {
          ...state,
          documentsLoading: true,
        };
      }
    }

    case SharedDocumentActions.GET_SHARED_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        documentsLoading: false,
        additionalDocumentsLoading: false,
        documents: getSharedDocumentsState(state, action),
        documentsPagination: {
          count: action.payload.results_len,
          offset: action.payload.offset,
          totalCount: action.payload.total,
        },
      };
    }

    case SharedDocumentActions.CLEAN_SHARED_DOCUMENTS: {
      return sharedDocumentsInitialState;
    }

    default: return state;
  }
}

function getSharedDocumentsState(state: SharedDocumentsState, action: GetSharedDocumentsSuccess) {
  // if returned request is already with offset check if there are alread some
  // documents in store and if they are concat those two arrays otherwise store returned one
  // if returned request has offset 0, wipe store and store these results
  if (action.payload.offset > 0) {
    return state.documents.length > 0 ?
      state.documents.concat(action.payload.results) :
      action.payload.results;
  } else return action.payload.results;
}
