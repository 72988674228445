// Signer entity locks name and email of the signing user at the time of transaction
import { HttpErrorResponse } from '@angular/common/http';

import { AppRoutes } from 'src/app/app/app.routes.misc';
import { MytitleError } from 'src/app/client/models/client.models';
import {
  CreateDocumentsDeletionParams,
  Document,
  FinishDocumentsDeletionResponse,
} from 'src/app/client/models/mytitle-service.models';
import { FileOrderRequestParams } from 'src/app/client/models/order.models';
import { LoginRoutes } from 'src/app/pages/login/login.routes.misc';
import { SignUpRoutes } from 'src/app/pages/sign-up/sign-up.routes.misc';

export interface FileState {
  /**
   * File selected for upload.
   */
  file: File;
  folder: string;
  /**
   * Document created out of uploaded file.
   */
  document: Document;
  isFileUploading: boolean;
  canBePatched: boolean;
  fileError: HttpErrorResponse;
  /** Indicates that document should be signed as free */
  is_free: boolean;
  percentUploaded: number;
  isFileUpgrading: boolean;
  upgradingDocument: FileOrderRequestParams;
  fileParentGuid: string;

  documentsBeingDeleting: boolean;
  documentsToDelete: CreateDocumentsDeletionParams;
  finishingDocumentsDeleting: boolean;
  deletedDocuments: FinishDocumentsDeletionResponse;
  error: MytitleError[];
}

export const progressRoutes: string[] = [
  `/${AppRoutes.PaymentConfirmation}`,
  `/${AppRoutes.DocumentCredentials}`,
  `/${AppRoutes.WatermarkSelection}`,
  `/${AppRoutes.DocumentProgress}`,
  `/${AppRoutes.CreditBundleChoice}?fileFlow=true`,
  `/${AppRoutes.CreditOrderSummary}?fileFlow=true`,
  `/${AppRoutes.SignUp}?fileFlow=true`,
  `/${AppRoutes.SignUp}?creditFlow=true`,
  `/${AppRoutes.SignUp}/${SignUpRoutes.Country}?fileFlow=true`,
  `/${AppRoutes.SignUp}/${SignUpRoutes.Country}?creditFlow=true`,
  `/${AppRoutes.Login}?fileFlow=true`,
  `/${AppRoutes.Login}?creditFlow=true`,
  `/${AppRoutes.Login}/${LoginRoutes.TwoFA}?fileFlow=true`,
  `/${AppRoutes.Login}/${LoginRoutes.TwoFA}?creditFlow=true`,
  `/${AppRoutes.EmailConfirmation}?fileFlow=true`,
  `/${AppRoutes.EmailConfirmation}?creditFlow=true`,
];
