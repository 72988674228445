/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';

import { Document, PatchDocumentRequest } from 'src/app/client/models/mytitle-service.models';
import { FileOrderResponse } from 'src/app/client/models/order.models';
import {
  FileExt,
  FolderExt,
  ParsedFolderStructure,
} from '../reducers/upload-folder.reducer';

export enum UploadFolderActions {
  DISPLAY_FOLDER_CREDIT_CONFIRMATION = '[Folder] Display folder credit confirmation',
  WAIT_UNTIL_FOLDER_STRUCTURE_IS_READY = '[Folder] Waiting until folder structure is ready',

  UPLOAD_FOLDER_START = '[Folder] Upload folder start',
  UPLOAD_FOLDER_FINISHED = '[Folder] Upload folder finished',

  CREATE_FOLDER_STRUCTURE_BUFFER = '[Folder] Create folder structure buffer',
  UPDATE_FOLDER_STRUCTURE_BUFFER = '[Folder] Update folder structure buffer',

  CREATE_FOLDER_STRUCTURE_START = '[Folder] Create folder structure start',
  CREATE_FOLDER_STRUCTURE_SUCCESS = '[Folder] Create folder structure success',

  UPLOAD_FILES_INTO_FOLDER_START = '[Folder] Upload files into folder start',
  UPLOAD_FILES_INTO_FOLDER_SUCCESS = '[Folder] Upload files into folder success',

  UPLOAD_FOLDER_FILE_START = '[Folder] Upload folder file start',
  UPLOAD_FOLDER_FILE_PROGRESS = '[Folder] Upload folder file progress',
  UPLOAD_FOLDER_FILE_SUCCESS = '[Folder] Upload folder file success',
  UPLOAD_FOLDER_FILE_ERROR = '[Folder] Upload folder file error',
  UPLOAD_FOLDER_FILE_RETRY = '[Folder] Upload folder file retry',
  UPLOAD_FOLDER_FILE_REMOVE = '[Folder] Upload folder file remove',

  PATCH_FOLDER_FILES = '[Folder] Patch folder files',
  PATCH_FOLDER_FILE_START = '[Folder] Patch folder file start',
  PATCH_FOLDER_FILE_SUCCESS = '[Folder] Patch folder file success',

  CREATE_FOLDER_FILE_ORDERS_START = '[Folder] Create folder file orders start',
  CREATE_FOLDER_FILE_ORDERS_SUCCESS = '[Folder] Create folder file orders success',

  RESET_STORE = '[Folder] Reset upload folder store',
}

export class DisplayFolderCreditConfirmation implements Action {
  public readonly type = UploadFolderActions.DISPLAY_FOLDER_CREDIT_CONFIRMATION;
  constructor(public payload: { files: FileExt[]; parentGuid: string }) {}
}

export class UploadFolderStart implements Action {
  public readonly type = UploadFolderActions.UPLOAD_FOLDER_START;
  constructor(public payload: PatchDocumentRequest) {}
}

export class UploadFolderFinished implements Action {
  public readonly type = UploadFolderActions.UPLOAD_FOLDER_FINISHED;
}

export class CreateFolderStructureBuffer implements Action {
  public readonly type = UploadFolderActions.CREATE_FOLDER_STRUCTURE_BUFFER;
  constructor(public payload: { structure: ParsedFolderStructure; parentGuid: string }) {}
}

export class UpdateFolderStructureBuffer implements Action {
  public readonly type = UploadFolderActions.UPDATE_FOLDER_STRUCTURE_BUFFER;
  constructor(public payload: FolderExt[]) {}
}

export class CreateFolderStructureStart implements Action {
  public readonly type = UploadFolderActions.CREATE_FOLDER_STRUCTURE_START;
  constructor(public payload: { folders: FolderExt[]; parentGuid: string }) {}
}

export class CreateFolderStructureSuccess implements Action {
  public readonly type = UploadFolderActions.CREATE_FOLDER_STRUCTURE_SUCCESS;
  constructor(public payload: FolderExt[]) {}
}

export class WaitUntilFolderStructureIsCreated {
  public readonly type = UploadFolderActions.WAIT_UNTIL_FOLDER_STRUCTURE_IS_READY;
}

export class UploadFilesIntoFolderStart {
  public readonly type = UploadFolderActions.UPLOAD_FILES_INTO_FOLDER_START;
}

export class UploadFilesIntoFolderSuccess {
  public readonly type = UploadFolderActions.UPLOAD_FILES_INTO_FOLDER_SUCCESS;
}

export class UploadFolderFileStart {
  public readonly type = UploadFolderActions.UPLOAD_FOLDER_FILE_START;
  constructor(public payload: FileExt) {}
}

export class UploadFolderFileProgress {
  public readonly type = UploadFolderActions.UPLOAD_FOLDER_FILE_PROGRESS;
  constructor(public payload: { percentDone: number; storeGuid: string }) {}
}

export class UploadFolderFileSuccess {
  public readonly type = UploadFolderActions.UPLOAD_FOLDER_FILE_SUCCESS;
  constructor(public payload: { file: Document; storeGuid: string }) {}
}

export class UploadFolderFileError {
  public readonly type = UploadFolderActions.UPLOAD_FOLDER_FILE_ERROR;
  constructor(public payload: { error: number; storeGuid: string }) {}
}

export class UploadFolderFileRetry {
  public readonly type = UploadFolderActions.UPLOAD_FOLDER_FILE_RETRY;
  constructor(public payload: string) {}
}
export class UploadFolderFileRemove {
  public readonly type = UploadFolderActions.UPLOAD_FOLDER_FILE_REMOVE;
  constructor(public payload: string) {}
}

export class CreateFolderFileOrdersStart {
  public readonly type = UploadFolderActions.CREATE_FOLDER_FILE_ORDERS_START;
}

export class CreateFolderFileOrdersSuccess {
  public readonly type = UploadFolderActions.CREATE_FOLDER_FILE_ORDERS_SUCCESS;
  constructor(public payload: FileOrderResponse) {}
}

export class PatchFolderFiles {
  public readonly type = UploadFolderActions.PATCH_FOLDER_FILES;
}

export class PatchFolderFileStart {
  public readonly type = UploadFolderActions.PATCH_FOLDER_FILE_START;
  constructor(public payload: { guid: string; params: PatchDocumentRequest }) {}
}

export class PatchFolderFileSuccess {
  public readonly type = UploadFolderActions.PATCH_FOLDER_FILE_SUCCESS;
}

export class UploadFolderReset {
  public readonly type = UploadFolderActions.RESET_STORE;
}

export type UploadFolderAction =
  | DisplayFolderCreditConfirmation
  | UploadFolderStart
  | UploadFolderFinished
  | UploadFolderReset
  | CreateFolderStructureBuffer
  | UpdateFolderStructureBuffer
  | CreateFolderStructureStart
  | CreateFolderStructureSuccess
  | WaitUntilFolderStructureIsCreated
  | UploadFilesIntoFolderStart
  | UploadFilesIntoFolderSuccess
  | UploadFolderFileStart
  | UploadFolderFileProgress
  | UploadFolderFileSuccess
  | UploadFolderFileError
  | UploadFolderFileRetry
  | UploadFolderFileRemove
  | CreateFolderFileOrdersStart
  | CreateFolderFileOrdersSuccess
  | PatchFolderFiles
  | PatchFolderFileStart
  | PatchFolderFileSuccess;
