import { CreditBundleChoiceEffects } from './effects/credit-bundle-choice.effects';
import { FileRoutingEffects } from './effects/file-routing.effects';
import { FileEffects } from './effects/file.effects';
import { CreditOrderEffects } from './effects/order-credit.effects';
import { FileOrderEffects } from './effects/order-file.effects';
import { PaymentEffects } from './effects/payment.effects';
import { RouterEffects } from './effects/router.effects';
import { UploadFolderEffects } from './effects/upload-folder.effects';
import { VoucherEffects } from './effects/voucher.effects';

export const appEffects = [
  CreditOrderEffects,
  FileEffects,
  FileRoutingEffects,
  PaymentEffects,
  FileOrderEffects,
  CreditBundleChoiceEffects,
  RouterEffects,
  UploadFolderEffects,
  VoucherEffects,
];
