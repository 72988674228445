 /* eslint-disable max-len */

export enum Notifications {
  Expires = 'Expires in ',
  UserNotRegistered = 'This user is not registered with Mytitle.',
  UserRegistered = 'This user is registered with Mytitle.',
  NoLimit = 'This sharing has no time limit.',
  DocUpgradeSuccess = 'You successfully upgraded your document!',
  FolderUpgradeSuccess = 'You successfully upgraded your folder!',
  Unexpected = 'Unexpected error occured during document upload. Please try again.',
  NotEnoughCredits = 'Not enough credits',
  FolderUpgrade = 'Are you sure you would like to create certificate over this folder? This folder would be locked and you will no longer have an option to change it.',
  Upgrade = 'Are you sure you would like to upgrade this document?',
  MissingGuid = 'Missing file guid',
  QuotaExceeded = 'Free quota exceeded',
  LinkSent = 'The confirmation link to finish the deletion was sent to your email.',
  FileChanged = 'Your file was successfully changed.',
  FileMoved = 'Your file was successfully moved.',
  FileRenamed = 'Your file was successfully renamed.',
  NotChecked = 'You did not check any document to delete.',
  DeleteSelected = 'Do you really want to delete selected',
  Document = 'document',
  Documents = 'documents',
  FolderMoved = 'Your folder was successfully moved.',
  FolderRenamed = 'Your folder was successfully renamed.',
  LoggedOut = 'You have been logged out',
  FileEmpty = 'Your file is empty',
  MaxSize = 'Maximum file size is 500 MB',
  CopiedLink = 'Link has been copied to clipboard',
  AlreadySigned = 'You are already registered with us. We logged you instead.',
  DisabledTwoFa = '2-Step Verification has been successfully disabled.',
  DeletionStarted = 'Your account have been deactivated and will be deleted in 30 days.',
  AccountDeleted = 'Your account has been deleted.',
  ErrorOccured = 'An error occured during saving your account info changes.',
  InfoChanged = 'Account info successfully changed.',
  Invoice = 'Invoice could not be downloaded.',
  SharingWithYourself = 'You can not share a document with yourself.',
  AlreadySharing = 'This document is already being shared with this email address.',
  Confirmed = 'Certificate can only be downloaded once the status is "confirmed".',
  AuthError = 'Authentication error occured. Please try log in again.',
  MailResent = 'The confirmation email was resent',
  GoogleCodeIncorrect = 'Google authentication code is incorrect or expired. Please try again.',
  GoogleWindowClosed = 'Authentication was unsuccessful. Google authentication window was closed.',
  GoogleAccessDenied = 'Authentication was unsuccessful. Google denied access for provided account.',
  GoogleError = 'An error occured during authentication with your Google account. Try again later.',
  WithoutName = 'Uploaded without name',
  WrongButton = 'File was selected instead of a folder. Use upload file button instead.',
  NothingSelected = 'No folder or file were selected.',
  PaygateUnexpected = 'Unexpected error occured trying to access PayGate. Please try again.',
  PaygateInvalidISIC = 'To purchase the Academy credits, please first provide a valid ISIC card.',
  PaygateISICBuyingStandard = 'You are an Academy member. Please choose from the discounted Academy bundles.',
  LimitExceeded = 'Your free files limit has been exceeded',
  FreeDocumentsInFolder = 'There are free documents in a folder you\'d like to certify. Upgrade files first.',
  FolderLocked = 'You cannot change locked folder.',
  FolderEmpty = 'You cannot upgrade and lock an empty folder.',
  ContinueAfterUpload = 'You can continue this process after your file is uploaded.',
  Remove = 'This removes credentials.',
  FileOrderError = 'There was an error while processing your file. Please try again.',
}
