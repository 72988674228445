import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';
import { BillingServiceClient } from 'src/app/client/services/billing-service.client';
import {
  GetCreditBalance,
  GetCreditBalanceActions,
  GetCreditBalanceError,
  GetCreditBalanceSuccess,
} from '../actions/getCreditBalance.actions';

@Injectable()
export class GetCreditBalanceEffects {


  public getUserInfo = createEffect(() => this.storeActions.pipe(
    ofType<GetCreditBalance>(GetCreditBalanceActions.GET_CREDIT_BALANCE),
    switchMap(() => this.billingServiceClient.getUserCreditBalance()
      .pipe(
        map(result => new GetCreditBalanceSuccess(result),
        catchError((error: HttpErrorResponse) => of(new GetCreditBalanceError(error))),
      )),
    ),
  ));

  constructor(
    private storeActions: Actions,
    private billingServiceClient: BillingServiceClient,
  ) { }
}
