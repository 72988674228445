import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { GopayPaymentService, GOPAY_PAYMENT_COUNTRIES } from 'src/app/app/services/gopay-payment.service';
import { FooterModule } from 'src/app/partials/footer/footer.module';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../../environments/environment';
import { AuthModule } from '../auth/auth.module';
import {
  ConfirmationDialogModule,
} from '../partials/confirmation-dialog/confirmation-dialog.module';
import { VideoDialogModule } from '../partials/video-dialog/video-dialog.module';
import { NavbarModule } from '../partials/navbar/navbar.module';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import {
  ConfirmationCodeDirective,
} from './directives/confirmation-code.directive';
import { ScrollModule } from './directives/scroll/scroll.module';
import { HomeModule } from './home/home.module';
import { appEffects } from './states/app.effects';
import { appReducers, metaReducers } from './states/app.reducers';
import { RouterSerializer } from './states/router.serializer';


registerLocaleData(localeCs, 'cs');

// avoid logging errors on localhost and SSR
if (typeof window !== 'undefined' && location.hostname.includes('mytitle.com')) {
  Sentry.init({
    dsn: 'https://e5ca0119bee145fa8a750d73c9dd9e0b@o222939.ingest.sentry.io/1425188',
    environment: environment.production ? 'production' : 'dev',
    release: environment.version,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationCodeDirective,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AuthModule,
    HomeModule,
    NavbarModule,
    FooterModule,
    ConfirmationDialogModule,
    VideoDialogModule,
    ScrollModule,
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 100],
    }),
    StoreModule.forRoot(appReducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: RouterSerializer,
      routerState: RouterState.Minimal,
    }),
    EffectsModule.forRoot(appEffects),
    !environment.production ? StoreDevtoolsModule.instrument({maxAge: 25}) : [],
  ],
  providers: [
    environment.production ? {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    } : [],
    GOPAY_PAYMENT_COUNTRIES.includes(environment.language) ? GopayPaymentService : [],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
