import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { forkJoin, of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap, switchMapTo } from 'rxjs/operators';

import { AppRoutes } from 'src/app/app/app.routes.misc';
import { UsersServiceClient } from 'src/app/client/services/users-service.client';

import { getRouterState } from 'src/app/app/states/selectors/router.selectors';
import { LoginRoutes } from 'src/app/pages/login/login.routes.misc';
import { getLoginParams } from 'src/app/pages/login/store/login.selector';
import { GetUserInfo } from '../actions/getUserInfo.action';
import { GetCreditBalance } from '../actions/getCreditBalance.actions';
import { GoogleLoginStart } from '../actions/google-auth.action';
import { Login, LoginActions, LoginError, LoginSuccess, LoginTwoFA } from '../actions/login.action';
import { RegisterClean } from '../actions/register.action';
import { LoginErrorsEnum } from '../models/user';
import { RegisterState } from '../reducers/register.reducer';
import { getGoogleIdToken } from '../selectors/google-auth.selector';
import { getRegisterState } from '../selectors/register.selector';

@Injectable()
export class LoginEffects {

  public login = createEffect(() => this.storeActions.pipe(
    ofType<Login>(LoginActions.LOGIN),
    switchMap(action => this.usersServiceClient.login(action.payload)
      .pipe(
        map((
          { access_token: accessToken, expires_in, refresh_token: refreshToken },
        ) => new LoginSuccess({
          email: action.payload.email,
          token: {
            accessToken,
            expires: new Date(Date.now() + expires_in * 1000),
            refreshToken,
          },
        })),
        catchError((error: HttpErrorResponse) => of(new LoginError(error.error))),
      ),
    ),
  ));


  public readonly loginWithTwoFA = createEffect(() => this.storeActions.pipe(
    ofType<LoginTwoFA>(LoginActions.LOGIN_TWO_FA),
    switchMap(() => forkJoin([
        this.store.select(getLoginParams).pipe(first()),
        this.store.select(getGoogleIdToken).pipe(first()),
      ])),
    map(([loginParams, googleIdToken]) => {
      if (loginParams && loginParams.email && loginParams.password && !googleIdToken) {
        return new Login(loginParams);
      } else if (loginParams && loginParams.code && googleIdToken) {
        return new GoogleLoginStart();
      } else {
        return new Login(loginParams);
      }
    }),
  ));


  public loadUserAfterLogin = createEffect(() => this.storeActions.pipe(
    ofType<LoginSuccess>(LoginActions.LOGIN_SUCCESS),
    switchMapTo(this.store.pipe(
      select(getRegisterState),
      first(),
      mergeMap((registerState: RegisterState) => {
        const actionsToReturn: any[] = [
          new GetUserInfo(),
          new GetCreditBalance(),
        ];
        if (registerState && registerState.email) actionsToReturn.push(new RegisterClean());

        return actionsToReturn;
      }),
    )),
  ));


  public onLoginError = createEffect(() => this.storeActions.pipe(
    ofType<LoginError>(LoginActions.LOGIN_ERROR),
    map(action => action.payload[0]),
    switchMap(error => forkJoin([
        of(error),
        this.store.pipe(
          select(getRouterState),
          first(),
        ),
      ])),
    map(([error, routerState]) => {
      if (error.code === LoginErrorsEnum.REGISTRATION_NOT_FINISHED) {
        if (routerState.queryParams.fileFlow) {
          this.router.navigate(
            [AppRoutes.EmailConfirmation],
            { queryParams: { fileFlow: true } },
          );
        } else if (routerState.queryParams.creditFlow) {
          this.router.navigate(
            [AppRoutes.EmailConfirmation],
            { queryParams: { creditFlow: true } },
          );
        } else {
          this.router.navigate([AppRoutes.EmailConfirmation]);
        }
      } else if (error.code === LoginErrorsEnum.TWO_FA_CODE_MISSING) {
        if (routerState.queryParams.fileFlow) {
          this.router.navigate(
            [AppRoutes.Login, LoginRoutes.TwoFA],
            { queryParams: { fileFlow: true } },
          );
        } else if (routerState.queryParams.creditFlow) {
          this.router.navigate(
            [AppRoutes.Login, LoginRoutes.TwoFA],
            { queryParams: { creditFlow: true } },
          );
        } else {
          this.router.navigate([AppRoutes.Login, LoginRoutes.TwoFA]);
        }
      }
    }),
  ), { dispatch: false });

  constructor(
    private router: Router,
    private store: Store<any>,
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
  ) {
  }
}
