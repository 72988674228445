import { Action } from '@ngrx/store';
import { VoucherError } from 'src/app/client/models/credit.models';
import { VoucherAction, VoucherActions } from '../actions/voucher.actions';

export interface VoucherState {
  isValidating: boolean;
  discount_percentage: number;
  voucherError: VoucherError;
  hasVoucherError: boolean;
  code: string;
}

export const initialVoucherState: VoucherState = {
  isValidating: false,
  discount_percentage: null,
  code: null,
  voucherError: null,
  hasVoucherError: false,
};

export function voucherReducer(
  state = initialVoucherState,
  generic: Action): VoucherState {

  const action = generic as VoucherAction;

  switch (action.type) {
    case VoucherActions.VALIDATE_VOUCHER: {
      return {
        ...state,
        isValidating: true,
        code: action.payload.code,
      };
    }
    case VoucherActions.VALIDATE_VOUCHER_SUCCESS: {
      return {
        ...state,
        isValidating: false,
        discount_percentage: action.payload.discount_percentage,
      };
    }
    case VoucherActions.VALIDATE_VOUCHER_ERROR: {
      return {
        ...state,
        isValidating: false,
        voucherError: action.payload,
        hasVoucherError: true,
      };
    }
    case VoucherActions.CLEAR_VOUCHER: {
      return {
        ...initialVoucherState,
      };
    }
  }

  return state;
}
