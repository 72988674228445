<div class="main-body" mtScrollManager [ngClass]="{'main-body--light-azure' : (colorTheme | async) !== 'transparent'}">
  <mt-navbar
    [ngClass]="['main-body__navbar', 'main-body__navbar--desktop', (colorTheme | async)]"
    [isSticky]="isSticky"
    [initializeHeaderElement]="initializeHeaderElement | async">
  </mt-navbar>
  <mt-navbar-mobile
    [ngClass]="['main-body__navbar', 'main-body__navbar--mobile', (colorTheme | async)]"
    [initializeHeaderElement]="initializeHeaderElement | async">
  </mt-navbar-mobile>

  <div
    class="main-body__content mb-auto"
    [class.container]="applyRouterOutletContainer | async">
    <router-outlet></router-outlet>
  </div>

  <mt-footer
    class="main-body__footer"
    [class]="colorTheme | async"
    [noLogo]="(displayFooterLogo | async) === false">
  </mt-footer>
</div>
