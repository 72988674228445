<div class="secure-ideas">
  <div class="container">
    <div class="d-flex justify-content-between flex-wrap pt-5 py-lg-5">
      <img class="payment-icon" alt=""
           src="/assets/gopay/gopay_bannery-bile.png">
      <img class="payment-icon ml-2 ml-sm-0" alt=""
           src="/assets/gopay/visa.svg">
      <img class="payment-icon ml-2 ml-sm-0" alt=""
           src="/assets/gopay/verified-by-visa.svg">
      <img class="payment-icon ml-2 ml-sm-0" alt=""
           src="/assets/gopay/visa-electron.svg">
      <img class="payment-icon ml-2 ml-sm-0" alt=""
           src="/assets/gopay/mastercard.svg">
      <img class="payment-icon ml-2 ml-sm-0" alt=""
           src="/assets/gopay/mastercard-electronic.svg">
      <img class="payment-icon ml-2 ml-sm-0" alt=""
           src="/assets/gopay/mastercard-secure-code.svg">
      <img class="payment-icon ml-2 ml-sm-0" alt=""
           src="/assets/gopay/maestro.svg">
    </div>
  </div>
</div>
