/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { VoucherError, VoucherRequest, VoucherResponse } from 'src/app/client/models/credit.models';

export enum VoucherActions {
  VALIDATE_VOUCHER = '[Voucher] Validate voucher',
  VALIDATE_VOUCHER_SUCCESS = '[Voucher] Validate voucher success',
  VALIDATE_VOUCHER_ERROR = '[Voucher] Validate voucher error',
  CLEAR_VOUCHER = '[Voucher] Clear voucher',
}

export class ValidateVoucher implements Action {
  public readonly type = VoucherActions.VALIDATE_VOUCHER;
  constructor(public payload: VoucherRequest) {}
}

export class ValidateVoucherSuccess implements Action {
  public readonly type = VoucherActions.VALIDATE_VOUCHER_SUCCESS;
  constructor(public payload: VoucherResponse) {}
}

export class ValidateVoucherError implements Action {
  public readonly type = VoucherActions.VALIDATE_VOUCHER_ERROR;
  constructor(public payload: VoucherError) {}
}

export class ClearVoucher implements Action {
  public readonly type = VoucherActions.CLEAR_VOUCHER;
}

export type VoucherAction =
ValidateVoucher |
ValidateVoucherSuccess |
ValidateVoucherError |
ClearVoucher;
