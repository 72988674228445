import { FileLimitEffects } from './effects/file-limit.effects';
import { GetCreditBalanceEffects } from './effects/getCreditBalance.effect';
import { GetGeoIPEffects } from './effects/getGeoIP.effect';
import { GetUserInfoEffects } from './effects/getUserInfo.effect';
import { GoogleAuthEffects } from './effects/google-auth.effects';
import { LoginEffects } from './effects/login.effect';
import { PersistenceEffects } from './effects/persistence.effect';
import { RefreshTokenEffects } from './effects/refreshToken.effect';
import { RegisterEffects } from './effects/register.effect';
import { ResetPasswordConfirmEffects } from './effects/reset-password-confirm.effects';
import { ResetPasswordEffects } from './effects/reset-password.effects';
import { VerifyEmailEffects } from './effects/verifyEmail.effect';

export const authEffects = [
  GoogleAuthEffects,
  FileLimitEffects,
  GetCreditBalanceEffects,
  GetGeoIPEffects,
  GetUserInfoEffects,
  LoginEffects,
  RefreshTokenEffects,
  PersistenceEffects,
  RegisterEffects,
  VerifyEmailEffects,
  ResetPasswordEffects,
  ResetPasswordConfirmEffects,
];
