import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { googleAuthReducer, GoogleAuthState } from './reducers/google-auth.reducer';
import { registerReducer, RegisterState } from './reducers/register.reducer';
import { resetPasswordReducer, ResetPasswordState } from './reducers/reset-password.reducer';
import { userReducer, UserState } from './reducers/user.reducer';
import { verifyEmailReducer, VerifyEmailState } from './reducers/verifyEmail.reducer';

export interface AuthState {
  register: RegisterState;
  user: UserState;
  verifyEmail: VerifyEmailState;
  resetPassword: ResetPasswordState;
  google: GoogleAuthState;
}

export const authReducers: ActionReducerMap<AuthState> = {
  register: registerReducer,
  user: userReducer,
  verifyEmail: verifyEmailReducer,
  resetPassword: resetPasswordReducer,
  google: googleAuthReducer,
};

export const authStoreName = 'auth';

export const getAuthState = createFeatureSelector<AuthState>(authStoreName);
