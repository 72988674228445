
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, merge } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

import { AppRoutes } from 'src/app/app/app.routes.misc';
import {
  GetGeoIPActions,
  GetGeoIPSuccess,
} from 'src/app/auth/store/actions/getGeoIP.action';
import {
  GetUserInfoActions,
  GetUserInfoSuccess,
} from 'src/app/auth/store/actions/getUserInfo.action';
import {
  Logout,
  LogoutActions,
} from 'src/app/auth/store/actions/logout.action';
import { getGeoIPState, getIsEU } from 'src/app/auth/store/selectors/user.selector';
import { CreditConfig } from 'src/app/client/models/credit.models';
import { BillingServiceClient } from 'src/app/client/services/billing-service.client';
import {
  getCurrencySelector,
  getCurrencySign,
} from 'src/app/pages/credit-bundle-choice/credit-bundle-choice.utils';
import {
  ChangeUserInfoActions,
  ChangeUserInfoSuccess,
} from 'src/app/pages/settings/store/actions/change-user-info.actions';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { ErrorHelperService } from 'src/app/shared/services/error-helper.service';
import {
  CreditBundleChoiceActions,
  GetCreditBundles,
  GetCreditBundlesSuccess,
  GetCreditOrderSummary,
  GetPriceRange,
  GetPriceRangeSuccess,
  RepeatCreditChoice,
} from '../actions/credit-bundle-choice.actions';
import { getRouterState } from '../selectors/router.selectors';
import { AppState } from './../models/app.models';

@Injectable()
export class CreditBundleChoiceEffects {


  public readonly triggerGetStartingPrice = createEffect(() => merge(
    this.storeActions.pipe(ofType<GetGeoIPSuccess>(GetGeoIPActions.GET_GEO_IP_SUCCESS)),
    this.storeActions.pipe(ofType<GetUserInfoSuccess>(GetUserInfoActions.GET_USER_INFO_SUCCESS)),
    this.storeActions.pipe(ofType<ChangeUserInfoSuccess>(
      ChangeUserInfoActions.CHANGE_USER_INFO_SUCCESS)),
    this.storeActions.pipe(ofType<Logout>(LogoutActions.LOGOUT)),
  ).pipe(map(() => new GetPriceRange())));


  public readonly getCreditPackages = createEffect(() => this.storeActions.pipe(
    ofType<GetCreditBundles>(CreditBundleChoiceActions.GET_CREDIT_BUNDLES),
    switchMap(() => this.billingServiceClient.getCreditOrderConfig().pipe(
        map(response => new GetCreditBundlesSuccess(response)),
        this.errorHelperService.raiseError(),
      )),
  ));


  public readonly retreiveStartingPrice = createEffect(() => this.storeActions.pipe(
    ofType<GetPriceRange>(CreditBundleChoiceActions.GET_PRICE_RANGE),
    switchMap(() => this.billingServiceClient.getCreditOrderConfig().pipe(
        this.errorHelperService.raiseError(),
      )),
    switchMap(bundles => forkJoin([
        this.store.select(getGeoIPState).pipe(first()),
        this.store.select(getIsEU).pipe(first()),
      ])
        .pipe(
          map(([_, isEU]) => {
            if (this.enviService.isCzechEnvironment) {
              return bundles.results.filter(bundle => bundle.currency === 'CZK');
            } else {
              // eslint-disable-next-line max-len
              return bundles.results.filter(bundle => bundle.currency === getCurrencySelector(isEU));
            }
          }),
        )),
    map(bundles => this.getPriceRange(bundles)),
    map(startingPrice => new GetPriceRangeSuccess(startingPrice)),
  ));


  public readonly redirectBackToCreditBundles = createEffect(() => this.storeActions.pipe(
    ofType<RepeatCreditChoice>(CreditBundleChoiceActions.REPEAT_CREDIT_CHOICE),
    tap(() => this.router.navigate([AppRoutes.CreditBundleChoice])),
  ), { dispatch: false });


  public readonly navigateIfSelectCreditBundles = createEffect(() => this.storeActions.pipe(
    ofType<GetCreditOrderSummary>(CreditBundleChoiceActions.GET_CREDIT_ORDER_SUMMARY),
    switchMap(() => this.store.pipe(
      select(getRouterState),
      first())),
    tap(router => {
      if (router.queryParams.fileFlow) {
        this.router.navigate(
          [AppRoutes.CreditOrderSummary],
          { queryParams: { fileFlow: true } },
        );
      } else if (router.queryParams.creditFlow) {
        this.router.navigate(
          [AppRoutes.CreditOrderSummary],
          { queryParams: { creditFlow: true } },
        );
      } else this.router.navigate([AppRoutes.CreditOrderSummary]);
    }),
  ), { dispatch: false });

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private storeActions: Actions,
    private billingServiceClient: BillingServiceClient,
    private errorHelperService: ErrorHelperService,
    private enviService: EnvironmentService,
  ) { }

  private getPriceRange(bundles: CreditConfig[]): string[] {
    const bundleArray: number[] = [];

    bundles.forEach(bundle => {
      bundleArray.push(parseFloat(bundle.total_price) / bundle.credits_count);
      bundleArray.sort((a, b) => a - b);
    });

    return [
      `${bundleArray[0].toFixed(2)}${getCurrencySign(bundles[0].currency)}`,
      `${bundleArray[bundleArray.length - 1].toFixed(2)}${getCurrencySign(bundles[0].currency)}`,
    ];
  }
}
