/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { TokenInfo } from '../models/tokenInfo';

export enum LoadTokenActions {
  LOAD_TOKEN_SUCCESS = '[Auth] LoadToken Success',
  LOAD_TOKEN_ERROR = '[Auth] LoadToken Error',
}

export class LoadTokenSuccess implements Action {
  public readonly type = LoadTokenActions.LOAD_TOKEN_SUCCESS;
  constructor(public payload: {
    token: TokenInfo;
  }) { }
}

export class LoadTokenError implements Action {
  public readonly type = LoadTokenActions.LOAD_TOKEN_ERROR;
}

export type LoadTokenAction = LoadTokenSuccess | LoadTokenError;
