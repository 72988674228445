import { Action } from '@ngrx/store';
import {
  ChangeDocumentAction,
  DeleteDocumentsAction,
  DeleteDocumentsActions,
  LoadDocumentsActions,
} from 'src/app/pages/my-documents/store/actions/my-documents.actions';
import { FileAction, FileActions } from '../actions/file.action';
import { FileState } from '../models/file.models';

export const initialState: FileState = {
  file: null,
  folder: null,
  document: null,
  isFileUploading: false,
  canBePatched: false,
  percentUploaded: 0,
  fileError: null,
  isFileUpgrading: false,
  /** Indicates that document should be signed as free */
  is_free: false,
  upgradingDocument: null,
  fileParentGuid: null,

  documentsBeingDeleting: false,
  documentsToDelete: null,
  finishingDocumentsDeleting: false,
  deletedDocuments: null,
  error: null,
};

export function fileReducer(state: FileState = initialState, genericAction: Action): FileState {

  const action = genericAction as FileAction | ChangeDocumentAction | DeleteDocumentsAction;

  switch (action.type) {

    case LoadDocumentsActions.UPDATE_MY_DOCUMENTS: {
      return {
        ...state,
        fileParentGuid: action.payload.folderGuid,
      };
    }

    case FileActions.FILE_UPDATED:
      return {
        ...state,
        file: action.payload.file,
        folder: action.payload.folder,
        is_free: action.payload.is_free,
        // reset document if there was one already
        document: null,
      };

    case FileActions.ADDRESS_UPDATED:
      return { ...state, document: { ...state.document, address: action.payload } };

    case FileActions.ID_CARD_UPDATED:
      return { ...state, document: { ...state.document, id_card: action.payload } };

    case FileActions.FILE_UPLOAD_START:
      return {
        ...state, isFileUploading: true, is_free: action.payload.is_free, percentUploaded: 0,
      };

    case FileActions.FILE_UPLOAD_PROGRESS:
      return { ...state, percentUploaded: action.payload.percentDone };

    case FileActions.FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        document: action.payload,
        is_free: action.payload.is_free,
        isFileUploading: false,
        canBePatched: true,
      };

    case FileActions.FILE_UPGRADE:
      return {
        ...state,
        upgradingDocument: action.payload,
        isFileUpgrading: true,
      };

    case FileActions.FILE_UPLOAD_ERROR:
      return { ...state, fileError: action.payload, isFileUploading: false };

    case FileActions.SELECT_DOCUMENT_PRICE:
      return { ...state, ...action.payload };

    case FileActions.CLEAR_FILE_STATE:
      return { ...initialState };

    case (DeleteDocumentsActions.CREATE_DELETION_DOCUMENTS_START):
      return {
        ...state,
        documentsBeingDeleting: true,
        documentsToDelete: action.payload,
      };

    case (DeleteDocumentsActions.CREATE_DELETION_DOCUMENTS_SUCCESS):
      return {
        ...state,
        documentsBeingDeleting: false,
      };

    case (DeleteDocumentsActions.CREATE_DELETION_DOCUMENTS_ERROR):
      return {
        ...state,
        documentsBeingDeleting: false,
      };

    case (DeleteDocumentsActions.FINISH_DELETION_DOCUMENTS_START):
      return {
        ...state,
        finishingDocumentsDeleting: true,
      };

    case (DeleteDocumentsActions.FINISH_DELETION_DOCUMENTS_SUCCESS):
      return {
        ...state,
        finishingDocumentsDeleting: false,
        deletedDocuments: action.payload,
        error: null,
      };

    case (FileActions.PATCH_DOCUMENT_ERROR):
      return { ...state, isFileUploading: false, fileError: action.payload };

    case (DeleteDocumentsActions.FINISH_DELETION_DOCUMENTS_ERROR):
      return { ...state, finishingDocumentsDeleting: false, error: action.payload };

    default:
      return state;
  }
}
