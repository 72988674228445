import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  RouterNavigatedAction,
  RouterNavigatedPayload,
  ROUTER_NAVIGATED,
} from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';

import { filter, first, map, switchMap } from 'rxjs/operators';
import { progressRoutes } from '../models/file.models';
import { getAppLayoutSettings } from '../reducers/layout.reducers';
import { getFileState } from '../selectors/file.selectors';
import { ClearFileState } from './../actions/file.action';
import { ChangeLayoutState } from './../actions/layout.actions';
import { AppState } from './../models/app.models';

@Injectable()
export class RouterEffects {

  /**
   * When user uploads a file which is stored in app state and is redirected out
   * of file flow/uploading process, stored file is being removed.
   */

  public readonly cleanFileFromStoreOnFileflowCancel = createEffect(() => this.storeActions.pipe(
    ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
    map(payloadObject => payloadObject.payload),
    filter((payload: RouterNavigatedPayload) => !progressRoutes.includes(payload.routerState.url)),
    switchMap(() => this.store.pipe(
      select(getFileState),
      first(),
    )),
    filter(fileState => !!fileState.file),
    map(() => new ClearFileState()),
  ));

  /**
   * On route change apply specific layout setting for given route.
   * If there is no state specified for given route, default state will be applied.
   */

  public readonly changeAppLayoutStateAccordingToRoute = createEffect(() => this.storeActions.pipe(
    ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
    map(action => {
      const newLayoutState = getAppLayoutSettings(action.payload.routerState.url);
      return new ChangeLayoutState(newLayoutState);
    }),
  ));

  constructor(
    private store: Store<AppState>,
    private storeActions: Actions,
  ) {}

}
