import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VoucherState } from '../reducers/voucher.reducer';

export const voucherState = createFeatureSelector<VoucherState>('voucher');

export const getVoucherDiscount = createSelector(
  voucherState,
  state => state.discount_percentage,
);

export const getIsValidatingVoucher = createSelector(
  voucherState,
  state => state.isValidating,
);

export const getVoucherError = createSelector(
  voucherState,
  state => state.voucherError,
);

export const getHasError = createSelector(
  voucherState,
  state => state.hasVoucherError,
);

export const getSavedVoucher = createSelector(
  voucherState,
  state => state.code,
);
