import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CreditBundleChoiceState } from '../reducers/credit-bundle-choice.reducer';

export const getCreditBundleState =
  createFeatureSelector<CreditBundleChoiceState>('creditBundle');

export const getCreditBundles = createSelector(
  getCreditBundleState,
  state => state.bundles,
);

export const getSelectedBundle = createSelector(
  getCreditBundleState,
  state => state.selectedBundle,
);

export const getIsSelectedBundleAcademy = createSelector(
  getCreditBundleState,
  state => state.isBundleAcademy,
);

export const isLoadingBundles = createSelector(
  getCreditBundleState,
  state => state.isLoadingBundles,
);

export const isCreatingOrder = createSelector(
  getCreditBundleState,
  state => state.isCreatingOrder,
);

export const getPriceRange = createSelector(
  getCreditBundleState,
  state => state.priceRange,
);

export const hasPricingChanged = createSelector(
  getCreditBundleState,
  state => state.hasPricingChanged,
);
