import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { UsersServiceClient } from 'src/app/client/services/users-service.client';
import {
  ResetPasswordConfirm,
  ResetPasswordConfirmActions,
  ResetPasswordConfirmError,
  ResetPasswordConfirmSuccess,
} from '../actions/reset-password-confirm.actions';

@Injectable()
export class ResetPasswordConfirmEffects {

  public resetPasswordConfirm = createEffect(() => this.storeActions.pipe(
    ofType<ResetPasswordConfirm>(ResetPasswordConfirmActions.RESET_PASSWORD_CONFIRM),
    switchMap(action => this.usersServiceClient.forgotPasswordVerify(action.payload)
      .pipe(
        map(() => new ResetPasswordConfirmSuccess()),
        catchError((error: HttpErrorResponse) => of(new ResetPasswordConfirmError(error.error))),
      ),
    ),
  ));


  public resetPasswordConfirmSuccess = createEffect(() => this.storeActions.pipe(
    ofType<ResetPasswordConfirmSuccess>(ResetPasswordConfirmActions.RESET_PASSWORD_CONFIRM_SUCCESS),
    map(() => this.router.navigate(['/', AppRoutes.Login])),
  ), { dispatch: false });

  constructor(
    private router: Router,
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
  ) {
  }
}
