// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';

import { User } from 'src/app/auth/store/models/user';
import { ChangeUserInfoParams } from 'src/app/client/models/users.modules';
import { MytitleError } from 'src/app/client/models/client.models';

export enum ChangeUserInfoActions {
  CHANGE_USER_INFO_LOCALIZATION = '[settings] Change user info localization',
  CHANGE_USER_INFO = '[settings] Change user info',
  CHANGE_USER_INFO_SUCCESS = '[settings] Change user info success',
  CHANGE_USER_INFO_ERROR = '[settings] Change user info error',
}

export class ChangeUserInfoLocalization implements Action {
  public readonly type = ChangeUserInfoActions.CHANGE_USER_INFO_LOCALIZATION;
  constructor(public payload: User) {}
}

export class ChangeUserInfo implements Action {
  public readonly type = ChangeUserInfoActions.CHANGE_USER_INFO;
  constructor(public payload: ChangeUserInfoParams) {}
}

export class ChangeUserInfoSuccess implements Action {
  public readonly type = ChangeUserInfoActions.CHANGE_USER_INFO_SUCCESS;
  constructor(public payload: User) {}
}

export class ChangeUserInfoError implements Action {
  public readonly type = ChangeUserInfoActions.CHANGE_USER_INFO_ERROR;
  constructor(public payload: MytitleError[]) { }
}

export type ChangeUserInfoAction =
  ChangeUserInfo |
  ChangeUserInfoSuccess |
  ChangeUserInfoError;
