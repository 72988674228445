import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ChangeUserInfoParams,
  ForgotPasswordRequestParams,
  ForgotPasswordVerifyParams,
  GeoIP,
  GetUserDeleteInfoResponse,
  GoogleLoginParams,
  GoogleRegistrationParams,
  LoginParams,
  NewRegistrationParams,
  OAuthValidResponse,
  RefreshTokenRequest,
  ResendVerificationCodeParams,
  ScorePasswordParams,
  SubmitUserRemovalParams,
  TwoStepAuthenticationParams,
  TwoStepAuthenticationResponse,
  UserInfo,
  UsersAffiliateEarnings,
  VerifyEmailParams,
  VerifyUserRemovalParams,
  VerifyUserRemovalResponse,
} from '../models/users.modules';

@Injectable()
export class UsersServiceClient {

  private serviceUrl = '/api/v1/users';

  constructor(private http: HttpClient) {}

  /**
   * NewRegistration
   * Creates new user account with user name and email. Sends a verification email.
   * Server will always return 204 if the email is in correct form as a protection
   * against user enumeration.
   *
   * Can be used to resend the email verification email (before the email address is
   * verified). Email verification message contains a code that the user needs
   * to enter to verify email.
   */
  public register(params: NewRegistrationParams): Observable<void> {
    return this.http.post<void>(`${this.serviceUrl}/register/new`, params);
  }

  public googleRegister(params: GoogleRegistrationParams): Observable<void> {
    return this.http.post<void>(`${this.serviceUrl}/register/google`, params);
  }

  /**
   * VerifyEmail
   * Verifies email and setups news password. Both are required.
   */
  public verifyEmail(params: VerifyEmailParams): Observable<void> {
    return this.http.post<void>(`${this.serviceUrl}/register/email_verify`, params);
  }

  /**
   * Resend verification code on email. Requires password and email.
   * Can be done only right after registration or when user logs in an not finished registration
   * code is returned, that means that entered email and password is ok.
   */
  public resendVerificationCode(params: ResendVerificationCodeParams): Observable<void> {
    return this.http.post<void>(`${this.serviceUrl}/register/resend_email_verify_code`, params);
  }

  /**
   * UserLogin
   * Logs in the user using provided credentials.
   *  https://www.oauth.com/oauth2-servers/access-tokens/access-token-response/
   */
  public login(params: LoginParams): Observable<OAuthValidResponse> {
    return this.http.post<OAuthValidResponse>(`${this.serviceUrl}/login`, params);
  }

  public googleLogin(params: GoogleLoginParams): Observable<OAuthValidResponse> {
    return this.http.post<OAuthValidResponse>(`${this.serviceUrl}/login-google`, params);
  }

  /**
   * Retrieve user info
   */
  public getUserInfo(): Observable<UserInfo> {
    return this.http.get<UserInfo>(`${this.serviceUrl}/user_info`);
  }

  /**
   * Changes user info
   */
  public changeUserInfo(params: ChangeUserInfoParams): Observable<UserInfo> {
    return this.http.patch<UserInfo>(`${this.serviceUrl}/user_info`, params);
  }

  /**
   * Get affiliate program info
   */
  public getAffiliateUserInfo(): Observable<UsersAffiliateEarnings> {
    return this.http.get<UsersAffiliateEarnings>(`${this.serviceUrl}/user-info/affiliate`);
  }

  /**
   * Refresh token
   */
  public refreshToken(params: RefreshTokenRequest): Observable<OAuthValidResponse> {
    return this.http.post<OAuthValidResponse>(`${this.serviceUrl}/refresh_token`, params);
  }

  /**
   * Forgot password (request)
   * Requests a forgot password email with link to reset it.
   */
  public forgotPasswordRequest(params: ForgotPasswordRequestParams): Observable<void> {
    return this.http.post<void>(`${this.serviceUrl}/password/forgot_request`, params);
  }

  /**
   * Forgot password (verify)
   * Verifies and sets new password.
   */
  public forgotPasswordVerify(params: ForgotPasswordVerifyParams): Observable<void> {
    return this.http.post<void>(`${this.serviceUrl}/password/forgot_verify`, params);
  }

  /**
   * Score password
   * Checks password security.
   * Responds `204` if password security is sufficient.
   * Responds `400` with error code if not.
   */
  public scorePassword(params: ScorePasswordParams): Observable<void> {
    return this.http.post<void>(`${this.serviceUrl}/password/score`, params);
  }

  /**
   * Get Geo IP address
   */
  public getGeoIP(): Observable<GeoIP> {
    return this.http.get<GeoIP>(`${this.serviceUrl}/geoip`);
  }

  /**
   * Enable 2-Step Authentication
   */
  public enableTwoStepAuthentication(params?: TwoStepAuthenticationParams):
    Observable<TwoStepAuthenticationResponse> {
      return this.http.post<TwoStepAuthenticationResponse>(`${this.serviceUrl}/2fa`, params);
  }

  /**
   * Disable 2-Step Authentication
   */
  public disableTwoStepAuthentication() {
    return this.http.delete(`${this.serviceUrl}/2fa`);
  }

  /**
   * Tells if user has 2FA or password necessary for deletion
   */
  public getUserDeleteInfo(): Observable<GetUserDeleteInfoResponse> {
    return this.http.get<GetUserDeleteInfoResponse>(`${this.serviceUrl}/user_deletion/credentials`);
  }

  /**
   * Submit user info credentials for account removal
   */
  public submitUserRemoval(params: SubmitUserRemovalParams): Observable<void> {
    return this.http.post<void>(`${this.serviceUrl}/user_deletion/new`, params);
  }

  /**
   * Verify users with no 2FA
   */
  public verifyUserRemoval(params: VerifyUserRemovalParams): Observable<VerifyUserRemovalResponse> {
    return this.http.post<VerifyUserRemovalResponse>
      (`${this.serviceUrl}/user_deletion/verify`, params);
  }
}
