import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import { VerifyEmailAction, VerifyEmailActions } from '../actions/verifyEmail.action';

export interface VerifyEmailState {
  verifying: boolean;
  error: MytitleError;
}

export const initialVerifyEmailState: VerifyEmailState = {
  verifying: false,
  error: null,
};

export function verifyEmailReducer(
  state = initialVerifyEmailState,
  generic: Action,
) {
  const action = generic as VerifyEmailAction;
  switch (action.type) {
    case VerifyEmailActions.VERIFY_EMAIL: {
      return {
        ...state,
        verifying: true,
        error: null,
      };
    }
    case VerifyEmailActions.VERIFY_EMAIL_SUCCESS: {
      return {
        ...state,
        verifying: false,
      };
    }
    case VerifyEmailActions.VERIFY_EMAIL_ERROR: {
      return {
        ...state,
        error: action.payload,
        verifying: false,
      };
    }
  }
  return state;
}
