import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Logout } from 'src/app/auth/store/actions/logout.action';
import { getEmail, getFullName } from 'src/app/auth/store/selectors/user.selector';
import { Notifications } from 'src/app/shared/models/notifications';
import { SnackbarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'mt-user-indicator',
  templateUrl: './user-indicator.component.html',
  styleUrls: ['./user-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserIndicatorComponent {

  public email: Observable<string>;
  public name: Observable<string>;
  public initial: Observable<string>;

  constructor(
    private store: Store<any>,
    private snackbarService: SnackbarService,
  ) {
    this.email = this.store.pipe(select(getEmail));
    this.name = this.store.pipe(select(getFullName));
    this.initial = combineLatest([
      this.name,
      this.email,
    ]).pipe(
      map(([name, email]) => name || email),
      filter(name => !!name),
      map(name => this.createInitial(name)),
    );
  }

  public logOut() {
    this.snackbarService.queueSnackBar(
      Notifications.LoggedOut, { duration: 3000 });
    this.store.dispatch(new Logout());
  }

  private createInitial(name: string): string {
    const parts = name.split(' ');
    let out = parts[0][0];
    if (parts.length > 1) {
      out += parts[parts.length - 1][0];
    }
    return out;
  }
}
