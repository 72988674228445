import { Action, createFeatureSelector } from '@ngrx/store';
import { GoogleAuthAction, GoogleAuthActions } from 'src/app/auth/store/actions/google-auth.action';
import { LoginAction, LoginActions } from 'src/app/auth/store/actions/login.action';
import { MytitleError } from 'src/app/client/models/client.models';
import { LoginParams } from 'src/app/client/models/users.modules';

interface LoginState {
  loginParams: LoginParams;
  loggingIn: boolean;
  error: MytitleError[];
}

export const initialLoginState: LoginState = {
  loginParams: null,
  loggingIn: false,
  error: null,
};

export function loginReducer(
  state = initialLoginState,
  generic: Action,
) {
  const action = generic as LoginAction | GoogleAuthAction;

  switch (action.type) {
    case LoginActions.LOGIN:
      return { ...state, loggingIn: true, error: null, loginParams: action.payload };
    case LoginActions.LOGIN_ERROR:
      return { ...state, loggingIn: false, error: action.payload };
    case LoginActions.LOGIN_SUCCESS:
    case GoogleAuthActions.GOOGLE_LOGIN_SUCCESS:
      return { ...state, loggingIn: false, error: null, loginParams: null };
    case LoginActions.LOGIN_TWO_FA:
      return {
        ...state,
        loggingIn: true,
        loginParams: { ...state.loginParams, code: action.payload },
      };
    case LoginActions.CLEAR_LOGIN_ERROR:
      return {
        ...state,
        error: null,
      };
    case GoogleAuthActions.GOOGLE_LOGIN_ERROR: {
      return {
        ...state,
        loggingIn: false,
      };
    }
  }

  return state;
}

export const loginStoreName = 'login';

export const getLoginState = createFeatureSelector<LoginState>(loginStoreName);
