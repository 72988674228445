<div class="section-logo">
  <img
    src="/assets/certificate/video-icon.svg"
    alt="watch video about us"
    (click)="openVideoDialog()"
    class="pulsate">
</div>

<div class="certificate" id="video">
  <div class="certificate__title">
    <h2 class="section-title" i18n="@@certificateClaim">
      Get an ownership certificate of your intellectual property
    </h2>
  </div>
<!-- DESKTOP -->
  <div class="certificate__wrapper">
    <img src="/assets/certificate/certificate.png" alt="certificate" class="certificate__desktop">
  </div>
  <!-- MOBILE -->
  <div class="certificate__mobile">
    <img src="/assets/certificate/mobile-certificate.png" alt="certificate" class="certificate__mobile-image">
    <img src="/assets/certificate/certificate-circle.svg"
         alt="certificate"
         class="certificate__circle certificate__circle--first infinite-pulsate"
         (click)="selectContainer(0)">
    <img src="/assets/certificate/certificate-circle.svg"
         alt="certificate"
         class="certificate__circle certificate__circle--second infinite-pulsate"
         (click)="selectContainer(1)">
    <img src="/assets/certificate/certificate-circle.svg"
         alt="certificate"
         class="certificate__circle certificate__circle--third infinite-pulsate"
         (click)="selectContainer(2)">
    <img src="/assets/certificate/certificate-circle.svg"
         alt="certificate"
         class="certificate__circle certificate__circle--fourth infinite-pulsate"
         (click)="selectContainer(3)">


    <div *ngIf="isContainerSelected(0)"
         class="certificate__card">
      <img src="/assets/certificate/certificate-card.svg"
           alt=""
           class="certificate__info certificate__info--first">
      <div class="certificate__text certificate__text--first">
        <p><strong i18n="@@certificateIconOne">The author, time and location</strong></p>
        <p i18n="@@certificateIconOneText">Who owns it.</p>
      </div>
    </div>
    <div *ngIf="isContainerSelected(1)"
         class="certificate__card">
      <img src="/assets/certificate/certificate-card.svg"
           alt=""
           class="certificate__info certificate__info--second">
      <div class="certificate__text certificate__text--second">
        <p><strong i18n="@@certificateIconTwo">Explanation</strong></p>
        <p i18n="@@certificateIconTwoText">How the stamp was created.</p>
      </div>
    </div>
    <div *ngIf="isContainerSelected(2)"
         class="certificate__card">
      <img src="/assets/certificate/certificate-card.svg"
           alt=""
           class="certificate__info certificate__info--third">
      <div class="certificate__text certificate__text--third">
        <p><strong i18n="@@certificateIconThree">Verification link</strong></p>
        <p i18n="@@certificateIconThreeText">Anyone can check the file authorship.</p>
      </div>
    </div>
    <div *ngIf="isContainerSelected(3)"
         class="certificate__card">
      <img src="/assets/certificate/certificate-card.svg"
           alt=""
           class="certificate__info certificate__info--fourth">
      <div class="certificate__text certificate__text--fourth">
        <p><strong i18n="@@certificateIconFour">Digital fingerprint</strong></p>
        <p i18n="@@certificateIconFourText">Globally unique identifier.</p>
      </div>
    </div>
  </div>
</div>
