import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { importSsrStateMetaReducer,
} from '../../shared/universal/transfer-state/browser-import-state/import.reducers';
import { validateStateReducer } from '../../shared/universal/validate-state/validate-state.reducer';
import { AppState } from './models/app.models';
import { creditBundleChoiceReducer } from './reducers/credit-bundle-choice.reducer';
import * as file from './reducers/file.reducers';
import { layoutReducer } from './reducers/layout.reducers';
import { fileOrderReducer } from './reducers/order-file.reducers';
import { paymentReducer } from './reducers/payment.reducers';
import { uploadFolderStateReducer } from './reducers/upload-folder.reducer';
import { voucherReducer } from './reducers/voucher.reducer';

export const appReducers: ActionReducerMap<AppState> = {
  layout: layoutReducer,
  file: file.fileReducer,
  router: routerReducer,
  creditBundle: creditBundleChoiceReducer,
  fileOrder: fileOrderReducer,
  payment: paymentReducer,
  uploadFolder: uploadFolderStateReducer,
  voucher: voucherReducer,
};

// eslint-disable-next-line @typescript-eslint/array-type
export const metaReducers: MetaReducer<AppState>[] = [
  // actionLoggerReducer,
  importSsrStateMetaReducer,
  validateStateReducer,
];
