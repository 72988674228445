import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { getRouterState } from 'src/app/app/states/selectors/router.selectors';
import { getFileState } from '../states/selectors/file.selectors';

@Injectable({
  providedIn: 'root',
})
export class CreditFileGuardService implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<any>,
  ) {
  }

  public canActivate(): Observable<boolean | UrlTree> {
    return forkJoin([
      this.store.select(getFileState).pipe(first()),
      this.store.select(getRouterState).pipe(first()),
    ])
      .pipe(
        map(([fileState, routerState]) => [!!fileState.file, !!routerState.queryParams.fileFlow]),
        take(1),
        map(([isFilePresent, isInFileFlow]) => {
          if (isInFileFlow && !isFilePresent) {
            return this.router.createUrlTree(['/']);
          } else {
            return true;
          }
        }),
      );
  }
}
