import { LoginParams } from 'src/app/client/models/users.modules';
import { RegisterState } from './reducers/register.reducer';

export function getAuthParams(
  registerState: RegisterState,
  loginParams: LoginParams,
  originEffect: string): { email: string; password: string } {
    if (registerState.email && registerState.password) {
      return { email: registerState.email, password: registerState.password };
    } else if (loginParams.email && loginParams.password) {
      return { email: loginParams.email, password: loginParams.password };
    } else {
      throw new Error(`[${originEffect}] Registration params are missing in
       store registerState and loginParam is null.`);
    }
}
