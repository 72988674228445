import { Action } from '@ngrx/store';
import { AppState } from '../../../../app/states/models/app.models';

export enum ImportActions {
  IMPORT_SSR_STATE = 'Import SSR State',
}

/** SSR rendered state replaces the init state */
export class ImportSsrStateAction implements Action {
  public readonly type = ImportActions.IMPORT_SSR_STATE;

  constructor(public payload: AppState) {
  }
}

export type ImportAction
  = ImportSsrStateAction;
