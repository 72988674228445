import { Action } from '@ngrx/store';

import { FolderPath, FolderWithChildren } from 'src/app/client/models/mytitle-service.models';
import { Folder } from 'src/app/client/models/mytitle-service.models';
import { SharedDocumentAction, SharedDocumentActions } from '../actions/shared-document.actions';
import { SharedFolderAction, SharedFolderActions } from '../actions/shared-folder.actions';

export interface SharedFoldersState {
  treeFolders: FolderWithChildren[];
  flatFolders: Folder[];
  flatFolderPath: FolderPath[];
  flatFolderLoading: boolean;
  treeFolderLoading: boolean;
}

export const sharedFoldersInitialState: SharedFoldersState = {
  treeFolders: [],
  flatFolders: [],
  flatFolderPath: [],
  flatFolderLoading: false,
  treeFolderLoading: false,
};

export function sharedFoldersReducer(
  state = sharedFoldersInitialState,
  generic: Action,
): SharedFoldersState {

  const action = generic as SharedFolderAction | SharedDocumentAction;
  switch (action.type) {

    case SharedFolderActions.GET_FLAT_FOLDER_LIST_START: {
      return {
        ...state,
        flatFolderLoading: true,
      };
    }

    case SharedFolderActions.GET_TREE_FOLDER_LIST_START: {
      return {
        ...state,
        treeFolderLoading: true,
      };
    }

    case SharedFolderActions.GET_FLAT_FOLDER_LIST_SUCCESS: {
      return {
        ...state,
        flatFolderLoading: false,
        flatFolders: action.payload.folders,
        flatFolderPath: action.payload.folder_path,
      };
    }

    case SharedFolderActions.GET_TREE_FOLDER_LIST_SUCCESS: {
      return {
        ...state,
        treeFolderLoading: false,
        treeFolders: action.payload,
      };
    }

    case SharedDocumentActions.CLEAN_SHARED_DOCUMENTS: {
      return sharedFoldersInitialState;
    }

    default: return state;
  }
}
