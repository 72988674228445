import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FileUpdated, SelectDocumentPrice } from 'src/app/app/states/actions/file.action';
import { AppState } from 'src/app/app/states/models/app.models';
import { LimitExceeded } from 'src/app/auth/store/models/user';
import { getIsAuthorized } from 'src/app/auth/store/selectors/user.selector';
import { Notifications } from 'src/app/shared/models/notifications';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'mt-quoted-header',
  templateUrl: './quoted-header.component.html',
  styleUrls: ['./quoted-header.component.scss'],
})
export class QuotedHeaderComponent {
  public isLoggedIn: Observable<boolean>;
  public priceRange: Observable<string[]>;
  public isMobile: MediaQueryList;
  public limitExceededState: LimitExceeded;

  constructor(
    public store: Store<AppState>,
    public snackbarService: SnackbarService,
  ) {
    this.isLoggedIn = this.store.pipe(select(getIsAuthorized));
  }

  public fileAdded(file: File, isFree: boolean) {
    if (this.limitExceededState && this.limitExceededState.isLimitExceeded) {
      if (!this.limitExceededState.wasWarningShown) {
        this.snackbarService.queueSnackBar(
          Notifications.QuotaExceeded, { triggerAction: 'FREE_LIMIT_WARNING_SHOWN' });
      } else {
        this.snackbarService.queueSnackBar(Notifications.QuotaExceeded);
      }
    } else {
      this.store.dispatch(new FileUpdated({
        file,
        folder: null,
        is_free: isFree,
        uploadNow: true,
      },
      ));
    this.store.dispatch(new SelectDocumentPrice({ is_free: isFree }));
    }
  }
}
