import { createSelector } from '@ngrx/store';
import { getAuthState } from '../auth.reducers';

export const getGoogleAuthState = createSelector(
  getAuthState,
  state => state.google,
)
;
export const isGoogleAuthenticating = createSelector(
  getGoogleAuthState,
  state => state.isAuthenticating,
);

export const getGoogleIdToken = createSelector(
  getGoogleAuthState,
  state => state.idToken,
);

export const getGoogleCampaignCode = createSelector(
  getGoogleAuthState,
  state => state.campaignCode,
);

export const getGoogleAffiliateCode = createSelector(
  getGoogleAuthState,
  state => state.affiliateCode,
);
