export interface DataLayerEvent {
  event: string;
}

export class NavigationEvent implements DataLayerEvent {
  public event = 'angular.navigate';
  public navigatedPage: string;
  constructor(navigatedPage: string) {
    this.navigatedPage = navigatedPage;
  }
}
