<header class="header-wrapper">
  <div class="container">
    <div class="row no-gutters">
      <div class="col d-flex justify-content-between no-gutters">
        <a class="col-auto mr-auto logo-link" routerLink="">
          <svg class="mt-main-logo">
            <use xlink:href="/assets/img/sprite.svg#logo"></use>
          </svg>
        </a>
        <input type="checkbox" class="navigation__checkbox" id="navi-toggle" [(ngModel)]="expanded">

        <label for="navi-toggle" class="navigation__button">
          <span class="navigation__icon"></span>
        </label>

        <div class="navigation__background">&nbsp;</div>

        <nav class="navigation__nav">
          <ul class="navigation__list">

            <ng-container *ngIf="isLoggedIn | async">
              <li class="navigation__item">
                <span class="navigation__credit-text" i18n="@@youHave">You have</span>
                <span class="navigation__credit-count"
                      (click)="toggleNavbar()"
                      [routerLink]="['/', appRoutes.CreditBundleChoice]">
                      {{ creditBalance | async }} <span i18n="@@credits">Credits</span></span>
              </li>
              <li class="navigation__item">
                <span class="navigation__divider"></span>
              </li>
              <li class="navigation__item" (click)="toggleNavbar()">
                <a [href]="getBlog()"
                   rel="noreferrer"
                   class="navigation__link"><h3>Blog</h3></a>
              </li>
              <li class="navigation__item" (click)="toggleNavbar()">
                <a routerLink="/" fragment="faq" class="navigation__link"><h3 i18n="@@faq">FAQ</h3></a>
              </li>
              <li class="navigation__item">
                <a class="navigation__link"
                      (click)="toggleNavbar()"
                      [routerLink]="['/', appRoutes.CreditBundleChoice]">
                  <h3 i18n="@@buyCredits">Buy credits</h3></a>
              </li>
              <li class="navigation__item" (click)="toggleNavbar()">
                <a [routerLink]="['/', appRoutes.MyDocuments]" class="navigation__link"><h3 i18n="@@myDocuments">My documents</h3></a>
              </li>
              <li class="navigation__item" (click)="toggleNavbar()">
                <a [routerLink]="['/', appRoutes.Settings]" class="navigation__link"><h3 i18n="@@settings">Settings</h3></a></li>
              <li class="navigation__item">
                <span class="navigation__divider"></span>
              </li>
              <li class="navigation__item navigation__item--last">
                <a (click)="logout()" class="navigation__link"><h3 i18n="@@logOut">Logout</h3></a>
              </li>
            </ng-container>

            <ng-container *ngIf="(isLoggedIn | async) === false">
              <li class="navigation__item" (click)="toggleNavbar()">
                <a [routerLink]="['/', appRoutes.SignUp]" class="navigation__link"><h3 i18n="@@signUp">Sign Up</h3></a>
              </li>
              <li class="navigation__item" (click)="toggleNavbar()">
                <a [routerLink]="['/', appRoutes.Login]" class="navigation__link"><h3 i18n="@@logIn">Log In</h3></a>
              </li>
              <li class="navigation__item">
                <span class="navigation__divider"></span>
              </li>
              <li class="navigation__item" (click)="toggleNavbar()">
                <a routerLink="/" fragment="pricing" class="navigation__link"><h3 i18n="@@pricing">Pricing</h3></a>
              </li>
              <li class="navigation__item" (click)="toggleNavbar()">
                <a routerLink="/" fragment="video" class="navigation__link"><h3 i18n="@@howDoesItWork">How Does It Work?</h3></a>
              </li>
              <li class="navigation__item" (click)="toggleNavbar()">
                <a [href]="getBusiness()"
                  target="_blank"
                  rel="noreferrer"
                  class="navigation__link"><h3 i18n="@@forBusiness">Mytitle for Business</h3></a>
              </li>
              <li class="navigation__item" (click)="toggleNavbar()">
                <a [href]="getBlog()"
                  rel="noreferrer"
                  class="navigation__link"><h3>Blog</h3></a>
              </li>
              <li class="navigation__item navigation__item--last" (click)="toggleNavbar()">
              </li>
            </ng-container>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>
