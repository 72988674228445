import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { AppRoutes } from 'src/app/app/app.routes.misc';
import { AppState } from 'src/app/app/states/models/app.models';
import { getRouterState } from 'src/app/app/states/selectors/router.selectors';
import { GoogleAuthErrorCodes } from 'src/app/auth/auth.models';
import { CheckFreeLimit } from 'src/app/auth/store/actions/file-limit.action';
import { GetCreditBalance } from 'src/app/auth/store/actions/getCreditBalance.actions';
import { GetUserInfo } from 'src/app/auth/store/actions/getUserInfo.action';
import {
  GoogleAuthActions,
  GoogleAuthCleanup,
  GoogleAuthError,
  GoogleAuthSuccess,
  GoogleLoginError,
  GoogleLoginStart,
  GoogleLoginSuccess,
  GoogleRegisterError,
  GoogleRegisterStart,
  GoogleRegisterSuccess,
  GoogleSignUp,
  GoogleSignUpCancel,
} from 'src/app/auth/store/actions/google-auth.action';
import { LoginErrorsEnum } from 'src/app/auth/store/models/user';
import { getGoogleIdToken } from 'src/app/auth/store/selectors/google-auth.selector';
import { GoogleLoginParams } from 'src/app/client/models/users.modules';
import { UsersServiceClient } from 'src/app/client/services/users-service.client';
import { LoginRoutes } from 'src/app/pages/login/login.routes.misc';
import { getLoginParams } from 'src/app/pages/login/store/login.selector';
import { SignUpRoutes } from 'src/app/pages/sign-up/sign-up.routes.misc';
import { Notifications } from 'src/app/shared/models/notifications';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Injectable()
export class GoogleAuthEffects {


  public readonly handleGoogleAuthSuccess = createEffect(() => this.storeActions.pipe(
    ofType<GoogleAuthSuccess>(GoogleAuthActions.GOOGLE_AUTH_SUCCESS),
    map(() => new GoogleLoginStart()),
  ));


  public readonly handleGoogleAuthError = createEffect(() => this.storeActions.pipe(
    ofType<GoogleAuthError>(GoogleAuthActions.GOOGLE_AUTH_ERROR),
    tap(action => {
      this.snackbarService.queueSnackBar(
        this.getErrorMessage(action.payload.error),
        { duration: 10000 },
      );
    }),
  ), { dispatch: false });


  public readonly redirectUserToGoogleSignUpPage = createEffect(() => this.storeActions.pipe(
    ofType<GoogleSignUp>(GoogleAuthActions.GOOGLE_SIGNUP),
    switchMap(() => this.store.pipe(
      select(getRouterState),
      first())),
    tap(router => {
      if (router.queryParams.fileFlow) {
        this.router.navigate(
          [AppRoutes.SignUp, SignUpRoutes.Country],
          { queryParams: { fileFlow: true } },
        );
      } else if (router.queryParams.creditFlow) {
        this.router.navigate(
          [AppRoutes.SignUp, SignUpRoutes.Country],
          { queryParams: { creditFlow: true } },
        );
      } else this.router.navigate([AppRoutes.SignUp, SignUpRoutes.Country]);
    }),
  ), { dispatch: false });


  public readonly registerUserWithGoogleAccount = createEffect(() => this.storeActions.pipe(
    ofType<GoogleRegisterStart>(GoogleAuthActions.GOOGLE_REGISTER_START),
    switchMap(action => this.store.pipe(
      select(getGoogleIdToken),
      first(),
      map(idToken => [
        idToken,
        action.payload.country,
        action.payload.campaign_code,
        action.payload.affiliate_code,
      ]),
    )),
    switchMap(([id_token, country, campaign_code, affiliate_code]) => this.usersServiceClient.googleRegister(
        { id_token, country, campaign_code, affiliate_code }).pipe(
        map(() => new GoogleRegisterSuccess()),
        catchError((error: HttpErrorResponse) => of(new GoogleRegisterError(error.error))),
      )),
  ));


  public readonly handleUserLogin = createEffect(() => this.storeActions.pipe(
    ofType<GoogleLoginStart>(GoogleAuthActions.GOOGLE_LOGIN_START),
    switchMap(() => forkJoin([
        this.store.pipe(select(getGoogleIdToken), first()),
        this.store.pipe(select(getLoginParams), first()),
      ])),
    switchMap(([id_token, loginParams]) => {
      let params: GoogleLoginParams = { id_token };
      if (loginParams && loginParams.code) params = { ...params, code: loginParams.code };

      return this.usersServiceClient
        .googleLogin(params)
        .pipe(
          map(response => new GoogleLoginSuccess({
            token: {
              accessToken: response.access_token,
              expires: new Date(Date.now() + response.expires_in * 1000),
              refreshToken: response.refresh_token,
            },
          })),
          catchError((error: HttpErrorResponse) => of(new GoogleLoginError(error.error))),
        );
    }),
  ));


  public readonly storeTokenAndAuthUserOnLoginSuccess = createEffect(() => this.storeActions.pipe(
    ofType<GoogleLoginSuccess>(GoogleAuthActions.GOOGLE_LOGIN_SUCCESS),
    mergeMap(() => [
      new GetUserInfo(),
      new GetCreditBalance(),
      new CheckFreeLimit(),
    ]),
  ));


  public readonly handleGoogleLoginError = createEffect(() => this.storeActions.pipe(
    ofType<GoogleLoginError>(GoogleAuthActions.GOOGLE_LOGIN_ERROR),
    map(action => action.payload[0]),
    switchMap(error => forkJoin([
        of(error),
        this.store.pipe(
          select(getRouterState),
          first(),
        ),
      ])),
    filter(([error, routerState]) => {
      if (error.code === LoginErrorsEnum.TWO_FA_CODE_MISSING) {
        if (routerState.queryParams.fileFlow) {
          this.router.navigate(
            [AppRoutes.Login, LoginRoutes.TwoFA],
            { queryParams: { fileFlow: true } },
          );
        } else if (routerState.queryParams.creditFlow) {
          this.router.navigate(
            [AppRoutes.Login, LoginRoutes.TwoFA],
            { queryParams: { creditFlow: true } },
          );
        } else this.router.navigate([AppRoutes.Login, LoginRoutes.TwoFA]);
        return false;
      } else if (error.code === LoginErrorsEnum.TWO_FA_CODE_INCORRECT) {
        this.snackbarService.queueSnackBar(
          Notifications.GoogleCodeIncorrect,
          { duration: 10000 },
        );
        return false;
      } else return true;
    }),
    map(([error, _]) => {
      if (error.code === LoginErrorsEnum.NO_ACTIVE_ACCOUNT) {
        return new GoogleSignUp();
      } else {
        this.snackbarService.queueSnackBar(
          error.message,
          { duration: 10000 },
        );
        return new GoogleAuthCleanup();
      }
    }),
  ));


  public readonly cancelRegistration = createEffect(() => this.storeActions.pipe(
    ofType<GoogleSignUpCancel>(GoogleAuthActions.GOOGLE_SIGNUP_CANCEL),
    switchMap(() => this.store.pipe(
      select(getRouterState),
      first(),
    )),
    map(routerState => {
      if (routerState.queryParams.fileFlow) {
        this.router.navigate(
          [AppRoutes.Login],
          { queryParams: { fileFlow: true } },
        );
      } else if (routerState.queryParams.creditFlow) {
        this.router.navigate(
          [AppRoutes.Login],
          { queryParams: { creditFlow: true } },
        );
      } else this.router.navigate([AppRoutes.Login]);
      return new GoogleAuthCleanup();
    }),
  ));

  constructor(
    private store: Store<AppState>,
    private storeActions: Actions,
    private router: Router,
    private usersServiceClient: UsersServiceClient,
    private snackbarService: SnackbarService,
    ) {}

  private getErrorMessage(error: GoogleAuthErrorCodes): string {
    switch (error) {
      case GoogleAuthErrorCodes.CLOSED: {
        return Notifications.GoogleWindowClosed;
      }

      case GoogleAuthErrorCodes.DENIED: {
        return Notifications.GoogleAccessDenied;
      }

      case GoogleAuthErrorCodes.FAILED:
      default: {
        return Notifications.GoogleError;
      }
    }
  }

}
