import * as uuid from 'uuid';

import {
  FileExt,
  FolderExt,
  ParsedFolderStructure,
} from 'src/app/app/states/reducers/upload-folder.reducer';

/**
 * Return complete folder hierarchy
 */
export function parseFilesIntoFolderStructure(files: FileExt[]): ParsedFolderStructure {
  // Parse file relative string paths and create unique folder structure
  const paths = files.map(x => x.webkitRelativePath.replace(x.name, ''));
  const folderStructureToReturn = {
    files,
    ...getUniqueFolderStructure(paths),
  };

  return folderStructureToReturn;
  }

/**
 * Return unique folder structure of all uploaded files/folders inside of a folder
 */
function getUniqueFolderStructure(paths: string[]):
  { folders: FolderExt[]; foldersInTotal: number } {

  const foldersToReturn: FolderExt[] = [];
  let foldersInTotal = 0;

  paths.forEach(path => {
    const parsedPath = path.split('/');
    parsedPath.pop(); // remove last element (file name itself)

    let folders = foldersToReturn;
    parsedPath.forEach(pathSegment => {
      const index = folders.findIndex(x => x.folderName === pathSegment);
      // create new folder and step inside of it
      if (index === -1) {
        foldersInTotal++;
        const newLength = folders.push({
          storeGuid: uuid.v4(),
          folderGuid: null,
          parentFolderGuid: null,
          folderName: pathSegment,
          childFolders: [],
        });
        folders = folders[newLength - 1].childFolders;
      // otherwise step directly into it
      } else folders = folders[index].childFolders;
    });
  });

  return { folders: foldersToReturn, foldersInTotal };
}
