<ng-container>
  <mt-quoted-header></mt-quoted-header>
  <mt-certificate></mt-certificate>
  <mt-how-it-works mtScrollSection="how" id="how"></mt-how-it-works>
  <mt-testimonials></mt-testimonials>
  <mt-map></mt-map>
  <mt-pricing mtScrollSection="pricing" id="pricing"></mt-pricing>
  <mt-payment-options class="d-none d-lg-block"></mt-payment-options>
  <mt-blog-section></mt-blog-section>
  <mt-faq mtScrollSection="faq"></mt-faq>
</ng-container>


