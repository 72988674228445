import { Action } from '@ngrx/store';
import { GoogleAuthErrorCodes } from 'src/app/auth/auth.models';
import { GoogleAuthAction, GoogleAuthActions } from 'src/app/auth/store/actions/google-auth.action';
import { GoogleRegistrationStartParams } from 'src/app/client/models/users.modules';

export interface GoogleAuthState {
  idToken: string;
  googleRegistrationParams: GoogleRegistrationStartParams;
  campaignCode: string;
  affiliateCode: string;
  errorCode: GoogleAuthErrorCodes;
  isAuthenticating: boolean;
}

export const initialGoogleAuthState: GoogleAuthState = {
  idToken: null,
  googleRegistrationParams: null,
  affiliateCode: null,
  campaignCode: null,
  errorCode: null,
  isAuthenticating: null,
};

export function googleAuthReducer(
  state = initialGoogleAuthState,
  generic: Action,
): GoogleAuthState {

  const action = generic as GoogleAuthAction;
  switch (action.type) {

    case GoogleAuthActions.GOOGLE_AUTH_START: {
      return {
        ...state,
        isAuthenticating: true,
      };
    }

    case GoogleAuthActions.GOOGLE_AUTH_ERROR: {
      return {
        ...state,
        isAuthenticating: false,
        errorCode: action.payload.error,
      };
    }

    case GoogleAuthActions.GOOGLE_AUTH_SUCCESS: {
      return {
        ...state,
        idToken: action.payload.idToken,
      };
    }

    case GoogleAuthActions.GOOGLE_SIGNUP: {
      return {
        ...state,
        isAuthenticating: false,
      };
    }

    case GoogleAuthActions.GOOGLE_REGISTER_START: {
      return {
        ...state,
        googleRegistrationParams: action.payload,
        isAuthenticating: true,
      };
    }

    case GoogleAuthActions.GOOGLE_REGISTER_ERROR: {
      return {
        ...state,
        isAuthenticating: false,
      };
    }

    case GoogleAuthActions.GOOGLE_LOGIN_START: {
      return {
        ...state,
        isAuthenticating: true,
      };
    }

    case GoogleAuthActions.GOOGLE_LOGIN_ERROR: {
      return {
        ...state,
        isAuthenticating: false,
      };
    }

    case GoogleAuthActions.SAVE_CAMPAIGN_CODE: {
      return {
        ...state,
        campaignCode: action.payload,
        isAuthenticating: false,
      };
    }

    case GoogleAuthActions.SAVE_AFFILIATE_CODE: {
      return {
        ...state,
        affiliateCode: action.payload,
        isAuthenticating: false,
      };
    }

    case GoogleAuthActions.GOOGLE_AUTH_CLEANUP:
    case GoogleAuthActions.GOOGLE_LOGIN_SUCCESS: {
      return initialGoogleAuthState;
    }
  }

  return state;
}
