import { Action } from '@ngrx/store';

import { MytitleError } from 'src/app/client/models/client.models';
import { SharingObject } from 'src/app/client/models/mytitle-service.models';
import {
  SharingAction,
  SharingActions,
} from '../actions/sharing.actions';

export interface SharingsState {
  documentGuid: string;
  folderGuid: string;
  allSharings: SharingObject[];
  sharingError: MytitleError[];
  isLoading: boolean;
}

export const sharingsInitialState: SharingsState = {
  documentGuid: null,
  folderGuid: null,
  sharingError: null,
  allSharings: [],
  isLoading: false,
};

export function sharingsReducer(
  state = sharingsInitialState,
  generic: Action,
): SharingsState {

  const action = generic as SharingAction;
  switch (action.type) {

    case SharingActions.GET_ALL_SHARINGS: {
      return {
        ...state,
        isLoading: true,
        documentGuid: action.payload.shared_document || null,
        folderGuid: action.payload.shared_folder || null,
      };
    }

    case SharingActions.GET_ALL_SHARINGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allSharings: action.payload,
      };
    }

    case SharingActions.CREATE_SHARING:
    case SharingActions.UPDATE_SHARING:
    case SharingActions.DELETE_SHARING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SharingActions.CREATE_SHARING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allSharings: state.allSharings.concat([action.payload]),
      };
    }

    case SharingActions.CREATE_SHARING_ERROR: {
      return {
        ...state,
        sharingError: action.payload,
        isLoading: false,
      };
    }

    case SharingActions.UPDATE_SHARING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allSharings: replaceSharingObject(
          action.payload,
          state.allSharings,
        ),
      };
    }

    case SharingActions.DELETE_SHARING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allSharings: removeSharingObject(
          action.payload,
          state.allSharings,
        ),
      };
    }

    case SharingActions.SHARING_DIALOG_CLOSED: {
      return sharingsInitialState;
    }

    default: return state;
  }
}

function replaceSharingObject(object: SharingObject, array: SharingObject[]): SharingObject[] {
  const index = array.findIndex(x => x.guid === object.guid);
  if (index !== -1) array[index] = object;
  else throw new Error('Updated sharing object was not found in store.');

  return array;
}

function removeSharingObject(guid: string, array: SharingObject[]): SharingObject[] {
  return array.filter(x => x.guid !== guid);
}
