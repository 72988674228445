/* eslint-disable max-classes-per-file */
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { MytitleError } from 'src/app/client/models/client.models';
import {
  CreateDocumentsDeletionParams,
  Document,
  FinishDocumentsDeletionResponse,
  ListDocumentsParams,
  ListDocumentsResponse,
  PatchDocumentRequest,
} from 'src/app/client/models/mytitle-service.models';

export enum LoadDocumentsActions {
  UPDATE_MY_DOCUMENTS = '[MyDocuments] Update my documents',
  CLEAN_MY_DOCUMENTS = '[MyDocuments] Clean my documents',

  LOAD_DOCUMENTS_START = '[MyDocuments] LoadDocuments Start',
  LOAD_DOCUMENTS_SUCCESS = '[MyDocuments] LoadDocuments Success',
  LOAD_DOCUMENTS_ERROR = '[MyDocuments] LoadDocuments Error',
}

export class UpdateMyDocuments implements Action {
  public readonly type = LoadDocumentsActions.UPDATE_MY_DOCUMENTS;
  constructor(public payload: { folderGuid: string; searchText: string }) {}
}

export class CleanMyDocuments implements Action {
  public readonly type = LoadDocumentsActions.CLEAN_MY_DOCUMENTS;
}

export class LoadDocumentsStart implements Action {
  public readonly type = LoadDocumentsActions.LOAD_DOCUMENTS_START;
  constructor(public payload: ListDocumentsParams) {}
}

export class LoadDocumentsSuccess implements Action {
  public readonly type = LoadDocumentsActions.LOAD_DOCUMENTS_SUCCESS;
  constructor(public payload: ListDocumentsResponse) { }
}

export class LoadDocumentsError implements Action {
  public readonly type = LoadDocumentsActions.LOAD_DOCUMENTS_ERROR;
  constructor(public payload: HttpErrorResponse) { }
}

export type LoadDocumentsAction = LoadDocumentsStart | LoadDocumentsSuccess | LoadDocumentsError;

export enum ChangeDocumentActions {
  CHANGE_DOCUMENT_START = '[MyDocuments] Change Document Start',
  CHANGE_DOCUMENT_SUCCESS = '[MyDocuments] Change Document Success',
  CHANGE_DOCUMENT_ERROR = '[MyDocuments] Change Document Error',
}

export class ChangeDocumentStart implements Action {
  public readonly type = ChangeDocumentActions.CHANGE_DOCUMENT_START;
  constructor(public payload: PatchDocumentRequest & { guid: string }) { }
}

export class ChangeDocumentSuccess implements Action {
  public readonly type = ChangeDocumentActions.CHANGE_DOCUMENT_SUCCESS;
  constructor(public payload: { document: Document; changes: PatchDocumentRequest }) { }
}

export class ChangeDocumentError implements Action {
  public readonly type = ChangeDocumentActions.CHANGE_DOCUMENT_ERROR;
  constructor(public payload: MytitleError[]) { }
}

export type ChangeDocumentAction = UpdateMyDocuments
  | CleanMyDocuments
  | ChangeDocumentStart
  | ChangeDocumentSuccess
  | ChangeDocumentError;

export enum DeleteDocumentsActions {
  CREATE_DELETION_DOCUMENTS_START = '[MyDocuments] Create deletion of documents Start',
  CREATE_DELETION_DOCUMENTS_SUCCESS = '[MyDocuments] Create deletion of documents Success',
  CREATE_DELETION_DOCUMENTS_ERROR = '[MyDocuments] Create deletion of documents Error',

  FINISH_DELETION_DOCUMENTS_START = '[MyDocuments] Finish deletion of documents Start',
  FINISH_DELETION_DOCUMENTS_SUCCESS = '[MyDocuments] Finish deletion of documents Success',
  FINISH_DELETION_DOCUMENTS_ERROR = '[MyDocuments] Finish deletion of documents Error',
}

export class CreateDeletionDocumentsStart implements Action {
  public readonly type = DeleteDocumentsActions.CREATE_DELETION_DOCUMENTS_START;
  constructor(public payload: CreateDocumentsDeletionParams) { }
}

export class CreateDeletionDocumentsSuccess implements Action {
  public readonly type = DeleteDocumentsActions.CREATE_DELETION_DOCUMENTS_SUCCESS;
}

export class CreateDeletionDocumentsError implements Action {
  public readonly type = DeleteDocumentsActions.CREATE_DELETION_DOCUMENTS_ERROR;
  constructor(public payload: HttpErrorResponse) { }
}

export class FinishDeletionDocumentsStart implements Action {
  public readonly type = DeleteDocumentsActions.FINISH_DELETION_DOCUMENTS_START;
  constructor(public payload: string) { }
}

export class FinishDeletionDocumentsSuccess implements Action {
  public readonly type = DeleteDocumentsActions.FINISH_DELETION_DOCUMENTS_SUCCESS;
  constructor(public payload: FinishDocumentsDeletionResponse) { }
}

export class FinishDeletionDocumentsError implements Action {
  public readonly type = DeleteDocumentsActions.FINISH_DELETION_DOCUMENTS_ERROR;
  constructor(public payload: MytitleError[]) { }
}

export type DeleteDocumentsAction =
  | CleanMyDocuments
  | CreateDeletionDocumentsStart
  | CreateDeletionDocumentsSuccess
  | CreateDeletionDocumentsError
  | FinishDeletionDocumentsStart
  | FinishDeletionDocumentsSuccess
  | FinishDeletionDocumentsError;
