/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';

export enum LogoutActions {
  LOGOUT = '[Auth] Logout',
}

export class Logout implements Action {
  public readonly type = LogoutActions.LOGOUT;
}

export type LogoutAction = Logout;
