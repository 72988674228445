import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { MytitleServiceClient } from 'src/app/client/services/mytitle-service.client';
import {
  CheckFreeLimit, CheckFreeLimitExceededSuccess, LimitActions,
} from '../actions/file-limit.action';

@Injectable()
export class FileLimitEffects {


  public readonly checkFreeLimit = createEffect(() => this.storeActions.pipe(
    ofType<CheckFreeLimit>(LimitActions.CHECK_FREE_LIMIT),
    switchMap(() => this.mytitleServiceClient.checkLimit()
          .pipe(
            map(result => new CheckFreeLimitExceededSuccess(result)),
          ),
    ),
  ));

  constructor(
    private storeActions: Actions,
    private mytitleServiceClient: MytitleServiceClient,
  ) {
  }
}
