import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { asyncScheduler, of, scheduled } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { PersistenceService } from '../../services/persistence.service';
import { GetCreditBalance } from '../actions/getCreditBalance.actions';
import { GetUserInfo } from '../actions/getUserInfo.action';
import { GoogleAuthActions } from '../actions/google-auth.action';
import {
  LoadTokenAction,
  LoadTokenActions,
  LoadTokenError,
  LoadTokenSuccess,
} from '../actions/loadToken.action';
import { LoginAction, LoginActions } from '../actions/login.action';
import { Logout, LogoutActions } from '../actions/logout.action';
import {
  RefreshTokenAction,
  RefreshTokenActions,
  RefreshTokenError,
} from '../actions/refreshToken.action';

@Injectable()
export class PersistenceEffects {

  public readonly persistTokenOnLogin = createEffect(() => this.storeActions.pipe(
    ofType(
      LoginActions.LOGIN_SUCCESS,
      RefreshTokenActions.REFRESH_TOKEN_SUCCESS,
      GoogleAuthActions.GOOGLE_LOGIN_SUCCESS),
    tap(({ payload }) => {
      this.persistenceService.persistToken(payload.token);
    }),
  ), { dispatch: false });


  public readonly removeTokenOnRefreshTokenError = createEffect(() => this.storeActions.pipe(
    ofType<RefreshTokenError | Logout>(
      RefreshTokenActions.REFRESH_TOKEN_ERROR, LogoutActions.LOGOUT),
    tap(() => {
      this.persistenceService.removeToken();
      this.router.navigate(['/']);
    }),
  ), { dispatch: false });

  /**
   * Autoload persisted token from localStorage
   */

  public readonly loadToken = createEffect(() => scheduled(of(null), asyncScheduler).pipe(
    map(() => {
      try {
        return new LoadTokenSuccess({
          token: this.persistenceService.loadToken(),
        });
      } catch {
        return new LoadTokenError();
      }
    }),
  ));


  public readonly loadUserInfo = createEffect(() => this.storeActions.pipe(
    // TODO: Add refresh token success action
    ofType(LoadTokenActions.LOAD_TOKEN_SUCCESS),
    mergeMap(() => [
      new GetUserInfo(),
      new GetCreditBalance(),
    ]),
  ));

  constructor(
    private storeActions: Actions<LoginAction | LoadTokenAction | RefreshTokenAction>,
    private persistenceService: PersistenceService,
    private router: Router,
  ) {
  }

}
