import { Action } from '@ngrx/store';
import * as uuid from 'uuid';

import { PatchDocumentRequest } from 'src/app/client/models/mytitle-service.models';
import { Document } from 'src/app/client/models/mytitle-service.models';
import { UploadFolderAction, UploadFolderActions } from './../actions/upload-folder.actions';

export enum FileUploadingStatus {
  WAITING = 'waiting',
  UPLOADING = 'uploading',
  ERROR = 'error',
  PAUSED = 'paused',
  SUCCESS = 'success',
}

export interface ParsedFolderStructure {
  foldersInTotal: number;
  folders: FolderExt[];
  files: FileExt[];
}

export interface FolderExt {
  storeGuid: string; // used only for manipulation and pairing in store before BE guid is created
  folderGuid: string; // guid from BE for this folder
  folderName: string;
  parentFolderGuid: string; // guid from BE for parent of this folder
  childFolders: FolderExt[];
}

export interface FileExt extends File {
  webkitRelativePath: string;
  folderPath?: string;
  parentGuid?: string;
  storeGuid?: string;
  uploadingProgress?: number;
  uploadingStatus?: FileUploadingStatus;
  errorCode?: number;
}

export interface UploadFolderState {
  // general
  uploadingProcessStarted: boolean;
  uploadingProcessFinished: boolean;
  credentials: PatchDocumentRequest;

  // 1. prep
  folders: FolderExt[];
  files: FileExt[];
  parentGuid: string;

  // 2. for folder creating purposes
  totalFolders: number;
  foldersCreated: number;

  // 3. for file uploading purposes
  fileUploadingStarted: boolean;
  currentlyUploading: number;
  uploadedFiles: Document[];

  // 4. for patching uploaded documents
  filePatchingStarted: boolean;
  filesPatched: number;

  // 5. for creating file orders
  creatingFileOrdersStarted: boolean;
  creatingFileOrdersFinished: boolean;
}

export const initialUploadFolderState: UploadFolderState = {
  uploadingProcessStarted: false,
  uploadingProcessFinished: false,
  credentials: null,
  folders: null,
  files: null,
  parentGuid: null,
  totalFolders: null,
  foldersCreated: 0,
  fileUploadingStarted: false,
  currentlyUploading: 0,
  uploadedFiles: null,
  filePatchingStarted: false,
  filesPatched: 0,
  creatingFileOrdersStarted: false,
  creatingFileOrdersFinished: false,
};

export function uploadFolderStateReducer(
  state: UploadFolderState = initialUploadFolderState,
  generic: Action): UploadFolderState {

  const action = generic as UploadFolderAction;
  switch (action.type) {

    case UploadFolderActions.CREATE_FOLDER_STRUCTURE_BUFFER: {
      return {
        ...state,
        folders: action.payload.structure.folders,
        files: action.payload.structure.files,
        totalFolders: action.payload.structure.foldersInTotal,
        parentGuid: action.payload.parentGuid,
        foldersCreated: 0,
      };
    }

    case UploadFolderActions.UPDATE_FOLDER_STRUCTURE_BUFFER: {
      return {
        ...state,
        folders: updateFolderStructure(state.folders, action.payload),
        foldersCreated: state.foldersCreated + action.payload.length,
      };
    }

    case UploadFolderActions.UPLOAD_FOLDER_START: {
      return {
        ...state,
        uploadingProcessStarted: true,
        credentials: action.payload,
      };
    }

    case UploadFolderActions.UPLOAD_FOLDER_FINISHED: {
      return {
        ...state,
        uploadingProcessFinished: true,
      };
    }

    case UploadFolderActions.UPLOAD_FILES_INTO_FOLDER_START: {
      return {
        ...state,
        currentlyUploading: 0,
        uploadedFiles: [],
        files: updateFolderGuidForEveryFile(state.files, state.folders),
        fileUploadingStarted: true,
      };
    }

    case UploadFolderActions.UPLOAD_FOLDER_FILE_START: {
      const files = state.files;
      const index = files.findIndex(x => x.storeGuid === action.payload.storeGuid);
      files[index].uploadingStatus = FileUploadingStatus.UPLOADING;

      return {
        ...state,
        files,
        currentlyUploading: state.currentlyUploading + 1,
      };
    }

    case UploadFolderActions.UPLOAD_FOLDER_FILE_PROGRESS: {
      const files = state.files;
      const index = files.findIndex(x => x.storeGuid === action.payload.storeGuid);
      files[index].uploadingProgress = action.payload.percentDone;

      return {
        ...state,
        files,
      };
    }

    case UploadFolderActions.UPLOAD_FOLDER_FILE_ERROR: {
      const files = state.files;
      const index = files.findIndex(x => x.storeGuid === action.payload.storeGuid);
      files[index].uploadingStatus = FileUploadingStatus.ERROR;
      files[index].uploadingProgress = 0;
      files[index].errorCode = action.payload.error;

      return {
        ...state,
        files,
        currentlyUploading: state.currentlyUploading - 1,
      };
    }

    case UploadFolderActions.UPLOAD_FOLDER_FILE_RETRY: {
      const files = state.files;
      const index = files.findIndex(x => x.storeGuid === action.payload);
      files[index].uploadingStatus = FileUploadingStatus.WAITING;
      files[index].uploadingProgress = 0;

      return {
        ...state,
        files,
      };
    }

    case UploadFolderActions.UPLOAD_FOLDER_FILE_REMOVE: {
      const files = state.files;
      const index = files.findIndex(x => x.storeGuid === action.payload);
      files.splice(index, 1);

      return {
        ...state,
        files,
      };
    }

    case UploadFolderActions.UPLOAD_FOLDER_FILE_SUCCESS: {
      const files = state.files;
      const index = files.findIndex(x => x.storeGuid === action.payload.storeGuid);
      const uploadedFiles = state.uploadedFiles || [];
      uploadedFiles.push(action.payload.file);
      files[index].uploadingStatus = FileUploadingStatus.SUCCESS;
      files[index].uploadingProgress = 100;

      return {
        ...state,
        files,
        uploadedFiles,
        currentlyUploading: state.currentlyUploading - 1,
      };
    }

    case UploadFolderActions.PATCH_FOLDER_FILES: {
      return {
        ...state,
        filePatchingStarted: true,
      };
    }

    case UploadFolderActions.PATCH_FOLDER_FILE_SUCCESS: {
      return {
        ...state,
        filesPatched: state.filesPatched + 1,
      };
    }

    case UploadFolderActions.CREATE_FOLDER_FILE_ORDERS_START: {
      return {
        ...state,
        creatingFileOrdersStarted: true,
      };
    }

    case UploadFolderActions.CREATE_FOLDER_FILE_ORDERS_SUCCESS: {
      return {
        ...state,
        creatingFileOrdersFinished: true,
      };
    }

    case UploadFolderActions.RESET_STORE: {
      return initialUploadFolderState;
    }

    default: return state;
  }
}

function updateFolderStructure(
  currentFolders: FolderExt[],
  updatedFolders: FolderExt[]): FolderExt[] {

    if (gotInitialFolderUpdated(currentFolders, updatedFolders)) {
      return replaceWholeStructure(currentFolders, updatedFolders);
    } else {
      const parentGuid = getFolderParentGuid(updatedFolders);
      return replaceFolderChildren(parentGuid, updatedFolders, currentFolders);
    }
  }

function getFolderParentGuid(folders: FolderExt[]): string {
  if (folders && folders.length) return folders[0].parentFolderGuid;
  else return null;
}

function replaceFolderChildren(
  folderGuid: string,
  folders: FolderExt[],
  currentFolders: FolderExt[]) {

    const folder = searchInFoldersByGuid(folderGuid, currentFolders);

    if (folder) folder.childFolders = folders;

    return currentFolders;
  }

function searchInFoldersByGuid(guid: string, folders: FolderExt[]): FolderExt {
  // try first level
  const folderIndex = folders.findIndex(x => x.folderGuid === guid);
  if (folderIndex !== -1) return folders[folderIndex];
  // search children if there is no appearance on the first level
  else {
    let result: FolderExt = null;

    for (const folder of folders) {
      if (folder.childFolders && folder.childFolders.length) {
        result = searchInFoldersByGuid(guid, folder.childFolders);
        if (result) return result;
      } else result = null;
    }

    return result;
  }
}

function gotInitialFolderUpdated(
  currentFolders: FolderExt[],
  updatedFolders: FolderExt[]): boolean {

    if (currentFolders &&
      currentFolders.length === 1 &&
        updatedFolders &&
        updatedFolders.length === 1) {
          return currentFolders[0].storeGuid === updatedFolders[0].storeGuid;
    } else return false;
  }

function replaceWholeStructure(
  currentFolders: FolderExt[],
  updatedFolders: FolderExt[]): FolderExt[] {

    currentFolders[0] = updatedFolders[0];
    return currentFolders;
  }

function updateFolderGuidForEveryFile(files: FileExt[], folders: FolderExt[]): FileExt[] {
  files.forEach(file => {
    file.storeGuid = uuid.v4();
    file.uploadingStatus = FileUploadingStatus.WAITING;
    file.uploadingProgress = 0;

    let folderLevel = folders;
    const absolutePathParsed = file.webkitRelativePath
      .replace(file.name, '')
      .split('/');
    absolutePathParsed.pop();

    for (let index = 0; index < absolutePathParsed.length; index++) {
      const folderIndex = folderLevel.findIndex(x => x.folderName === absolutePathParsed[index]);
      if (folderIndex === -1) continue;

      if ((index + 1) === absolutePathParsed.length) {
        file.parentGuid = folderLevel[folderIndex].folderGuid;
      } else {
        folderLevel = folderLevel[folderIndex].childFolders;
      }
    }
  });

  return files;
}
