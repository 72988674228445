<section class="container">
  <h2 class="section-title" i18n="@@howItWorksTitle">Mytitling process step by step</h2>
  <!-- DESKTOP -->
  <div class="section__wrapper">
    <div class="process">
      <div class="lefty">
        <div class="section__step section__step--left"
             (mouseenter)="selectContainer(1)">
          <p class="section__step-name"
             [ngClass]="{ 'selected-name' : selectedContainerId === 1 }"
             i18n="@@processOneTitle">Validation hash saved onto blockchain</p>
          <p class="section__description"
             [ngClass]="{ 'selected-description' : selectedContainerId === 1 }"
             i18n="@@processOneDescription">We create a code of your file and store it on blockchain.
            This step is verified by a digital timestamp, which identifies the date stored.</p>
        </div>
        <div class="section__step section__step--left"
             (mouseenter)="selectContainer(3)">
          <p class="section__step-name"
             [ngClass]="{ 'selected-name' : selectedContainerId === 3 }"
             i18n="@@processTwoTitle">Download your ownership certificate</p>
          <p class="section__description"
             [ngClass]="{ 'selected-description' : selectedContainerId === 3 }"
             i18n="@@processTwoDescription">The ownership certificate carries the information about your stored files and your personal ID.
            It serves as a legal proof oof your ownership in case it is questioned or misused.</p>
        </div>
      </div>
      <div class="line"></div>
      <div class="righty">
        <div class="section__step"
             (mouseenter)="selectContainer(0)">
          <p class="section__step-name"
             [ngClass]="{ 'selected-name' : selectedContainerId === 0 }"
             i18n="@@processThreeTitle">Personal account & uploaded files</p>
          <p class="section__description"
             [ngClass]="{ 'selected-description' : selectedContainerId === 0 }"
             i18n="@@processThreeDescription">Create your personal account and choose a plan which fits your needs.
            Simply upload the file from your computer.
            Now your files are safely stored and encrypted on this private storage.</p>
        </div>
        <div class="section__step"
             (mouseenter)="selectContainer(2)">
          <p class="section__step-name"
             [ngClass]="{ 'selected-name' : selectedContainerId === 2 }"
             i18n="@@processFourTitle">Get the digital stamp</p>
          <p class="section__description"
             [ngClass]="{ 'selected-description' : selectedContainerId === 2 }"
             i18n="@@processFourDescription">The digital timestamp is a protection tool, which serves as the identificator.
            Such timestamp allows to recognise the encrypted version of your stored data.</p>
        </div>
        <div class="section__step"
             (mouseenter)="selectContainer(4)">
          <p class="section__step-name"
             [ngClass]="{ 'selected-name' : selectedContainerId === 4 }"
             i18n="@@processFiveTitle">Have a lifelong protection of your work</p>
          <p class="section__description"
             [ngClass]="{ 'selected-description' : selectedContainerId === 4 }"
             i18n="@@processFiveDescription">With this ownership proof you do not have to worry any longer.
            Via blockchain, Mytitle helps to protect what is rightfully yours permanently.</p>
        </div>
      </div>
    </div>
    <div class="section__ellipse-wrapper">
      <img src="/assets/how-it-works/process-ellipse.svg" alt="" class="section__ellipse">
      <img [src]="carouselImages[selectedContainerId]"
           alt=""
           class="section__image"
           [ngClass]="[ getCorrectImagePosition(selectedContainerId) ]">
    </div>
  </div>
  <!-- MOBILE -->
  <div class="section__mobile-wrapper container">
    <div class="mobile-wrapper">
      <div class="mobile-wrapper__step">
        <p class="mobile-wrapper__step--name"
           i18n="@@processOneTitle">Validation hash saved onto blockchain</p>
        <p class="mobile-wrapper__step--description"
           i18n="@@processOneDescription">We create a code of your file and store it on blockchain.
          This step is verified by a digital timestamp, which identifies the date stored.</p>
      </div>
      <div class="mobile-wrapper__step mobile-wrapper__step--right">
        <p class="mobile-wrapper__step--name"
           i18n="@@processTwoTitle">Download your ownership certificate</p>
        <p class="mobile-wrapper__step--description"
           i18n="@@processTwoDescription">The ownership certificate carries the information about your stored files and your personal ID.
          It serves as a legal proof oof your ownership in case it is questioned or misused.</p>
      </div>
      <div class="mobile-wrapper__step">
        <p class="mobile-wrapper__step--name"
           i18n="@@processThreeTitle">Personal account & uploaded files</p>
        <p class="mobile-wrapper__step--description"
           i18n="@@processThreeDescription">Create your personal account and choose a plan which fits your needs.
          Simply upload the file from your computer.
          Now your files are safely stored and encrypted on this private storage.</p>
      </div>
      <div class="mobile-wrapper__step mobile-wrapper__step--right">
        <p class="mobile-wrapper__step--name"
           i18n="@@processFourTitle">Get the digital stamp</p>
        <p class="mobile-wrapper__step--description"
           i18n="@@processFourDescription">The digital timestamp is a protection tool, which serves as the identificator.
          Such timestamp allows to recognise the encrypted version of your stored data.</p>
      </div>
      <div class="mobile-wrapper__step">
        <p class="mobile-wrapper__step--name"
           i18n="@@processFiveTitle">Have a lifelong protection of your work</p>
        <p class="mobile-wrapper__step--description"
           i18n="@@processFiveDescription">With this ownership proof you do not have to worry any longer.
          Via blockchain, Mytitle helps to protect what is rightfully yours permanently.</p>
      </div>
    </div>
  </div>
</section>