import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarDismiss as MatSnackBarDismiss } from '@angular/material/legacy-snack-bar';
import { AppState } from '../../app/states/models/app.models';
import { FreeLimitWarningShown } from '../../auth/store/actions/file-limit.action';

type SnackbarActionType = 'FREE_LIMIT_WARNING_SHOWN';

export interface SnackbarConfigParams {
  duration?: number;
  triggerAction?: SnackbarActionType;
}

export interface SnackBarQueueItem {
  message: string;
  beingDispatched: boolean;
  configParams?: SnackbarConfigParams;
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService implements OnDestroy {

  private readonly snackBarQueue = new BehaviorSubject<SnackBarQueueItem[]>([]);
  private readonly snackBarQueue$ = this.snackBarQueue.asObservable();
  private ngDestroy = new Subject<void>();

  constructor(
    private matSnackBar: MatSnackBar,
    private store: Store<AppState>,
  ) {

    /* Dispatches all queued snack bars one by one */
    this.snackBarQueue$
     .pipe(
       filter(queue => queue.length > 0 && !queue[0].beingDispatched),
       tap(() => {
         const updatedQueue = this.snackBarQueue.value;
         updatedQueue[0].beingDispatched = true;
         this.snackBarQueue.next(updatedQueue);
       }),
       map(queue => queue[0]),
      takeUntil(this.ngDestroy))
     .subscribe(snackBarItem => this.showSnackbar(snackBarItem.message, snackBarItem.configParams));
  }

  public queueSnackBar(message: string, configParams?: SnackbarConfigParams) {
    this.snackBarQueue.next(
      this.snackBarQueue.value.concat([{ message, configParams, beingDispatched: false }]),
    );
  }

  public ngOnDestroy() {
    this.snackBarQueue.next([]);
    this.snackBarQueue.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  private showSnackbar(message: string, configParams?: SnackbarConfigParams) {
    const duration = this.getDuration(configParams);
    this.removeDismissedSnackBar(
      this.matSnackBar.open(message, 'OK', { duration }).afterDismissed(),
    );
    this.triggerAction(configParams);
  }

  /* Remove dismissed snack bar from queue and triggers another one to appear */
  private removeDismissedSnackBar(dismissed: Observable<MatSnackBarDismiss>) {
    dismissed
      .pipe(
        delay(1000),
        take(1))
      .subscribe(() => {
        const updatedQueue = this.snackBarQueue.value;
        if (updatedQueue[0].beingDispatched) updatedQueue.shift();
        this.snackBarQueue.next(updatedQueue);
      });
  }

  private getDuration(configParams?: SnackbarConfigParams): number {
    if (configParams && configParams.duration) return configParams.duration;
    else return 10000;
  }

  private triggerAction(configParams?: SnackbarConfigParams) {
    if (configParams && configParams.triggerAction) {
      if (configParams.triggerAction === 'FREE_LIMIT_WARNING_SHOWN') {
        this.store.dispatch(new FreeLimitWarningShown());
      }
    }
  }
}
