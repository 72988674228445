<header class="header-wrapper"
        [ngClass]=" {'sticky' : (isSticky && ((colorTheme | async) === 'transparent')) }">
  <div class="container">
    <div class="row no-gutters">
      <div class="col d-flex justify-content-between no-gutters">
        <a class="col-auto mr-auto logo-link" routerLink="">
          <svg class="mt-main-logo">
            <use xlink:href="/assets/img/sprite.svg#logo"></use>
          </svg>
        </a>
        <nav class="col-auto mt-nav">
          <a class="nav-link"
             [routerLink]="['/']"
             [mtScrollAnchor]="router.url ==='/' && 'how'"
             [fragment]="router.url !=='/' && 'how'"
             *ngIf="((isLoggedIn | async) === false) && !mediumScreen && !smallerScreen"
             i18n="@@howDoesItWork" >How Does It Work?</a>
          <a class="nav-link"
             [routerLink]="['/']"
             [mtScrollAnchor]="router.url ==='/' && 'pricing'"
             [fragment]="router.url !=='/' && 'pricing'"
             *ngIf="(isLoggedIn | async) === false"
             i18n="@@pricing">Pricing</a>
          <a class="nav-link"
             [href]="getBlog()"
             rel="noreferrer"
          >Blog</a>
          <a class="nav-link"
             [routerLink]="null" *ngIf="router.url==='/'" mtScrollAnchor="faq">
            FAQ
          </a>
          <a class="nav-link" *ngIf="router.url!=='/'"
             [routerLink]="[appRoutes.FAQ]">
            FAQ
          </a>

          <a class="nav-link"
             target="_blank"
             rel="noreferrer"
             [href]="getBusiness()"
             *ngIf="((isLoggedIn | async) === false) && !smallerScreen"><span i18n="@@forBusiness"
                                                                              class="nav-link">Mytitle for Business</span></a>
          <a *ngIf="(isLoggedIn | async) === false"
             class="login-link"
             [routerLink]="['/', appRoutes.Login]"><b i18n="@@logIn">Log In</b></a>
          <a *ngIf="(isLoggedIn | async) === false"
             class="start-link"
             data-cy="sign-up-link"
             [routerLink]="['/', appRoutes.SignUp]"
             ><b>Start</b></a>
          <a *ngIf="isLoggedIn | async"
             class="nav-link font-weight-bold"
             [routerLink]="['/', appRoutes.CreditBundleChoice]">{{ creditBalance | async }} <span i18n="@@credits">Credits</span></a>
          <a *ngIf="isLoggedIn | async"
             class="nav-link"
             [routerLink]="['/', appRoutes.CreditBundleChoice]"
             data-cy="nav-buy-credits"
          ><span i18n="@@buyCredits">Buy credits</span></a>
          <a *ngIf="isLoggedIn | async"
             class="nav-link"
             [routerLink]="['/', 'my-documents']" i18n="@@myDocuments">My documents</a>
          <a *ngIf="(isLoggedIn | async)"
             class="start-link"
             [matMenuTriggerFor]="uploadMenu"><b i18n="@@uploadFile">Upload file</b></a>
          <mt-user-indicator *ngIf="isLoggedIn | async" class="mx-3">
          </mt-user-indicator>
        </nav>
      </div>
    </div>
  </div>
</header>

<mat-menu #uploadMenu="matMenu">
  <mt-drop-zone (select)="uploadService.fileAdded($event)">
    <a mat-menu-item i18n="@@uploadFile" (click)="closeMenu()">
      Upload file
    </a>
  </mt-drop-zone>

  <mt-folder-upload (select)="uploadService.folderUpload($event)">
    <a mat-menu-item i18n="@@uploadFolder" (click)="closeMenu()">
      Upload folder
    </a>
  </mt-folder-upload>
</mat-menu>
