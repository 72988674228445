import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Environment } from 'src/environments/models/environment.models';
import { AvailableLanguageMutations } from 'src/environments/models/language.models';


@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {

  private environment: Environment;

  constructor() {
    this.environment = environment;
  }

  public get isCzechEnvironment(): boolean {
    return this.environment.language === AvailableLanguageMutations.CZECH;
  }

  public get isEnglishEnvironment(): boolean {
    return this.environment.language === AvailableLanguageMutations.ENGLISH;
  }


  public isIOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

  public isMac() {
    return !!navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);
  }

  public isSmall() {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }


}
