import { Action } from '@ngrx/store';
import * as jwtDecode from 'jwt-decode';
import { GeoIP } from 'src/app/client/models/users.modules';

import {
  ChangeUserInfoAction,
  ChangeUserInfoActions,
} from 'src/app/pages/settings/store/actions/change-user-info.actions';
import {
  TwoFactorAuthenticationAction,
  TwoFactorAuthenticationActions,
} from 'src/app/pages/settings/store/actions/two-factor-authentication.actions';
import { LimitAction, LimitActions } from '../actions/file-limit.action';
import {
  GetCreditBalanceAction,
  GetCreditBalanceActions,
} from '../actions/getCreditBalance.actions';
import { GetGeoIPAction, GetGeoIPActions } from '../actions/getGeoIP.action';
import { GetUserInfoAction, GetUserInfoActions } from '../actions/getUserInfo.action';
import { GoogleAuthAction, GoogleAuthActions } from '../actions/google-auth.action';
import { LoadTokenAction, LoadTokenActions } from '../actions/loadToken.action';
import { LocationVerifyAction, LocationVerifyActions } from '../actions/locationVerify';
import { LoginAction, LoginActions } from '../actions/login.action';
import { LogoutAction, LogoutActions } from '../actions/logout.action';
import { RefreshTokenAction, RefreshTokenActions } from '../actions/refreshToken.action';
import { JWTTokenPayload } from '../models/jwt-token-payload';
import { TokenInfo } from '../models/tokenInfo';
import { LimitExceeded, User } from '../models/user';

export interface UserState {
  user: User;
  isLoadingUser: boolean;
  token: TokenInfo;
  geoIP: GeoIP;
  limitExceeded: LimitExceeded;
  credit: number;
}

export const initialUserState: UserState = {
  user: null,
  isLoadingUser: false,
  token: null,
  geoIP: null,
  credit: null,
  limitExceeded: {
    isLimitExceeded: false,
    wasWarningShown: false,
    freeDocuments: null,
  },
};

export function userReducer(
  state = initialUserState,
  generic: Action,
): UserState {
  // Typing here because of strictFunctionTypes
  // prevents setting the `action` type
  const action = generic as LoginAction
    | RefreshTokenAction
    | GetUserInfoAction
    | LoadTokenAction
    | LogoutAction
    | GoogleAuthAction
    | GetCreditBalanceAction
    | GetGeoIPAction
    | LocationVerifyAction
    | TwoFactorAuthenticationAction
    | LimitAction
    | ChangeUserInfoAction;

  switch (action.type) {
    case LoginActions.LOGIN_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          isInLoginProcess: true,
          email: action.payload.email,
        },
        token: {
          ...action.payload.token,
        },
      };
    }

    case GoogleAuthActions.GOOGLE_LOGIN_SUCCESS: {
      return {
        ...state,
        token: {
          ...action.payload.token,
        },
        user: {
          ...state.user,
          isInLoginProcess: true,
        },
      };
    }

    case RefreshTokenActions.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        token: {
          ...action.payload.token,
        },
      };
    }

    case RefreshTokenActions.REFRESH_TOKEN_ERROR: {
      return {
        ...state,
        user: null,
        token: null,
      };
    }

    case LogoutActions.LOGOUT: {
      return {
        ...state,
        user: null,
        token: null,
        credit: null,
        limitExceeded: {
          isLimitExceeded: false,
          wasWarningShown: false,
          freeDocuments: null,
        },
      };

    }

    case LocationVerifyActions.LOCATION_VERIFIED: {
      return {
        ...state,
        user: {
          ...state.user,
          isInLoginProcess: false,
        },
      };
    }

    case ChangeUserInfoActions.CHANGE_USER_INFO: {
      return {
        ...state,
        user: {
          ...state.user,
          isInLoginProcess: true,
        },
      };
    }

    case GetUserInfoActions.GET_USER_INFO: {
      return {
        ...state,
        user: {
          ...state.user,
        },
        isLoadingUser: true,
      };
    }

    case ChangeUserInfoActions.CHANGE_USER_INFO_SUCCESS:
    case GetUserInfoActions.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
        isLoadingUser: false,
      };
    }

    case LoadTokenActions.LOAD_TOKEN_SUCCESS: {
      const decodedToken: JWTTokenPayload = jwtDecode(action.payload.token.accessToken);

      return {
        ...state,
        user: {
          ...state.user,
          email: decodedToken.email,
          fullName: decodedToken.name,
          is_eu: decodedToken.is_eu,
        },
        token: {
          ...action.payload.token,
        },
      };
    }

    case GetGeoIPActions.GET_GEO_IP_SUCCESS: {
      return {
        ...state,
        geoIP: action.payload,
      };
    }

    case TwoFactorAuthenticationActions.TWO_FA_CODE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          two_factor_auth: true,
        },
      };
    }

    case TwoFactorAuthenticationActions.TWO_FA_REMOVAL_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          two_factor_auth: false,
        },
      };
    }

    case GetCreditBalanceActions.GET_CREDIT_BALANCE_SUCCESS: {
      return {
        ...state,
        credit: action.payload.credits_count,
      };
    }

    case GetCreditBalanceActions.CLEAR_CREDIT_BALANCE: {
      return {
        ...state,
        credit: null,
      };
    }

    case LimitActions.CHECK_FREE_LIMIT_SUCCESS:
      return {
        ...state,
        limitExceeded: {
          ...state.limitExceeded,
          isLimitExceeded: action.payload.limit_exceeded,
          freeDocuments: action.payload.free_documents,
        },
      };

    case LimitActions.FREE_LIMIT_WARNING_SHOWN:
      return {
        ...state,
        limitExceeded: {
          ...state.limitExceeded,
          wasWarningShown: true,
        },
      };
  }

  return state;
}
