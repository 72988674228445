/* eslint-disable max-classes-per-file */
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { GetUserCreditBalanceResponse } from '../../../client/models/credit.models';

export enum GetCreditBalanceActions {
  GET_CREDIT_BALANCE = '[Auth] Get credit balance',
  GET_CREDIT_BALANCE_SUCCESS = '[Auth] Get credit balance Success',
  GET_CREDIT_BALANCE_ERROR = '[Auth] Get credit balance Error',
  CLEAR_CREDIT_BALANCE = '[Auth] Clear credit balance',
}

export class GetCreditBalance implements Action {
  public readonly type = GetCreditBalanceActions.GET_CREDIT_BALANCE;
}

export class GetCreditBalanceSuccess implements Action {
  public readonly type = GetCreditBalanceActions.GET_CREDIT_BALANCE_SUCCESS;
  constructor(public payload: GetUserCreditBalanceResponse) { }
}

export class GetCreditBalanceError implements Action {
  public readonly type = GetCreditBalanceActions.GET_CREDIT_BALANCE_ERROR;
  constructor(public payload: HttpErrorResponse) { }
}

export class ClearCreditBalance implements Action {
  public readonly type = GetCreditBalanceActions.CLEAR_CREDIT_BALANCE;
}

export type GetCreditBalanceAction = GetCreditBalance
  | GetCreditBalanceSuccess
  | GetCreditBalanceError
  | ClearCreditBalance;
