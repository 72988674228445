import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Type } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Event as RouterEvent, Router, RoutesRecognized } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, skipWhile, take } from 'rxjs/operators';
import { AppState, RouterStateUrl } from '../../app/states/models/app.models';
import { TRANSFER_STATE_KEY,
} from './transfer-state/browser-import-state/browser-import-ssr-state.module';

/** UniversalService contains methods to make easy development in the universal environment. */
@Injectable({
  providedIn: 'root',
})
export class UniversalService {
  private firstNavigation = true;
  private serverSideRendered: boolean;
  private platformServer: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: string,
              transferState: TransferState,
              router: Router,
              private actions: Actions) {
    this.captureFirstNavigation(router);
    this.determineServerSideRendered(transferState);
    this.setPlatformServerAndBrowser(platformId);
  }

  /** Returns true when this app runs in the browser and was rendered on the server. */
  public isServerSideRendered(): boolean {
    return this.serverSideRendered;
  }

  public isPlatformServer(): boolean {
    return this.platformServer;
  }

  /**
   * isRoutingTo parses ROUTER_NAVIGATION based on the given component to which the Angular
   * Router is routing. This method helps to prevent duplicate API calls.
   */
  public isRoutingTo(component: Type<any>): Observable<RouterStateUrl> {
    return this.actions
      .pipe(
        // filter by the ROUTER_NAVIGATION action
        ofType<RouterNavigationAction<RouterStateUrl>>(ROUTER_NAVIGATION),
        // could be null when snapshot is not found
        map(action => this.findRouteSnapshot(component, action.payload.routerState)),
        // do not pass snapshot when is has not changed
        distinctUntilChanged((oldSnapshot, newSnapshot) => {
          // oldSnapshot && ... syntax in case oldSnapshot would be null
          const oldParams = oldSnapshot && {
            url: oldSnapshot.url,
            params: oldSnapshot.params,
            queryParams: oldSnapshot.queryParams,
          };
          const newParams = newSnapshot && {
            url: newSnapshot.url,
            params: newSnapshot.params,
            queryParams: newSnapshot.queryParams,
          };
          // TODO: consider using better comparison method (eg. isEqual from lodash)
          return JSON.stringify(oldParams) === JSON.stringify(newParams);
        }),
        filter(snapshot => !!snapshot), // pass only non-null value
        this.firstOrSkipOp(), // eventually skip the first navigation event
      );
  }

  private captureFirstNavigation(router: Router) {
    // if there is a problem with duplicate requests upon first load with future versions of
    // Angular, this would be the place to check
    router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof RoutesRecognized),
        skip(1), // Wait for the second navigation event.
        take(1),  // we are not interested in other navigation events.
      )
      .subscribe(() => {
        this.firstNavigation = false;
      });
  }

  private determineServerSideRendered(transferState: TransferState) {
    this.serverSideRendered = transferState.hasKey(makeStateKey<AppState>(TRANSFER_STATE_KEY));
  }

  private setPlatformServerAndBrowser(platformId: string) {
    this.platformServer = isPlatformServer(platformId);
  }

  /** Take only the first event on the server or skip the first event in the browser on the first
   *  navigation.
   */
  private firstOrSkip<T>(observable: Observable<T>): Observable<T> {
    if (this.isPlatformServer()) return observable.pipe(take(1));
    else {
      return observable.pipe(
        skipWhile(() => this.firstNavigation && this.isServerSideRendered()),
      );
    }
  }

  private firstOrSkipOp<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => this.firstOrSkip(source);
  }

  /** Find the route snapshot by component in the tree of snapshots. */
  private findRouteSnapshot(component: Type<any>,
                            snapshot: RouterStateUrl): RouterStateUrl {
    if (snapshot.component === component) return snapshot;
    else return null;
  }
}
