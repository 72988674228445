/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import { NewRegistrationParams } from 'src/app/client/models/users.modules';

export enum RegisterActions {
  REGISTER = '[Auth] Register',
  REGISTER_SUCCESS = '[Auth] Register Success',
  REGISTER_ERROR = '[Auth] Register Error',
  REGISTER_CANCEL = '[Auth] Register Cancel',
  REGISTER_CLEAN = '[Auth] Register Clean',
}

export class Register implements Action {
  public readonly type = RegisterActions.REGISTER;
  constructor(public payload: NewRegistrationParams) { }
}

export class RegisterSuccess implements Action {
  public readonly type = RegisterActions.REGISTER_SUCCESS;
}

export class RegisterError implements Action {
  public readonly type = RegisterActions.REGISTER_ERROR;
  constructor(public payload: MytitleError[]) { }
}

export class RegisterCancel implements Action {
  public readonly type = RegisterActions.REGISTER_CANCEL;
}

export class RegisterClean implements Action {
  public readonly type = RegisterActions.REGISTER_CLEAN;
}

export type RegisterAction = Register
  | RegisterSuccess
  | RegisterError
  | RegisterCancel
  | RegisterClean;
