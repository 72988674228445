import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { getHttpParams } from 'src/app/app/utils/get-http-params';
import {
  CreateCreditOrderParams,
  CreateCreditOrderResponse,
  CreditOrderHistoryRequest,
  CreditOrderHistoryResponse,
  GetCreditOrderConfigResponse,
  GetUserCreditBalanceResponse,
  VoucherRequest,
  VoucherResponse,
} from '../models/credit.models';
import { GetGopayPaymentStatus } from '../models/gopay.models';
import {
  FileOrderRequestParams,
  FileOrderResponse,
  FolderOrderRequestParams,
  FolderOrderResponse,
} from '../models/order.models';
import { CreatePaymentRequest, CreatePaymentResponse } from '../models/payment.models';

@Injectable()
export class BillingServiceClient {

  private serviceUrl = '/api/v1/billing';

  constructor(private http: HttpClient) {}

  public getGopayPaymentState(payment_id: number): Observable<GetGopayPaymentStatus> {
    return this.http.get<GetGopayPaymentStatus>(
      `${this.serviceUrl}/payment/gopay/${payment_id}/`);
  }

  public getCreditOrderConfig(): Observable<GetCreditOrderConfigResponse> {
    return this.http.get<GetCreditOrderConfigResponse>(`${this.serviceUrl}/credits/order-config`);
  }

  public getUserCreditBalance(): Observable<GetUserCreditBalanceResponse> {
    return this.http.get<GetUserCreditBalanceResponse>(`${this.serviceUrl}/credits/user-balance`);
  }

  public createCreditOrder(params: CreateCreditOrderParams): Observable<CreateCreditOrderResponse> {
    return this.http.post<CreateCreditOrderResponse>(`${this.serviceUrl}/credits/order`, params);
  }

  public createPayment(params: CreatePaymentRequest): Observable<CreatePaymentResponse> {
    return this.http.post<CreatePaymentResponse>(`${this.serviceUrl}/payment/`, params);
  }

  public createFileOrder(params: FileOrderRequestParams): Observable<FileOrderResponse> {
    return this.http.post<FileOrderResponse>(`${this.serviceUrl}/file-order/`, params);
  }

  public createFolderOrder(params: FolderOrderRequestParams): Observable<FolderOrderResponse> {
    return this.http.post<FolderOrderResponse>(`${this.serviceUrl}/folder-order`, params);
  }

  public getCreditOrderHistory(params: CreditOrderHistoryRequest):
      Observable<CreditOrderHistoryResponse> {
    return this.http.get<CreditOrderHistoryResponse>
      (`${this.serviceUrl}/order/credit-order-history`, { params: getHttpParams(params) } );
  }

  public validateVoucher(params: VoucherRequest): Observable<VoucherResponse> {
    return this.http.post<VoucherResponse>(`${this.serviceUrl}/voucher/validate`, params);
  }
}
