import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  mergeMapTo,
  switchMap,
  switchMapTo,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { AppRoutes } from 'src/app/app/app.routes.misc';
import { GetCreditBalance } from 'src/app/auth/store/actions/getCreditBalance.actions';
import { CreateDocumentParams } from 'src/app/client/models/mytitle-service.models';
import { FileOrderRequestParams } from 'src/app/client/models/order.models';
import { BillingServiceClient } from 'src/app/client/services/billing-service.client';
import { MytitleServiceClient } from 'src/app/client/services/mytitle-service.client';
import { Notifications } from 'src/app/shared/models/notifications';
import { ErrorHelperService } from 'src/app/shared/services/error-helper.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { parseFilesIntoFolderStructure } from 'src/app/shared/utils/folder-parser';
import {
  FileExt,
  FileUploadingStatus,
  FolderExt,
  ParsedFolderStructure,
} from '../reducers/upload-folder.reducer';
import {
  CreateFolderFileOrdersStart,
  CreateFolderFileOrdersSuccess,
  CreateFolderStructureBuffer,
  CreateFolderStructureStart,
  CreateFolderStructureSuccess,
  DisplayFolderCreditConfirmation,
  PatchFolderFiles,
  PatchFolderFileStart,
  PatchFolderFileSuccess,
  UpdateFolderStructureBuffer,
  UploadFilesIntoFolderStart,
  UploadFilesIntoFolderSuccess,
  UploadFolderAction,
  UploadFolderActions,
  UploadFolderFileError,
  UploadFolderFileProgress,
  UploadFolderFileStart,
  UploadFolderFileSuccess,
  UploadFolderFinished,
  UploadFolderReset,
  UploadFolderStart,
  WaitUntilFolderStructureIsCreated,
} from './../actions/upload-folder.actions';
import { AppState } from './../models/app.models';
import {
  allFilesPatched,
  areAllFilesUploaded,
  areAllFoldersCreated,
  filesInWaitingState,
  getAllUploadedFiles,
  getFolderCredentials,
  getParentFolderGuid,
  numberOfCurrentlyUploadedFiles,
  uploadFolderState,
} from './../selectors/upload-folder.selectors';

@Injectable()
export class UploadFolderEffects {

  /**
   * Display credit checkout to user for whole transaction
   */

  public readonly displayFolderCreditConfirmation = createEffect(() => this.storeActions.pipe(
    ofType<DisplayFolderCreditConfirmation>(UploadFolderActions.DISPLAY_FOLDER_CREDIT_CONFIRMATION),
    map(action => [
      parseFilesIntoFolderStructure(action.payload.files),
      action.payload.parentGuid,
    ] as [ParsedFolderStructure, string]),
    filter(([folderStructure, _]) => {
      if (!folderStructure.folders.length && !folderStructure.files.length) {
        this.snackbarService
          .queueSnackBar(Notifications.NothingSelected);
        return false;
      } else if (!folderStructure.folders.length && folderStructure.files.length) {
        this.snackbarService
          .queueSnackBar(Notifications.WrongButton);
        return false;
      } else return true;
    }),
    map(([folderStructure, parentGuid]) => new CreateFolderStructureBuffer({
        structure: folderStructure,
        parentGuid,
      })),
    tap(() => this.router.navigate(['/', AppRoutes.FolderUploadConfirmation])),
  ));

  /**
   * Triggered whenever user wants to upload a folder.
   * First of all we need to create all subfolders in folder
   * hierarchy for this submitted folder. As soon as all folders are created
   * we can start uploading these files into submitted folder and its subfolders.
   */

  public readonly uploadFolder = createEffect(() => this.storeActions.pipe(
    ofType<UploadFolderStart>(UploadFolderActions.UPLOAD_FOLDER_START),
    switchMapTo(this.store.pipe(
      select(uploadFolderState),
      first(),
    )),
    concatMap(folderState => [
        new WaitUntilFolderStructureIsCreated(),
        new CreateFolderStructureStart({
          folders: folderState.folders,
          parentGuid: folderState.parentGuid,
        }),
      ]),
  ));

  /**
   * To create every single folder level in folder user wants to upload
   * and prepare folder structure before files are uploaded into these
   * sub folders
   */

  public readonly createFolderStructure = createEffect(() => this.storeActions.pipe(
    ofType<CreateFolderStructureStart>(UploadFolderActions.CREATE_FOLDER_STRUCTURE_START),
    mergeMap(action => {
      const xhrRequests: Observable<FolderExt>[] = [];

      action.payload.folders.forEach(folder => {
        xhrRequests.push(this.mytitleServiceClient.createFolder({
          name: folder.folderName || Notifications.WithoutName,
          parent: action.payload.parentGuid,
        }).pipe(
          map(savedFolder => {
            const folderExt: FolderExt = {
              folderGuid: savedFolder.guid,
              parentFolderGuid: savedFolder.parent,
              folderName: savedFolder.name,
              childFolders: folder.childFolders,
              storeGuid: folder.storeGuid, // assign same store guid to saved folder to pair them
            };

            return folderExt;
          }),
        ));
      });

      return forkJoin(xhrRequests).pipe(
        map(results => new CreateFolderStructureSuccess(results)),
        this.errorHelperService.raiseError(),
      );
    }),
  ));

  /**
   * If created folder level has also children folders,
   * repeat whole process again for sub folders
   */

  public readonly handleFolderStructure = createEffect(() => this.storeActions.pipe(
    ofType<CreateFolderStructureSuccess>(UploadFolderActions.CREATE_FOLDER_STRUCTURE_SUCCESS),
    concatMap(action => {
      const createSubFolders: CreateFolderStructureStart[] = [];

      action.payload.forEach(folder => {
        if (folder.childFolders && folder.childFolders.length) {
          createSubFolders.push(new CreateFolderStructureStart({
            folders: folder.childFolders,
            parentGuid: folder.folderGuid,
          }));
        }
      });

      return [
        new UpdateFolderStructureBuffer(action.payload),
        ...createSubFolders,
      ];
    }),
  ));

  /**
   * Wait until whole folder structure is created,
   * then prepare files before uploading them
   */

  public readonly waitUntilFolderStructureIsReady = createEffect(() => this.storeActions.pipe(
    ofType<WaitUntilFolderStructureIsCreated>
      (UploadFolderActions.WAIT_UNTIL_FOLDER_STRUCTURE_IS_READY),
    switchMapTo(this.store.pipe(
      select(areAllFoldersCreated),
      takeWhile(isFolderStructureReady => !isFolderStructureReady, true),
      filter(Boolean),
    )),
    map(() => new UploadFilesIntoFolderStart()),
  ));

  /**
   * File Upload buffer
   * Uploading all files and runs until all files are successfully uploaded
   * i.e. have their uploading status === 'success'. Also allows to upload
   * only 10 files at the time and allows to repeat uploading for files
   * which ended up in error status.
   */

  public readonly uploadFilesIntoFolders = createEffect(() => this.storeActions.pipe(
    ofType<UploadFilesIntoFolderStart>(UploadFolderActions.UPLOAD_FILES_INTO_FOLDER_START),
    // Repeat this until all files are successfully uploaded
    switchMapTo(this.store.pipe(
      select(areAllFilesUploaded),
      takeWhile(allFilesUploaded => !allFilesUploaded, false))),
    // Trigger pipeline whenever number of files with waiting to upload status changes
    switchMapTo(this.store.pipe(
      select(filesInWaitingState),
      takeWhile(files => !!files, false))),
    // Allow to upload only 10 files at the time
    switchMap(files => this.store.pipe(
      select(numberOfCurrentlyUploadedFiles),
      first(),
      filter(filesUploading => filesUploading < 10),
      map(() => {
        const file = files.find(x => x.uploadingStatus === FileUploadingStatus.WAITING);
        return file as FileExt;
      }),
    )),
    filter(file => !!file),
    map(file => new UploadFolderFileStart(file)),
  ));

  /**
   * Uploads every file individually. Reports success/progress/error state.
   */

  public readonly uploadFileStart = createEffect(() => this.storeActions.pipe(
    ofType<UploadFolderFileStart>(UploadFolderActions.UPLOAD_FOLDER_FILE_START),
    mergeMap(action => {
      const params: CreateDocumentParams = {
        file: action.payload,
        is_free: false,
        folder: action.payload.parentGuid ? action.payload.parentGuid : null,
      };

      return this.mytitleServiceClient
        .uploadFile(params)
        .pipe(
          map(event => {
            switch (event.type) {
              case HttpEventType.UploadProgress: {
                const percentDone = Math.round(100 * event.loaded / event.total);
                return new UploadFolderFileProgress({
                  percentDone,
                  storeGuid: action.payload.storeGuid,
                });
              }

              case HttpEventType.Response: {
                event.body.is_free = false;
                return new UploadFolderFileSuccess({
                  file: event.body,
                  storeGuid: action.payload.storeGuid,
                });
              }

              default: return null;
            }
          }),
          filter(response => !!response),
          catchError((error: HttpErrorResponse) => of(new UploadFolderFileError({
            error: error.status,
            storeGuid: action.payload.storeGuid,
          }))),
        );
    }),
  ));

  /**
   * As soon as all files are successfully uploaded, flow continues with
   * creating file orders.
   */

  public readonly waitUntilAllFilesAreUploaded = createEffect(() => this.storeActions.pipe(
    ofType<UploadFilesIntoFolderStart>(UploadFolderActions.UPLOAD_FILES_INTO_FOLDER_START),
    switchMapTo(this.store.pipe(
      select(areAllFilesUploaded),
      takeWhile(allFilesUploaded => !allFilesUploaded, true),
      filter(Boolean))),
    mapTo(new UploadFilesIntoFolderSuccess()),
  ));

  /**
   * If user entered credentials, patch files with these credentials first
   * otherwise create file orders
   */

  public readonly decideWhereToStart = createEffect(() => this.storeActions.pipe(
    ofType<UploadFilesIntoFolderSuccess>(UploadFolderActions.UPLOAD_FILES_INTO_FOLDER_SUCCESS),
    switchMapTo(this.store.pipe(
      select(getFolderCredentials),
      first(),
      map(credentials => Object.keys(credentials).length !== 0))),
    map(areCredentialsFilled => areCredentialsFilled ? new PatchFolderFiles() : new CreateFolderFileOrdersStart()),
  ));

  /**
   * Creating file orders for every uploaded file
   */

  public readonly createAllFileOrders = createEffect(() => this.storeActions.pipe(
    ofType<CreateFolderFileOrdersStart>(UploadFolderActions.CREATE_FOLDER_FILE_ORDERS_START),
    switchMapTo(this.store.pipe(
      select(getAllUploadedFiles),
      first())),
    switchMap(uploadedFiles => {
      const params: FileOrderRequestParams = {
        order_items: [],
      };

      uploadedFiles.forEach(file => {
        params.order_items.push({
          is_free: file.is_free,
          document_guid: file.guid,
        });
      });

      return this.billingServiceClient
        .createFileOrder(params)
        .pipe(
          map(res => new CreateFolderFileOrdersSuccess(res)),
          this.errorHelperService.raiseError(),
        );
    }),
  ));

  /**
   * In case files have to be patched first, wait until all files
   * are patched, then start creating file orders
   */

  public readonly waitUntilAllFilesArePatched = createEffect(() => this.storeActions.pipe(
    ofType<PatchFolderFiles>(UploadFolderActions.PATCH_FOLDER_FILES),
    switchMapTo(this.store.pipe(
      select(allFilesPatched),
      takeWhile(patchingDone => !patchingDone, true),
      filter(Boolean))),
    mapTo(new CreateFolderFileOrdersStart()),
  ));

  /**
   * Patch every uploaded file with credentials provided by user
   */

  public readonly patchAllFiles = createEffect(() => this.storeActions.pipe(
    ofType<PatchFolderFiles>(UploadFolderActions.PATCH_FOLDER_FILES),
    switchMapTo(forkJoin([
      this.store.pipe(
        select(getAllUploadedFiles),
        first()),
      this.store.pipe(
        select(getFolderCredentials),
        first())])),
    concatMap(([uploadedFiles, credentials]) => {
      const patchFolderFileRequests: PatchFolderFileStart[] = [];

      uploadedFiles.forEach(file => {
        patchFolderFileRequests.push(new PatchFolderFileStart({
          guid: file.guid,
          params: credentials,
        }));
      });

      return patchFolderFileRequests;
    }),
  ));


  public readonly patchFolderFile = createEffect(() => this.storeActions.pipe(
    ofType<PatchFolderFileStart>(UploadFolderActions.PATCH_FOLDER_FILE_START),
    map(action => action.payload),
    mergeMap(request => this.mytitleServiceClient
      .patchDocument(request.guid, request.params)
      .pipe(
        map(() => new PatchFolderFileSuccess()),
        this.errorHelperService.raiseError(),
      )),
  ));

  /**
   * Final action trigger as all folders are created, all files uploaded and all file orders created
   */

  public readonly allFileOrdersCreated = createEffect(() => this.storeActions.pipe(
    ofType<CreateFolderFileOrdersSuccess>(UploadFolderActions.CREATE_FOLDER_FILE_ORDERS_SUCCESS),
    delay(1500),
    mapTo(new UploadFolderFinished()),
  ));

  /* Navigate user back to MyDocuments after folder is successfully uploaded with delay */

  public readonly redirectOnUploadFolderSuccess = createEffect(() => this.storeActions.pipe(
    ofType<UploadFolderFinished>(UploadFolderActions.UPLOAD_FOLDER_FINISHED),
    delay(2500),
    switchMapTo(this.store.pipe(
      select(getParentFolderGuid),
      first())),
    tap(parentGuid => {
      if (parentGuid) {
        this.router.navigate(['/', AppRoutes.MyDocuments],
          { queryParams: { folder: parentGuid } });
      } else this.router.navigate(['/', AppRoutes.MyDocuments]);
    }),
    delay(1000),
    mergeMapTo([
      new UploadFolderReset(),
      new GetCreditBalance(),
    ]),
  ));

  constructor(
    private storeActions: Actions<UploadFolderAction>,
    private snackbarService: SnackbarService,
    private mytitleServiceClient: MytitleServiceClient,
    private billingServiceClient: BillingServiceClient,
    private errorHelperService: ErrorHelperService,
    private store: Store<AppState>,
    private router: Router,
  ) {}
}
