import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { UsersServiceClient } from 'src/app/client/services/users-service.client';
import {
  ResetPassword,
  ResetPasswordActions,
  ResetPasswordError,
  ResetPasswordSuccess,
} from '../actions/reset-password.actions';

@Injectable()
export class ResetPasswordEffects {

  public resetPassword = createEffect(() => this.storeActions.pipe(
    ofType<ResetPassword>(ResetPasswordActions.RESET_PASSWORD),
    switchMap(action => this.usersServiceClient.forgotPasswordRequest(action.payload)
      .pipe(
        map(() => new ResetPasswordSuccess()),
        catchError((error: HttpErrorResponse) => of(new ResetPasswordError(error.error))),
      ),
    ),
  ));


  public resetPasswordSuccess = createEffect(() => this.storeActions.pipe(
    ofType<ResetPasswordSuccess>(ResetPasswordActions.RESET_PASSWORD_SUCCESS),
    map(() => this.router.navigate([AppRoutes.ResetPassword, AppRoutes.ResetPasswordSent])),
  ), { dispatch: false });

  constructor(
    private router: Router,
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
  ) {
  }
}
