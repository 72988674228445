import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, first, map, mergeMap, tap } from 'rxjs/operators';
import { UsersServiceClient } from 'src/app/client/services/users-service.client';
import { RefreshTokenError, RefreshTokenSuccess } from '../store/actions/refreshToken.action';
import { TokenInfo } from '../store/models/tokenInfo';
import { UserState } from '../store/reducers/user.reducer';
import { getAuthToken } from '../store/selectors/user.selector';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  /**
   * Fires when once token is refreshed
   */
  public refreshedAccessToken$: Observable<TokenInfo> = null;

  constructor(
    private store: Store<UserState>,
    private usersServiceClient: UsersServiceClient,
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler) {

    return this.store.pipe(
      select(getAuthToken),
      first(),
      mergeMap(token =>
        // TODO: Filter based on our services to prevent token leaks
         this.handleRequest(req, next, token),
      ),
    );
  }

  private handleRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    token: TokenInfo,
  ): Observable<HttpEvent<any>> {

    if (token && token.accessToken) req = this.getAuthorizedRequest(req, token.accessToken);

    return next.handle(req).pipe(
      catchError(err => {
        if (
          // prevent looping refresh token requests
          req.url !== '/api/v1/users/refresh_token'
          && err instanceof HttpErrorResponse
          && err.status === 401
          && token
          && token.refreshToken
        ) {
          // TODO - find cleaner typing solution
          return this.handle401Error(req, next, token) as Observable<HttpEvent<any>>;
        } else {
          return throwError(err);
        }
      }),
    );
  }

  private getAuthorizedRequest(req: HttpRequest<any>, accessToken: string) {
    const authToken = 'Bearer ' + accessToken;
    return req.clone({
      headers: req.headers.set('Authorization', authToken),
    });
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler, token: TokenInfo) {
    if (!this.refreshedAccessToken$) {
      // Keep the ref to the current refresh token info
      this.refreshedAccessToken$ = this.usersServiceClient
        .refreshToken({ refresh: token.refreshToken })
        .pipe(
          map(({ access_token, expires_in, refresh_token }) => ({
            accessToken: access_token,
            expires: new Date(Date.now() + expires_in * 1000),
            refreshToken: refresh_token,
          }) as TokenInfo),
          // let our store know
          tap(
            tokenInfo => this.store.dispatch(new RefreshTokenSuccess({ token: tokenInfo })),
            refreshTokenError => this.store.dispatch(new RefreshTokenError(refreshTokenError)),
          ),
          finalize(() => {
            // once refreshing completes, remove the reference so we can refresh once again
            this.refreshedAccessToken$ = null;
          }),
        );
    }

    // once the token is returned, pass the original request
    return this.refreshedAccessToken$.pipe(
      mergeMap(tokenInfo => this.handleRequest(req, next, tokenInfo)),
    );
  }
}
