import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import {
  CheckFolderResponse,
  Folder } from 'src/app/client/models/mytitle-service.models';
import { FolderAction, FolderActions } from '../actions/folder.actions';

export interface FolderState {
  folderBeingUpdated: boolean;
  folder: Folder;
  folderCheck: CheckFolderResponse;
  error: MytitleError[];
}

export const folderInitialState: FolderState = {
  folderBeingUpdated: false,
  folder: null,
  folderCheck: null,
  error: null,
};

export function folderReducer(
  state = folderInitialState,
  generic: Action,
): FolderState {
  const action = generic as FolderAction;

  switch (action.type) {
    case (FolderActions.PATCH_FOLDER_START):
      return { ...state, folderBeingUpdated: true };

    case (FolderActions.PATCH_FOLDER_SUCCESS):
      return {
        ...state,
        folderBeingUpdated: action.payload.isUpgradingFolder,
      };

    case (FolderActions.PATCH_FOLDER_ERROR):
      return { ...state, error: action.payload };

    case (FolderActions.CLEAN_PATCH_FOLDER):
      return folderInitialState;

    case (FolderActions.CREATE_FOLDER_START):
      return { ...state, folderBeingUpdated: true };

    case (FolderActions.CREATE_FOLDER_SUCCESS):
      return { ...state, folderBeingUpdated: false };

    case (FolderActions.CHECK_FOLDER_FILES_SUCCESS):
      return { ...state, folderCheck: action.payload };

    case (FolderActions.CREATE_FOLDER_ORDER):
      return { ...state, folder: action.payload };
  }
  return state;
}
