import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { DropZoneModule } from 'src/app/partials/drop-zone/drop-zone.module';
import { FooterModule } from 'src/app/partials/footer/footer.module';
import { AnimationOnScrollModule } from 'src/app/shared/utils/animation-on-scroll/animation-on-scroll.module';
import { PaymentOptionsModule } from '../../partials/payment-options/payment-options.module';
import { ScrollModule } from '../directives/scroll/scroll.module';
import { FaqModule } from '../../pages/faq/faq.module';
import { BlogSectionComponent } from './blog-section/blog-section.component';
import { CertificateComponent } from './certificate/certificate.component';
import { HomeComponent } from './home.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { MapComponent } from './map/map.component';
import { PricingComponent } from './pricing/pricing.component';
import { QuotedHeaderComponent } from './quoted-header/quoted-header.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';

@NgModule({
  declarations: [
    HomeComponent,
    HowItWorksComponent,
    PricingComponent,
    MapComponent,
    BlogSectionComponent,
    CertificateComponent,
    QuotedHeaderComponent,
    TestimonialsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FooterModule,
    RouterModule,
    DropZoneModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    PaymentOptionsModule,
    MatIconModule,
    MatTabsModule,
    AnimationOnScrollModule,
    MatExpansionModule,
    ScrollModule,
    FaqModule
  ],
})
export class HomeModule {
}
