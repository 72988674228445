import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { FaqComponent } from './faq.component';

@NgModule({
  declarations: [FaqComponent],
  exports: [
    FaqComponent,
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    MatExpansionModule,
  ]
})
export class FaqModule { }
