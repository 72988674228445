/* eslint-disable @angular-eslint/no-forward-ref */
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FileExt } from 'src/app/app/states/reducers/upload-folder.reducer';

@Component({
  selector: 'mt-folder-upload',
  templateUrl: './folder-upload.component.html',
  styleUrls: ['./folder-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FolderUploadComponent),
      multi: true,
    },
  ],
})
export class FolderUploadComponent implements ControlValueAccessor {

  @ViewChild('folderInput', { static: false })
  public folderInput: ElementRef<HTMLInputElement>;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  public select = new EventEmitter<FileExt[]>();

  public selectedFolder: FileExt[];

  public isFileOver: boolean;

  // ControlValueAccessor filled
  private onChangeHandler: (folder: FileExt[]) => void;
  private onTouchedHandler: () => void;

  constructor() {}

  //// ControlValueAccessor
  public writeValue(folder: any): void {
    this.selectedFolder = folder;
  }

  public registerOnChange(fn: any): void {
    this.onChangeHandler = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedHandler = fn;
  }
  //// ControlValueAccessor end

  public folderChanged(files: FileList) {
    const filesArray = Object.values(files) as FileExt[];

    if (!filesArray.length) return;

    this.selectedFolder = filesArray;
    this.select.emit(filesArray);

    if (this.onChangeHandler) {
      this.onChangeHandler(filesArray);
    }
  }

  public selectFolder() {
    this.fireTouched();
    this.folderInput.nativeElement.click();
  }

  public clicked(event: Event) {
    event.stopPropagation();
    this.selectFolder();
  }

  private fireTouched() {
    if (this.onTouchedHandler) {
      this.onTouchedHandler();
    }
  }
}
