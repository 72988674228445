import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { AuthModule } from '../../auth/auth.module';
import { UserIndicatorComponent } from './user-indicator/user-indicator.component';

@NgModule({
  declarations: [UserIndicatorComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    RouterModule,
    AuthModule,
    MatDividerModule,
  ],
  exports: [
    UserIndicatorComponent,
  ],
})
export class UserIndicatorModule { }
