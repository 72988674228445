/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import {
  CreateCreditOrderParams,
  CreateCreditOrderResponse,
} from '../../../client/models/credit.models';

export enum OrderCreditActions {
  CREATE_CREDIT_ORDER = '[Credit order] Create',
  QUEUE_CREDIT_ORDER = '[Credit order] Queued',
  CREDIT_ORDER_UNAUTHORIZED = '[Credit order] Unauthorized',
  CREDIT_ORDER_AUTHORIZED = '[Credit order] Authorized',
  CREDIT_ORDER_SUCCESS = '[Credit order] Success',
}

export class CreateCreditOrder implements Action {
  public readonly type = OrderCreditActions.CREATE_CREDIT_ORDER;
  constructor(public payload: CreateCreditOrderParams) {}
}
export class QueueCreditOrder implements Action {
  public readonly type = OrderCreditActions.QUEUE_CREDIT_ORDER;
  constructor(public payload: CreateCreditOrderParams) {}
}

export class CreditOrderSuccess implements Action {
  public readonly type = OrderCreditActions.CREDIT_ORDER_SUCCESS;
  constructor(public payload: CreateCreditOrderResponse) {
  }
}

export class CreditOrderUnauthorized implements Action {
  public readonly type = OrderCreditActions.CREDIT_ORDER_UNAUTHORIZED;
}

export class CreditOrderAuthorized implements Action {
  public readonly type = OrderCreditActions.CREDIT_ORDER_AUTHORIZED;
  constructor(public payload: CreateCreditOrderParams) {}
}

export type OrderCreditAction =
  CreateCreditOrder |
  CreditOrderSuccess |
  CreditOrderUnauthorized |
  CreditOrderAuthorized |
  QueueCreditOrder;
