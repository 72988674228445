import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { getIsAuthorized } from '../../auth/store/selectors/user.selector';
import { AppRoutes } from '../app.routes.misc';
import { isCreatingOrder } from '../states/selectors/credit-bundle-choice.selectors';
import { getFileState } from '../states/selectors/file.selectors';
import { getRouterState } from '../states/selectors/router.selectors';

@Injectable({
  providedIn: 'root',
})
export class PreventAuthorizedVisitorGuardService implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<any>,
  ) { }

  public canActivate(): Observable<boolean | UrlTree> {
    return forkJoin([
      this.store.pipe(select(getIsAuthorized), take(1)),
      this.store.pipe(select(getRouterState), take(1)),
      this.store.pipe(select(getFileState), take(1)),
      this.store.pipe(select(isCreatingOrder), take(1)),
    ]).pipe(
      map(([isAuthorized, routerState, fileState, isCreatingCreditOrder]) => {
        if (isAuthorized) {
          // is authorized and is is not in file or credit workflow
          if ((!routerState.queryParams.fileFlow && !routerState.queryParams.creditFlow) ||
          // is authorized and has fileFlow param but doesnt have file in store (app re-init)
          (routerState.queryParams.fileFlow && !fileState.file) ||
          // is authorized and has creditFlow param but is
          // not currently creating credit order (app re-init)
          (routerState.queryParams.creditFlow && !isCreatingCreditOrder)) {
            return this.router.createUrlTree(['/', AppRoutes.MyDocuments]);
          }
        }

        return true;
      }),
    );
  }
}
