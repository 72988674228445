/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import {
  EnableTwoFACodeRequestPayload,
  EnableTwoFACodeSuccessPayload,
  EnableTwoFASuccessPayload,
} from '../models/two-factor-authentication.models';

export enum TwoFactorAuthenticationActions {
  TWO_FA_REQUEST = '[settings] Requesting enabling 2FA',
  TWO_FA_SUCCESS = '[settings] 2FA request succeeded',
  TWO_FA_CODE_REQUEST = '[settings] Submiting 2FA code for confirmation',
  TWO_FA_CODE_SUCCESS = '[settings] 2FA code submit succeeded',
  TWO_FA_CODE_ERROR= '[settings] 2FA code has validation errors',
  TWO_FA_REMOVAL_REQUEST = '[settings] Requesting 2FA removal',
  TWO_FA_REMOVAL_SUCCESS = '[settings] 2FA was removed',
}

export class EnableTwoFARequest implements Action {
  public readonly type = TwoFactorAuthenticationActions.TWO_FA_REQUEST;
}

export class EnableTwoFASuccess implements Action {
  public readonly type = TwoFactorAuthenticationActions.TWO_FA_SUCCESS;
  constructor(public payload: EnableTwoFASuccessPayload) {}
}

export class EnableTwoFACodeRequest implements Action {
  public readonly type = TwoFactorAuthenticationActions.TWO_FA_CODE_REQUEST;
  constructor(public payload: EnableTwoFACodeRequestPayload) {}
}

export class EnableTwoFACodeSuccess implements Action {
  public readonly type = TwoFactorAuthenticationActions.TWO_FA_CODE_SUCCESS;
  constructor(public payload: EnableTwoFACodeSuccessPayload) {}
}

export class EnableTwoFACodeError implements Action {
  public readonly type = TwoFactorAuthenticationActions.TWO_FA_CODE_ERROR;
  constructor(public payload: string) {}
}

export class DisableTwoFARequest implements Action {
  public readonly type = TwoFactorAuthenticationActions.TWO_FA_REMOVAL_REQUEST;
}

export class DisableTwoFASuccess implements Action {
  public readonly type = TwoFactorAuthenticationActions.TWO_FA_REMOVAL_SUCCESS;
}

export type TwoFactorAuthenticationAction =
  EnableTwoFARequest |
  EnableTwoFASuccess |
  EnableTwoFACodeRequest |
  EnableTwoFACodeSuccess |
  EnableTwoFACodeError |
  DisableTwoFARequest |
  DisableTwoFASuccess;
