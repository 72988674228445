import { ActionReducer } from '@ngrx/store';
import { AppState } from '../../../app/states/models/app.models';

/**
 * Validates state and action payload whether it can be stringified.
 * In order to successfully transfer state from server to browser JSON.stringify(state) must work.
 */
export function validateStateReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state: AppState, action: any): AppState => {
    try {
      JSON.stringify(state);
    } catch (err) {
      throw new Error('Unable to stringify state.');
    }

    if (action.payload) {
      try {
        JSON.stringify(action.payload);
      } catch (err) {
        throw new Error(`Unable to stringify payload of action '${action.type}'.`);
      }
    }

    return reducer(state, action);
  };
}
