import { Action } from '@ngrx/store';

import { MytitleError } from 'src/app/client/models/client.models';
import { DocumentActivityResponse } from 'src/app/client/models/mytitle-service.models';
import {
  DocumentActivityAction,
  DocumentActivityActions,
} from '../actions/document-activity.actions';

export interface DocumentActivityState {
  documentActivity: DocumentActivityResponse[];
  isActivityLoading: boolean;
  activityError: MytitleError[];
}

export const documentActivityInitialState: DocumentActivityState = {
  documentActivity: [],
  isActivityLoading: false,
  activityError: [],
};

export function documentActivityReducer(
  state = documentActivityInitialState,
  generic: Action,
): DocumentActivityState {

  const action = generic as DocumentActivityAction;
  switch (action.type) {

    case DocumentActivityActions.GET_FILE_ACTIVITY: {
      return {
        ...state,
        isActivityLoading: true,
      };
    }

    case DocumentActivityActions.GET_FILE_ACTIVITY_SUCCESS: {
      return {
        ...state,
        isActivityLoading: false,
        documentActivity: action.payload,
      };
    }

    case DocumentActivityActions.GET_FILE_ACTIVITY_ERROR: {
      return {
        ...state,
        activityError: action.payload,
        isActivityLoading: false,
      };
    }

    case DocumentActivityActions.GET_FOLDER_ACTIVITY: {
      return {
        ...state,
        isActivityLoading: true,
      };
    }

    case DocumentActivityActions.GET_FOLDER_ACTIVITY_SUCCESS: {
      return {
        ...state,
        isActivityLoading: false,
        documentActivity: action.payload,
      };
    }

    case DocumentActivityActions.GET_FOLDER_ACTIVITY_ERROR: {
      return {
        ...state,
        activityError: action.payload,
        isActivityLoading: false,
      };
    }

    case DocumentActivityActions.ACTIVITY_DIALOG_CLOSED: {
      return documentActivityInitialState;
    }

    default: return state;
  }
}
