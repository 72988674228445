import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BillingServiceClient } from './services/billing-service.client';
import { MytitleServiceClient } from './services/mytitle-service.client';
import { RenderServiceClient } from './services/render-service.client';
import { UsersServiceClient } from './services/users-service.client';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    BillingServiceClient,
    MytitleServiceClient,
    RenderServiceClient,
    UsersServiceClient,
  ],
})
export class ClientModule {}
