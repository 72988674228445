import { Component } from '@angular/core';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { UniversalService } from 'src/app/shared/universal/universal.service';

@Component({
  selector: 'mt-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent {
  constructor(
    private enviService: EnvironmentService,
    private uniService: UniversalService,
  ) { }

  public getEntrepreneurArticle() {
    if (!this.uniService.isPlatformServer()) {
      if (this.enviService.isCzechEnvironment) {
        return 'https://mytitle.cz/blog/podnikatele-chrante-sve-intelektualni-vlastnictvi';
      }
    }
    return 'https://mytitle.com/blog/to-all-the-entrepreneurs';
  }

  public getDesignerArticle() {
    if (!this.uniService.isPlatformServer()) {
      if (this.enviService.isCzechEnvironment) {
        return 'https://mytitle.cz/blog/grafici-budte-si-svym-vlastnim-panem';
      }
    }
    return 'https://mytitle.com/blog/what-gives-your-creations-and-designs-value';
  }

  public getMusicianArticle() {
    if (!this.uniService.isPlatformServer()) {
      if (this.enviService.isCzechEnvironment) {
        return 'https://mytitle.cz/blog/jsem-skladatel-ktery-si-nenecha-svou-praci-nekym-ukrast';
      }
    }
    return 'https://mytitle.com/blog/the-one-note-script-that-may-have-changed-your-life';
  }

  public getWriterArticle() {
    if (!this.uniService.isPlatformServer()) {
      if (this.enviService.isCzechEnvironment) {
        return 'https://mytitle.cz/blog/byt-spisovatelem-je-nelehke-a-prave-proto-chrante-svou-praci';
      }
    }
    return 'https://mytitle.com/blog/stories-protected';
  }

  public getDeveloperArticle() {
    if (!this.uniService.isPlatformServer()) {
      if (this.enviService.isCzechEnvironment) {
        return 'https://mytitle.cz/blog/opatrny-vyvojar-uspesny-vyvojar';
      }
    }
    return 'https://mytitle.com/blog/codes-worth-protecting';
  }

  public getBlog() {
    if (!this.uniService.isPlatformServer()) {
      if (this.enviService.isCzechEnvironment) {
        return 'https://mytitle.cz/blog/';
      }
    }
    return 'https://mytitle.com/blog/';
  }
}
