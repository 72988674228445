export interface User {
  fullName: string;
  isicNumber: string;
  isicExpiration: Date;
  isValidAcademy: boolean;
  email: string;
  country: string;
  is_eu: boolean;
  two_factor_auth: boolean;
  isInLoginProcess?: boolean;
  affiliate_code: string;
}

export interface LimitExceeded {
  isLimitExceeded: boolean;
  wasWarningShown: boolean;
  freeDocuments: number;
}

export enum UserCurrencyChange {
  FROM_USD_TO_EUR = 'Based on your account settings currency has been changed from USD to EUR.',
  FROM_EUR_TO_USD = 'Based on your account settings currency has been changed from EUR to USD.',
}

export enum LoginErrorsEnum {
  REGISTRATION_NOT_FINISHED = 'REGISTRATION_NOT_FINISHED',
  NO_ACTIVE_ACCOUNT = 'NO_ACTIVE_ACCOUNT',
  TWO_FA_CODE_MISSING = '2FA_CODE_MISSING',
  TWO_FA_CODE_INCORRECT = '2FA_CODE_INCORRECT',
  ALREADY_REGISTERED_WITH_GOOGLE = 'USER_REGISTERED_WITH_GOOGLE',
  GOOGLE_AUTH_FAILED = 'GOOGLE_AUTH_FAILED',
}

export enum ResendVerificationCodeErrorsEnum {
  EMAIL_SENT_RECENTLY = 'EMAIL_SENT_RECENTLY',
  EMAIL_ALREADY_VERIFIED = 'EMAIL_ALREADY_VERIFIED',
  NO_ACTIVE_ACCOUNT = 'NO_ACTIVE_ACCOUNT',
}
