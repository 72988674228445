import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, first, map, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { getRouterState } from 'src/app/app/states/selectors/router.selectors';
import { Login } from 'src/app/auth/store/actions/login.action';
import { UsersServiceClient } from 'src/app/client/services/users-service.client';
import { getLoginParams } from 'src/app/pages/login/store/login.selector';
import {
  VerifyEmail,
  VerifyEmailActions,
  VerifyEmailError,
  VerifyEmailSuccess,
} from '../actions/verifyEmail.action';
import { getAuthParams } from '../auth.utils';
import { getRegisterState } from '../selectors/register.selector';

@Injectable()
export class VerifyEmailEffects {

  /**
   * Verify user email address with activation code agains BE
   */

  public verifyEmail = createEffect(() => this.storeActions.pipe(
    ofType<VerifyEmail>(VerifyEmailActions.VERIFY_EMAIL),
    switchMap(action => forkJoin([
        of(action),
        this.store.select(getRegisterState).pipe(first()),
        this.store.select(getLoginParams).pipe(first()),
      ])),
    switchMap(([action, registerState, loginParams]) => this.usersServiceClient
      .verifyEmail({
        code: action.payload.code,
        email: registerState.email || loginParams.email,
      }).pipe(
        map(() => new VerifyEmailSuccess()),
        catchError((error: HttpErrorResponse) => of(new VerifyEmailError(error.error))),
      ),
    ),
  ));


  public readonly redirectOnVerifyEmailSuccess = createEffect(() => this.storeActions.pipe(
    ofType<VerifyEmailSuccess>(VerifyEmailActions.VERIFY_EMAIL_SUCCESS),
    switchMapTo(this.store.pipe(
      select(getRouterState),
      first(),
    )),
    // all the cases when the fileFlow=True are handled in file-routing.effects.ts
    // all the cases when the creditFlow=True are handled in order-credit.effects.ts
    filter(state =>
      state.url.includes(AppRoutes.EmailConfirmation) &&
      !state.queryParams.fileFlow &&
      !state.queryParams.creditFlow),
    tap(() => this.router.navigate(['/'])),
  ), { dispatch: false });

  /**
   * Logins after an email verification
   */

  public loginAfterRegistration = createEffect(() => this.storeActions.pipe(
    ofType<VerifyEmailSuccess>(VerifyEmailActions.VERIFY_EMAIL_SUCCESS),
    switchMap(() => forkJoin([
        this.store.select(getRegisterState).pipe(first()),
        this.store.select(getLoginParams).pipe(first()),
      ])),
    map(([registerParams, loginParams]) => {
      const params = getAuthParams(registerParams, loginParams, 'VerifyEmailSuccess');
      return new Login(params);
    }),
  ));

  constructor(
    private router: Router,
    private storeActions: Actions,
    private store: Store<any>,
    private usersServiceClient: UsersServiceClient,
  ) {
  }
}
