import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  EMPTY,
  MonoTypeOperatorFunction,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { ErrorRoutes } from 'src/app/pages/error/error.routes.misc';

@Injectable({
  providedIn: 'root',
})
export class ErrorHelperService {

  constructor(
    private errorHandler: ErrorHandler,
    private router: Router,
  ) { }

  /**
   * Resolve any error by showing an error page
   * returning an empty observable.
   */
  public raiseError<T>(): MonoTypeOperatorFunction<T> {
    return source => source.pipe(
        catchError(err => {
          if (err && err.status !== 401) this.showError(err);
          return EMPTY;
        }),
      );
  }

  private showError(error: any) {
    this.errorHandler.handleError(error);
    this.router.navigate([AppRoutes.Error, ErrorRoutes.Unexpected]);
  }

}
