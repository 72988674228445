<section class="faq">
  <div class="container">
    <h2 class="text-center mb-5 faq__title"
        id="faq" i18n="@@faq">FAQ</h2>

    <mat-tab-group (selectedTabChange)="selectFaq($event)">
      <mat-tab *ngFor="let tab of tabs" [label]="tab"></mat-tab>
    </mat-tab-group>
        <mat-accordion multi>
          <mat-expansion-panel *ngFor="let faq of items">
            <mat-expansion-panel-header>
              <mat-panel-title class="question">
                {{ faq.question }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <span class="answer">
            {{ faq.answer }}
            </span>
          </mat-expansion-panel>
        </mat-accordion>

        <div class="faq__claim">
          <h4 class="faq__claim--blue" i18n="@@faqClaimNotFound">Didn't find the answer you were looking for?</h4>
          <h4><span i18n="@@faqClaimAsk">Ask us directly at </span>
            <a class="faq__claim--link"
               href="mailto:support@mytitle.com">support@mytitle.com</a>.
          </h4>
        </div>
  </div>
</section>
