import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MultilingualMetaTag } from 'src/app/shared/models/meta-tag.models';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MetaTagService {

  constructor(
    private title: Title,
    private meta: Meta,
    private enviService: EnvironmentService,
  ) { }
  public updateMultilingualTitleTag(title: MultilingualMetaTag) {
    if (title && title[environment.language]) {
      this.title.setTitle(title[environment.language]);
      this.meta.updateTag({ property: 'og:title', content: title[environment.language] });
    }
  }

  public updateOgUrlTag(url: string) {
    let ogUrl = environment.apiUrl;
    if (url) ogUrl += `/${url}`;
    this.meta.updateTag({ property: 'og:url', content: ogUrl });
  }

  public updateOgImageTag(imageUrl: string) {
    this.meta.updateTag({ property: 'og:image', content: imageUrl });
  }

  public updateMultilinguaDescriptionTag(description: MultilingualMetaTag) {
    if (description && description[environment.language]) {
      this.meta.updateTag({
        name: 'description',
        content: description[environment.language],
      });
      this.meta.updateTag({
        property: 'og:description',
        content: description[environment.language],
      });
    }
  }

  public updateOgLocaleTag() {
  if (this.enviService.isCzechEnvironment) {
      this.meta.updateTag({ property: 'og:locale', content: 'cs_CZ' });
    } else {
      this.meta.updateTag({ property: 'og:locale', content: 'en_US' });
    }
  }
}
