// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';

import { GoogleAuthParams, GoogleErrorResponse } from 'src/app/auth/auth.models';
import { TokenInfo } from 'src/app/auth/store/models/tokenInfo';
import { MytitleError } from 'src/app/client/models/client.models';
import { GoogleRegistrationStartParams } from 'src/app/client/models/users.modules';

export enum GoogleAuthActions {
  GOOGLE_AUTH_START = '[auth] Google authentication start',
  GOOGLE_AUTH_SUCCESS = '[auth] Google authentication success',
  GOOGLE_AUTH_ERROR = '[auth] Google authentication error',

  GOOGLE_SIGNUP = '[auth] Google signup',
  GOOGLE_SIGNUP_CANCEL = '[auth] Google signup cancel',
  GOOGLE_AUTH_CLEANUP = '[auth] Google auth cleanup',

  GOOGLE_LOGIN_START = '[auth] Google login start',
  GOOGLE_LOGIN_SUCCESS = '[auth] Google login success',
  GOOGLE_LOGIN_ERROR = '[auth] Google login error',

  GOOGLE_REGISTER_START = '[auth] Google register start',
  GOOGLE_REGISTER_SUCCESS = '[auth] Google register success',
  GOOGLE_REGISTER_ERROR = '[auth] Google register error',

  SAVE_CAMPAIGN_CODE = '[auth] Google save campaign code',
  SAVE_AFFILIATE_CODE = '[auth] Google save affiliate code',
}

export class GoogleAuthStart implements Action {
  public readonly type = GoogleAuthActions.GOOGLE_AUTH_START;
}

export class GoogleAuthSuccess implements Action {
  public readonly type = GoogleAuthActions.GOOGLE_AUTH_SUCCESS;
  constructor(public payload: GoogleAuthParams) {}
}

export class GoogleAuthError implements Action {
  public readonly type = GoogleAuthActions.GOOGLE_AUTH_ERROR;
  constructor(public payload: GoogleErrorResponse) {}
}

export class GoogleSignUp implements Action {
  public readonly type = GoogleAuthActions.GOOGLE_SIGNUP;
}

export class GoogleSignUpCancel implements Action {
  public readonly type = GoogleAuthActions.GOOGLE_SIGNUP_CANCEL;
}

export class GoogleAuthCleanup implements Action {
  public readonly type = GoogleAuthActions.GOOGLE_AUTH_CLEANUP;
}

export class GoogleRegisterStart implements Action {
  public readonly type = GoogleAuthActions.GOOGLE_REGISTER_START;
  constructor(public payload: GoogleRegistrationStartParams) {}
}

export class GoogleRegisterSuccess implements Action {
  public readonly type = GoogleAuthActions.GOOGLE_REGISTER_SUCCESS;
}

export class GoogleRegisterError implements Action {
  public readonly type = GoogleAuthActions.GOOGLE_REGISTER_ERROR;
  constructor(public payload: MytitleError[]) {}
}

export class GoogleLoginStart implements Action {
  public readonly type = GoogleAuthActions.GOOGLE_LOGIN_START;
}

export class GoogleLoginSuccess implements Action {
  public readonly type = GoogleAuthActions.GOOGLE_LOGIN_SUCCESS;
  constructor(public payload: { token: TokenInfo }) {}
}

export class GoogleLoginError implements Action {
  public readonly type = GoogleAuthActions.GOOGLE_LOGIN_ERROR;
  constructor(public payload: MytitleError[]) { }
}

export class SaveCampaignCode implements Action {
  public readonly type = GoogleAuthActions.SAVE_CAMPAIGN_CODE;
  constructor(public payload: string) {}
}

export class SaveAffiliateCode implements Action {
  public readonly type = GoogleAuthActions.SAVE_AFFILIATE_CODE;
  constructor(public payload: string) {}
}

export type GoogleAuthAction =
  GoogleAuthStart |
  GoogleAuthSuccess |
  GoogleAuthError |
  GoogleAuthCleanup |
  GoogleSignUp |
  GoogleSignUpCancel |
  GoogleLoginStart |
  GoogleLoginSuccess |
  GoogleLoginError |
  GoogleRegisterStart |
  GoogleRegisterSuccess |
  GoogleRegisterError |
  SaveCampaignCode |
  SaveAffiliateCode;
