import { Injectable } from '@angular/core';

import { loadExternalScript } from 'src/app/app/utils/load-external-script';
import { UniversalService } from 'src/app/shared/universal/universal.service';
import { AvailableLanguageMutations } from 'src/environments/models/language.models';

export const GOPAY_PAYMENT_COUNTRIES: string[] = [
  AvailableLanguageMutations.CZECH,
  AvailableLanguageMutations.ENGLISH,
];

@Injectable()
export class GopayPaymentService {

  constructor(private universalService: UniversalService) {

    if (!this.universalService.isPlatformServer()) {
      if (location.hostname !== 'localhost' && !location.hostname.includes('dev')) {
        loadExternalScript('https://gate.gopay.cz/gp-gw/js/embed.js');
      } else {
        loadExternalScript('https://gw.sandbox.gopay.com/gp-gw/js/embed.js');
      }
    }

  }

  public triggerPaygate(gw_url: string) {
    (window as any)._gopay.checkout({ gatewayUrl: gw_url, inline: true });
  }

}
