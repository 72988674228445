import { Action } from '@ngrx/store';
import { MytitleError } from '../../../client/models/client.models';
import {
  ResetPasswordConfirmAction,
  ResetPasswordConfirmActions,
} from '../actions/reset-password-confirm.actions';
import { ResetPasswordAction, ResetPasswordActions } from '../actions/reset-password.actions';

export interface ResetPasswordState {
  resettingPassword: boolean;
  resetPasswordError: MytitleError[];

  resettingPasswordConfirm: boolean;
  resetPasswordConfirmError: MytitleError[];
}

export const initialResetPasswordState: ResetPasswordState = {
  resettingPassword: false,
  resetPasswordError: null,
  resettingPasswordConfirm: false,
  resetPasswordConfirmError: null,
};

export function resetPasswordReducer(
  state = initialResetPasswordState,
  generic: Action,
) {
  const action = generic as ResetPasswordAction
    | ResetPasswordConfirmAction;
  switch (action.type) {
    case ResetPasswordActions.RESET_PASSWORD: {
      return { ...state, resettingPassword: true, resetPasswordError: null };
    }
    case ResetPasswordActions.RESET_PASSWORD_SUCCESS: {
      return { ...state, resettingPassword: false, resetPasswordError: null };
    }
    case ResetPasswordActions.RESET_PASSWORD_ERROR: {
      return { ...state, resettingPassword: false, resetPasswordError: action.payload };
    }

    case ResetPasswordConfirmActions.RESET_PASSWORD_CONFIRM: {
      return { ...state, resettingPasswordConfirm: true, resetPasswordConfirmError: null };
    }
    case ResetPasswordConfirmActions.RESET_PASSWORD_CONFIRM_SUCCESS: {
      return { ...state, resettingPasswordConfirm: false, resetPasswordConfirmError: null };
    }
    case ResetPasswordConfirmActions.RESET_PASSWORD_CONFIRM_ERROR: {
      return {
        ...state,
        resettingPasswordConfirm: false,
        resetPasswordConfirmError: action.payload,
      };
    }
  }
  return state;
}
