// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';

import { ListDocumentsResponse } from 'src/app/client/models/mytitle-service.models';
import { ListDocumentsParams } from 'src/app/client/models/mytitle-service.models';

export enum SharedDocumentActions {
  UPDATE_SHARED_DOCUMENTS = '[Shared documents] Update shared documents',
  CLEAN_SHARED_DOCUMENTS = '[Shared documents] Cleaning shared documents',

  GET_SHARED_DOCUMENTS = '[Shared documents] Get all shared documents',
  GET_SHARED_DOCUMENTS_SUCCESS = '[Shared documents] Get all shared documents success',
}

export class GetSharedDocumentsStart implements Action {
  public readonly type = SharedDocumentActions.GET_SHARED_DOCUMENTS;
  constructor(public payload: ListDocumentsParams) {}
}

export class GetSharedDocumentsSuccess implements Action {
  public readonly type = SharedDocumentActions.GET_SHARED_DOCUMENTS_SUCCESS;
  constructor(public payload: ListDocumentsResponse) {}
}

export class CleanSharedDocuments implements Action {
  public readonly type = SharedDocumentActions.CLEAN_SHARED_DOCUMENTS;
}

export class UpdateSharedDocuments implements Action {
  public readonly type = SharedDocumentActions.UPDATE_SHARED_DOCUMENTS;
  constructor(public payload: { folderGuid: string; searchText: string }) {}
}

export type SharedDocumentAction =
  | GetSharedDocumentsStart
  | GetSharedDocumentsSuccess
  | UpdateSharedDocuments
  | CleanSharedDocuments;
