import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AppRoutes } from 'src/app/app/app.routes.misc';
import { GopayPaymentService } from 'src/app/app/services/gopay-payment.service';
import {
    CreateISICBuyingStandardError,
    CreateISICPaymentError,
    CreatePaymentError,
    CreatePaymentStart,
    CreatePaymentSuccess,
    PaymentActions,
} from 'src/app/app/states/actions/payment.actions';
import { BillingServiceClient } from 'src/app/client/services/billing-service.client';
import { Notifications } from 'src/app/shared/models/notifications';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Injectable()
export class PaymentEffects {

  public paymentCreate = createEffect(() => this.storeActions.pipe(
    ofType<CreatePaymentStart>(PaymentActions.CREATE_PAYMENT_START),
    switchMap(action => this.billingServiceClient.createPayment(action.payload).pipe(
      map(result => new CreatePaymentSuccess(result)),
      catchError((error: HttpErrorResponse) => of(new CreatePaymentError(error))),
    )),
  ));


  public triggerPaygate = createEffect(() => this.storeActions.pipe(
    ofType<CreatePaymentSuccess>(PaymentActions.CREATE_PAYMENT_SUCCESS),
    tap(action => {
      this.gopayPaymentService.triggerPaygate(action.payload.gopay.gw_url);
    }),
  ), { dispatch: false });


  public catchErrorPaygate = createEffect(() => this.storeActions.pipe(
    ofType<CreatePaymentError>(PaymentActions.CREATE_PAYMENT_ERROR),
    tap(() => {
      this.snackbarService.queueSnackBar(Notifications.PaygateUnexpected);
      this.router.navigate([AppRoutes.CreditBundleChoice]);
    }),
  ), { dispatch: false });

  public catchErrorISICPaygate = createEffect(() => this.storeActions.pipe(
    ofType<CreateISICPaymentError>(PaymentActions.CREATE_ISIC_PAYMENT_ERROR),
    tap(() => {
      this.snackbarService.queueSnackBar(Notifications.PaygateInvalidISIC);
      this.router.navigate([AppRoutes.Settings]);
    }),
  ), { dispatch: false });

  public catchErrorISICBuyingStandard = createEffect(() => this.storeActions.pipe(
    ofType<CreateISICBuyingStandardError>(PaymentActions.CREATE_ISIC_BUYING_STANDARD_ERROR),
    tap(() => {
      this.snackbarService.queueSnackBar(Notifications.PaygateISICBuyingStandard);
      this.router.navigate([AppRoutes.CreditBundleChoice]);
    }),
  ), { dispatch: false });


  constructor(
    @Optional() private gopayPaymentService: GopayPaymentService,
    private storeActions: Actions,
    private billingServiceClient: BillingServiceClient,
    private snackbarService: SnackbarService,
    private router: Router,
  ) {
  }
}
