import { Action, createFeatureSelector } from '@ngrx/store';

import { CreateFolderOrderError, FileOrderResponse } from 'src/app/client/models/order.models';
import { OrderFileAction, OrderFileActions } from '../actions/order-file.actions';

export interface FileOrderState {
  data: FileOrderResponse;
  loading: boolean;
  error: CreateFolderOrderError;
  highlight: boolean;
}

export const initialFileOrderState: FileOrderState = {
  data: null,
  loading: false,
  error: null,
  highlight: false,
};

export const fileOrderSelectorName = 'fileOrder';
export const getFileOrderStateSelector =
  createFeatureSelector<FileOrderState>(fileOrderSelectorName);

export function fileOrderReducer(
  state: FileOrderState = initialFileOrderState,
  generic: Action,
): FileOrderState {
  const action = generic as OrderFileAction;
  switch (action.type) {
    case OrderFileActions.CREATE_FILE_ORDER:
      return { ...state, loading: true, error: null };
    case OrderFileActions.FILE_ORDER_SUCCESS: {
      return { ...state, data: { ...state.data, ...action.payload }, loading: false };
    }
    case OrderFileActions.FILE_ORDER_ERROR:
      return { ...state, error: action.payload, loading: false };
    case OrderFileActions.HIGHLIGHT_UPDATE:
      return { ...state, highlight: action.payload };
    case OrderFileActions.CLEAR_FILE_ORDER:
      return initialFileOrderState;
    default:
      return state;
  }
}
