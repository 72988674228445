/**
 * My documents store module which is lazy loaded when my-documents page is opened
 */

import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import {
  documentActivityReducer,
  DocumentActivityState,
} from './reducers/document-activity.reducer';
import { folderReducer, FolderState } from './reducers/folder.reducer';
import { myDocumentsReducer, MyDocumentsState } from './reducers/my-documents.reducers';
import { sharedDocumentsReducer, SharedDocumentsState } from './reducers/shared-document.reducer';
import { sharedFoldersReducer, SharedFoldersState } from './reducers/shared-folder.reducer';
import { sharingsReducer, SharingsState } from './reducers/sharing.reducer';

export const myDocumentsLazyStoreName = 'my-documents-lazy';

export interface MyDocumentsLazyState {
  documentActivity: DocumentActivityState;
  sharings: SharingsState;
  sharedDocuments: SharedDocumentsState;
  sharedFolders: SharedFoldersState;
  myDocuments: MyDocumentsState;
  folder: FolderState;
}

export const myDocumentsLazyReducers: ActionReducerMap<MyDocumentsLazyState> = {
  documentActivity: documentActivityReducer,
  sharings: sharingsReducer,
  sharedDocuments: sharedDocumentsReducer,
  sharedFolders: sharedFoldersReducer,
  myDocuments: myDocumentsReducer,
  folder: folderReducer,
};

export const getMyDocumentsLazyState =
  createFeatureSelector<MyDocumentsLazyState>(myDocumentsLazyStoreName);
