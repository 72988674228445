import { Action } from '@ngrx/store';
import { CreditConfig } from 'src/app/client/models/credit.models';
import {
  CreditBundleChoiceAction,
  CreditBundleChoiceActions,
} from '../actions/credit-bundle-choice.actions';
import {
  OrderCreditAction,
  OrderCreditActions,
} from '../actions/order-credit.actions';
import {
  PaymentAction,
  PaymentActions,
} from '../actions/payment.actions';

export interface CreditBundleChoiceState {
  bundles: CreditConfig[];
  isLoadingBundles: boolean;
  isCreatingOrder: boolean;
  hasPricingChanged: boolean;
  selectedBundle: string;
  isBundleAcademy: boolean;
  priceRange: string[];
  voucherCode: string;
}

export const initialCreditBundleChoiceState: CreditBundleChoiceState = {
  bundles: null,
  isLoadingBundles: null,
  isCreatingOrder: null,
  hasPricingChanged: null,
  selectedBundle: null,
  isBundleAcademy: null,
  priceRange: null,
  voucherCode: null,
};

export function creditBundleChoiceReducer(
  state = initialCreditBundleChoiceState,
  generic: Action): CreditBundleChoiceState {

  const action = generic as
    CreditBundleChoiceAction | OrderCreditAction | PaymentAction;

  switch (action.type) {
    case CreditBundleChoiceActions.GET_PRICE_RANGE_SUCCESS: {
      return {
        ...state,
        priceRange: action.payload,
      };
    }

    case CreditBundleChoiceActions.GET_CREDIT_BUNDLES: {
      return {
        ...state,
        isLoadingBundles: true,
      };
    }

    case CreditBundleChoiceActions.GET_CREDIT_BUNDLES_SUCCESS: {
      return {
        ...state,
        isLoadingBundles: false,
        bundles: action.payload.results,
      };
    }

    case CreditBundleChoiceActions.GET_CREDIT_ORDER_SUMMARY: {
      return {
        ...state,
        selectedBundle: action.payload.config_guid,
      };
    }

    case CreditBundleChoiceActions.USER_PRICING_CHANGED: {
      return {
        ...state,
        hasPricingChanged: true,
      };
    }

    case CreditBundleChoiceActions.REPEAT_CREDIT_CHOICE: {
      return {
        ...state,
        isCreatingOrder: false,
      };
    }

    case OrderCreditActions.QUEUE_CREDIT_ORDER:
    case OrderCreditActions.CREATE_CREDIT_ORDER: {
      return {
        ...state,
        isCreatingOrder: true,
        hasPricingChanged: false,
        voucherCode: action.payload.voucher_code,
        isBundleAcademy: action.payload.is_academy,
      };
    }

    case PaymentActions.CREATE_PAYMENT_SUCCESS: {
      return {
        ...state,
        isCreatingOrder: true,
      };
    }
    case PaymentActions.CREATE_PAYMENT_ERROR: {
      return {
        ...state,
        isCreatingOrder: false,
      };
    }
  }

  return state;
}
