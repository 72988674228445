<section class="pricing">
  <div>
    <div class="pricing__heading">
      <h2 id="pricing" class="text-center section-title" i18n="@@pricing">Pricing</h2>
      <p class="pl-2 pr-2">
        <span i18n="@@pricingTitleOne">Get your certificate for only</span>
        <span> {{ oneCredit }}. </span>
        <span i18n="@@pricingTitleTwo">Protect your intellectual property or valuable files.</span>
      </p>
      <p class="pricing__description" i18n="@@pricingSubtitle">Check out our price packages</p>
    </div>
    <div *ngIf="bundles">
      <div class="pricing__row">
        <div class="pricing__wrapper">
          <div class="mx-3 text-center" *ngIf="!limitExceededState.isLimitExceeded">
            <p class="mat-tab-label" i18n="@@trial">Trial</p>
            <div class="pricing__trial-card">
              <div class="pricing__inner-box">
                <img src="assets/pricing/free-circle.svg" alt="">
                <div class="pricing__free"
                     [ngClass]="{'pricing__free--czech' : enviService.isCzechEnvironment }">
                  <mt-drop-zone class="card-action-button full-width"
                                (select)="this.fileAdded($event, true)">
                    <p class="mb-1" i18n="@@tryFor">Try for</p>
                    <p class="mb-0 pricing__uppercase" i18n="@@free">Free</p>
                  </mt-drop-zone>
                </div>
                <div class="d-flex justify-content-center">
                  <mt-drop-zone class="card-action-button full-width"
                                id="track-free-button"
                                (select)="this.fileAdded($event, true)">
                    <button class="pricing__button" i18n="@@uploadNow">Upload now</button>
                  </mt-drop-zone>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-4" *ngIf="!limitExceededState.isLimitExceeded">
              <div>
                <div class="pricing__pair">
                  <img src="assets/pricing/plus-icon.svg" alt="">
                  <p class="pricing__pro-text"><strong i18n="@@free">Free</strong></p>
                </div>
                <div class="pricing__pair">
                  <img src="assets/pricing/point-icon.svg" alt="" class="pricing__free-point">
                  <p class="pricing__pro-text">
                    <span i18n="@@upTo">Up to</span>
                    <span><strong> 50 MB </strong></span>
                    <span i18n="@@perFile">per file</span>
                  </p>
                </div>
                <div class="pricing__pair">
                  <img src="assets/pricing/point-icon.svg" alt="" class="pricing__free-point">
                  <p class="pricing__pro-text">Litecoin Testnet blockchain</p>
                </div>
                <div class="pricing__pair">
                  <img src="assets/pricing/point-icon.svg" alt="" class="pricing__free-point">
                  <p class="pricing__pro-text">
                    <span i18n="@@encryptedFor">Encrypted backup for </span>
                    <span><strong i18n="@@threeMonths">3 months</strong></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
              <mat-tab-group (selectedTabChange)="filterBundles()" [(selectedIndex)]="selectedPricing" animationDuration="0ms" id="pricing-tab-group" class="center-content">
                <mat-tab *ngFor="let priceTable of pricingTables" [label]="priceTable">
                  <div class="pricing__pro-card">
                    <div
                         *ngFor="let bundle of filteredBundles; let i = index"
                         (mouseenter)="selectContainer(i)"
                         (click)="pickCreditBundle(bundle.guid)"
                         [ngClass]="{ 'selected' : isContainerSelected(i)}"
                         class="pricing__item">
                        <p class="pricing__offer-mobile" i18n="@@bestOffer" *ngIf="bestOfferCreditIndex === i && isSmallScreen">
                          Best offer</p>
                        <p class="pricing__item-text">{{ bundle.credits_count }}
                          <span *ngIf="bundle.credits_count === 1" i18n="@@credit">credit</span>
                          <span *ngIf="bundle.credits_count > 1" i18n="@@credits">credits</span>
                        </p>
                        <p
                          class="pricing__item-text">{{ removeDecimals(bundle.total_price) }} {{ getCurrencySign(bundle.currency) }}
                        </p>
                    </div>
                  </div>
                  <div class="pricing__offer" i18n="@@bestOffer" *ngIf="showBestOffer"
                     [ngClass]="{ 'hover' : showBestOffer, 'pricing__offer--czech' : enviService.isCzechEnvironment }">
                    Best offer
                  </div>
                </mat-tab>
              </mat-tab-group>
            <div class="pricing__double-pair mt-4">
              <div class="mr-sm-3 mr-0 pricing__text-wrapper">
                <div class="pricing__pair">
                  <img src="assets/pricing/plus-icon.svg" alt="">
                  <p class="pricing__pro-text"><strong>Litecoin blockchain</strong></p>
                </div>
                <div class="pricing__pair">
                  <img src="assets/pricing/plus-icon.svg" alt="">
                  <p class="pricing__pro-text">
                    <span i18n="@@upTo">Up to</span>
                    <span><strong> 500 MB </strong></span>
                    <span i18n="@@perFile">per file</span>
                  </p>
                </div>
                <div class="pricing__pair">
                  <img src="assets/pricing/plus-icon.svg" alt="">
                  <p class="pricing__pro-text">
                    <span i18n="@@encryptedFor">Encrypted backup for </span>
                    <span><strong i18n="@@threeYears">10 years</strong></span>
                  </p>
                </div>
                <div *ngIf="selectedPricing===1" class="pricing__pair">
                  <img src="assets/pricing/point-icon.svg" alt="" class="pricing__free-point">
                  <p class="pricing__pro-text" i18n="@@requiresValidISIC">Requires valid ISIC/ITIC card</p>
                </div>
              </div>
              <div class="pricing__text-wrapper">
                <div class="pricing__pair">
                  <img src="assets/pricing/plus-icon.svg" alt="">
                  <p class="pricing__pro-text" i18n="@@fullIdentification">Full owners identification</p>
                </div>
                <div class="pricing__pair">
                  <img src="assets/pricing/plus-icon.svg" alt="">
                  <p class="pricing__pro-text" i18n="@@priceCardFingerprint">Digital Fingerprint</p>
                </div>
                <div class="pricing__pair">
                  <img src="assets/pricing/plus-icon.svg" alt="">
                  <p class="pricing__pro-text" i18n="@@priceCardTimestamp">Blockchain Timestamp</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="pricing__icons">
    <div class="pricing__icons--inner">
      <h2 class="text-center section-title" i18n="@@pricingTypesTitle">Which files can you upload?</h2>
      <p i18n="@@pricingTypesSubtitle">
        Any digital content. Any formatted ideas, contracts, wills, financial documents, e-books, screenplays, articles,
        art design, mp3 and more.
        The options are endless.</p>
    </div>
  </div>
  <div class="icon-box">
    <img src="/assets/pricing/image-icon.svg" alt="image-icon" class="icon-box__item">
    <img src="/assets/pricing/code-icon.svg" alt="code-icon" class="icon-box__item">
    <img src="/assets/pricing/pdf-icon.svg" alt="pdf-icon" class="icon-box__item">
    <img src="/assets/pricing/excel-icon.svg" alt="excel-icon" class="icon-box__item">
    <img src="/assets/pricing/video-icon.svg" alt="video-icon" class="icon-box__item">
    <img src="/assets/pricing/psd-icon.svg" alt="psd-icon" class="icon-box__item">
    <img src="/assets/pricing/doc-icon.svg" alt="doc-icon" class="icon-box__item">
    <img src="/assets/pricing/camera-icon.svg" alt="camera-icon" class="icon-box__item">
  </div>
</section>
