import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, first, map, switchMap, switchMapTo, tap } from 'rxjs/operators';

import { AppRoutes } from 'src/app/app/app.routes.misc';
import { getRouterState } from 'src/app/app/states/selectors/router.selectors';
import { UsersServiceClient } from 'src/app/client/services/users-service.client';
import {
  Register,
  RegisterActions,
  RegisterCancel,
  RegisterClean,
  RegisterError,
  RegisterSuccess,
} from '../actions/register.action';
import { AuthState } from '../auth.reducers';

@Injectable()
export class RegisterEffects {

  /**
   * Registration of new user
   */

  public register = createEffect(() => this.storeActions.pipe(
    ofType<Register>(RegisterActions.REGISTER),
    switchMap(action => this.usersServiceClient.register(action.payload).pipe(
        map(() => new RegisterSuccess()),
        catchError((error: HttpErrorResponse) => of(new RegisterError(error.error))),
      ),
    ),
  ));

  /**
   * If user decides to start registration again / sign-in as another user
   * and leave confirmation screen, he will be redirected back to sign up
   */

  public readonly navigateOnCancelRegistration = createEffect(() => this.storeActions.pipe(
    ofType<RegisterCancel>(RegisterActions.REGISTER_CANCEL),
    switchMapTo(this.store.pipe(
      select(getRouterState),
      first(),
    )),
    tap(state => {
      // If user cancels registration during confirmation, he is redirected to sign-up page
      if (state.url.includes(AppRoutes.EmailConfirmation)) {
        if (state.queryParams.fileFlow) {
          this.router.navigate([AppRoutes.SignUp], { queryParams: { fileFlow: true } });
        } else if (state.queryParams.creditFlow) {
          this.router.navigate([AppRoutes.SignUp], { queryParams: { creditFlow: true } });
        } else {
          this.router.navigate([AppRoutes.SignUp]);
        }
        // Else he is redirected from sign-up page to login screen on registration cancel
      } else if (state.url.includes(AppRoutes.SignUp)) {
        if (state.queryParams.fileFlow) {
          this.router.navigate([AppRoutes.Login], { queryParams: { fileFlow: true } });
        } else if (state.queryParams.creditFlow) {
          this.router.navigate([AppRoutes.Login], { queryParams: { creditFlow: true } });
        } else {
          this.router.navigate([AppRoutes.Login]);
        }
      }
    }),
  ), { dispatch: false });


  public readonly cancelRegistration = createEffect(() => this.storeActions.pipe(
    ofType<RegisterCancel>(RegisterActions.REGISTER_CANCEL),
    map(() => new RegisterClean()),
  ));

  constructor(
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
    private store: Store<AuthState>,
    private router: Router,
  ) {
  }
}
