export function getFileCreditsValue(filesize: number, isAcademy: boolean): number {
  if (!isAcademy) return 1;
  const filesizeMB = filesize / 1048576;

  if (filesizeMB <= 1) return 1;
  if (filesizeMB <= 5) return 2;
  if (filesizeMB <= 20) return 3;
  if (filesizeMB <= 50) return 4;

  return 5;
}
