<section class="container">
  <h2 class="map__title" i18n="@@mapTitle">We help protect the world's intellectual property</h2>
  <div class="map">
    <img src="/assets/img/home/map.svg" alt="" class="map__map">

    <img src="/assets/img/home/large-pin.svg"
         alt=""
         class="map__large-pin"
         scrollAnimate
         animationName="bounceInTop"
         *ngFor="let number of largePinNumberArray"
         [ngClass]="[ 'map__large-pin--' + number ]">

    <img src="/assets/img/home/medium-pin.svg"
          alt=""
          class="map__medium-pin"
          *ngFor="let number of mediumPinNumberArray"
          [ngClass]="[ 'map__medium-pin--' + number ]"
          scrollAnimate
          animationName="bounceInTop">

    <img src="/assets/img/home/small-pin.svg"
          alt=""
          class="map__small-pin"
          scrollAnimate
          animationName="bounceInTop"
          *ngFor="let number of smallPinNumberArray"
          [ngClass]="[ 'map__small-pin--' + number ]">
  </div>
</section>
