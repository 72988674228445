import { createSelector } from '@ngrx/store';
import { getLayoutState } from '../reducers/layout.reducers';

export const getColorTheme =
  createSelector(getLayoutState, state => state.colorTheme);

export const displayFooterLogo =
  createSelector(getLayoutState, state => state.displayFooterLogo);

export const applyRouterOutletContainer =
  createSelector(getLayoutState, state => state.applyRouterOutletContainer);

export const initializeHeaderElement =
  createSelector(getLayoutState, state => state.initializeHeaderElement);
