import { Action, createFeatureSelector } from '@ngrx/store';
import { ChangeLayoutState, LayoutActions } from './../actions/layout.actions';

export type ColorTheme = 'azure' | 'white' | 'transparent';

export interface LayoutState {
  path: string[];
  colorTheme: ColorTheme;
  /* Puts content of your component into container class, if you are
  willing to have container wrapper with margins on sides, set this to true. */
  applyRouterOutletContainer: boolean;
  /* Displays logo on footer element */
  displayFooterLogo: boolean;
  /* If you want to declare you own <header> element in HTML structure,
  set this property to false. Otherwise <header> element will be
  instantiated together with navigation component. */
  initializeHeaderElement: boolean;
}

/* Removes hashes and query params from URL */
export function getPathFromUrl(url: string): string {
  return url.split('?')[0].split('#')[0];
}

export function getAppLayoutSettings(url: string): LayoutState {
  let routeLayoutState: LayoutState;
  // Try to find defined template for given route
  routeLayoutState = routeLayoutStates.find(x => x.path.includes(getPathFromUrl(url)));
  // If given route has not specified template, use default one
  if (!routeLayoutState) routeLayoutState = routeLayoutStates.find(x => x.path.includes('default'));

  return routeLayoutState;
}

/**
 * If you want to have specific template on your Route, please specify
 * it here. Otherwise default templating will be used.
 */
const routeLayoutStates: LayoutState[] = [
  {
    // HOME page
    path: ['/'],
    colorTheme: 'transparent',
    applyRouterOutletContainer: false,
    displayFooterLogo: true,
    initializeHeaderElement: true,
  },
  {
    // ABOUT US page
    path: ['/about/us'],
    colorTheme: 'transparent',
    applyRouterOutletContainer: false,
    displayFooterLogo: true,
    initializeHeaderElement: true,
  },
  {
    // CONTACT page
    path: ['/about/contact'],
    colorTheme: 'transparent',
    applyRouterOutletContainer: false,
    displayFooterLogo: true,
    initializeHeaderElement: true,
  },
  {
    // DEFAULT
    path: ['default'],
    colorTheme: 'white',
    applyRouterOutletContainer: true,
    displayFooterLogo: false,
    initializeHeaderElement: true,
  },
];

export const initialLayoutState: LayoutState = routeLayoutStates.find(x => x.path.includes('/'));

export const layoutStateSelectorName = 'layout';
export const getLayoutState =
  createFeatureSelector<LayoutState>(layoutStateSelectorName);

export function layoutReducer(
  state: LayoutState = initialLayoutState,
  generic: Action): LayoutState {

  const action = generic as ChangeLayoutState;
  switch (action.type) {
    case LayoutActions.CHANGE_LAYOUT_STATE: {
      return action.payload;
    }

    default: return state;
  }
}
