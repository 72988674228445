/**
 * Enum of paths for app routes.
 */
export enum AppRoutes {
  About = 'about',
  CreditBundleChoice = 'security-pricing',
  DocumentCredentials = 'document-credentials',
  DocumentProgress = 'document-progress',
  FolderUploadConfirmation = 'folder-upload-confirmation',
  FolderUploadCredentials = 'folder-upload-credentials',
  FolderUploadProgress = 'folder-upload-progress',
  DeletionDocumentsConfirm = 'deletion',
  EmailConfirmation = 'email-confirmation',
  FAQ = 'faq',
  Error = 'error',
  Login = 'login',
  MyDocuments = 'my-documents',
  CreditOrderSummary = 'credit-order-summary',
  PaymentConfirmation = 'payment-confirmation',
  ResetPassword = 'reset-password-request',
  ResetPasswordSent = 'sent',
  ResetPasswordConfirm = 'reset-password',
  SignUp = 'sign-up',
  Verification = 'verification',
  Settings = 'settings',
  NotFound = '**',
  FolderCredentials = 'folder-credentials',
  WatermarkSelection = 'watermark',
}
