import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BillingServiceClient } from 'src/app/client/services/billing-service.client';
import {
  ValidateVoucher,
  ValidateVoucherError,
  ValidateVoucherSuccess,
  VoucherActions,
} from '../actions/voucher.actions';

@Injectable()
export class VoucherEffects {


  public readonly validateVoucher = createEffect(() => this.storeActions.pipe(
    ofType<ValidateVoucher>(VoucherActions.VALIDATE_VOUCHER),
    switchMap(action => this.billingServiceClient.validateVoucher(action.payload)
      .pipe(
        map(result => new ValidateVoucherSuccess(result)),
        catchError((error: HttpErrorResponse) => of(new ValidateVoucherError(error.error))),
      ),
    ),
  ));

  constructor(
    private storeActions: Actions,
    private billingServiceClient: BillingServiceClient,
  ) {
  }
}
