import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FileState } from '../models/file.models';

export const getFileState = createFeatureSelector<FileState>('file');
export const getDocument = createSelector(getFileState, state => state.document);
export const isUpgradingFile = createSelector(getFileState, state => state.isFileUpgrading);
export const upgradedFile = createSelector(getFileState, state => state.upgradingDocument);
export const getFileParentGuid = createSelector(getFileState, state => state.fileParentGuid);

export const isDeletingDocuments = createSelector(
  getFileState,
  state => state && state.finishingDocumentsDeleting,
);

export const getDeletedDocuments = createSelector(
  getFileState,
  state => state && state.deletedDocuments,
);

export const getVerifyDeletionError = createSelector(
  getFileState,
  state => state && state.error,
);

export const canBePatched = createSelector(
  getFileState,
  state => state && state.canBePatched,
);

export const getFile = createSelector(
  getFileState,
  state => state && state.file,
);
