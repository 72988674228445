/* eslint-disable max-classes-per-file */
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import {
  Address,
  CreateDocumentParams,
  Document,
  IDCard,
  PatchDocumentRequest,
} from 'src/app/client/models/mytitle-service.models';
import { FileOrderRequestParams, FileOrderResponse } from 'src/app/client/models/order.models';

export enum FileActions {
  FILE_UPDATED = '[file] file updated',
  ADDRESS_UPDATED = '[file] address updated',
  ID_CARD_UPDATED = '[file] id card updated',

  FILE_UPLOAD_START = '[file] upload start',
  FILE_UPLOAD_PROGRESS = '[file] upload progress',
  FILE_UPLOAD_SUCCESS = '[file] upload success',
  FILE_UPLOAD_ERROR = '[file] upload error',

  FILE_UPGRADE = '[file] upgrade',
  FILE_UPGRADE_START = '[file] upgrade start',
  FILE_UPGRADE_SUCCESS = '[file] upgrade success',

  NOT_ENOUGH_CREDITS = '[file] not enough credits',
  SELECT_DOCUMENT_PRICE = '[file] select document price',
  PATCH_DOCUMENT = '[file] patch document start',
  PATCH_DOCUMENT_SUCCESS = '[file] patch document success',
  PATCH_DOCUMENT_ERROR = '[file] patch document error',
  CLEAR_FILE_STATE = '[file] clear file state',
}

export class FileUpdated implements Action {
  public readonly type = FileActions.FILE_UPDATED;
  constructor(public payload: {
    file: File;
    folder: string;
    is_free: boolean;
    uploadNow: boolean;
  }) { }
}

export class AddressUpdated implements Action {
  public readonly type = FileActions.ADDRESS_UPDATED;
  constructor(public payload: Address) { }
}

export class IDCardUpdated implements Action {
  public readonly type = FileActions.ID_CARD_UPDATED;
  constructor(public payload: IDCard) { }
}

export class FileUploadStart implements Action {
  public readonly type = FileActions.FILE_UPLOAD_START;
  constructor(public payload: CreateDocumentParams) { }
}

export class FileUploadProgress implements Action {
  public readonly type = FileActions.FILE_UPLOAD_PROGRESS;

  constructor(public payload: { percentDone: number }) { }
}

export class FileUploadSuccess implements Action {
  public readonly type = FileActions.FILE_UPLOAD_SUCCESS;
  constructor(public payload: Document) { }
}

export class FileUploadError implements Action {
  public readonly type = FileActions.FILE_UPLOAD_ERROR;
  constructor(public payload: HttpErrorResponse) { }
}

export class FileUpgrade implements Action {
  public readonly type = FileActions.FILE_UPGRADE;
  constructor(public payload: FileOrderRequestParams) { }
}

export class FileUpgradeStart implements Action {
  public readonly type = FileActions.FILE_UPGRADE_START;
  constructor(public payload: FileOrderRequestParams) { }
}

export class FileUpgradeSuccess implements Action {
  public readonly type = FileActions.FILE_UPGRADE_SUCCESS;
  constructor(public payload: FileOrderResponse) { }
}

export class SelectDocumentPrice implements Action {
  public readonly type = FileActions.SELECT_DOCUMENT_PRICE;
  constructor(public payload: { is_free: boolean }) { }
}

export class PatchDocument implements Action {
  public readonly type = FileActions.PATCH_DOCUMENT;
  constructor(public payload: PatchDocumentRequest) { }
}

export class PatchDocumentSuccess implements Action {
  public readonly type = FileActions.PATCH_DOCUMENT_SUCCESS;
  constructor(public payload: Document) { }
}

export class PatchDocumentError implements Action {
  public readonly type = FileActions.PATCH_DOCUMENT_ERROR;
  constructor(public payload: HttpErrorResponse) { }
}

export class ClearFileState implements Action {
  public readonly type = FileActions.CLEAR_FILE_STATE;
}

export class NotEnoughCredits implements Action {
  public readonly type = FileActions.NOT_ENOUGH_CREDITS;
}

export type FileAction = FileUpdated | AddressUpdated | IDCardUpdated |
  FileUploadStart | FileUploadSuccess | FileUploadError | FileUpgrade |
  FileUpgradeStart | FileUpgradeSuccess | SelectDocumentPrice | FileUploadProgress|
  PatchDocument | PatchDocumentSuccess |
  PatchDocumentError| ClearFileState | NotEnoughCredits;
