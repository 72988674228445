import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { first, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { getIsAuthorized, limitExceededState } from 'src/app/auth/store/selectors/user.selector';
import { Notifications } from 'src/app/shared/models/notifications';
import { LimitExceeded } from '../../../auth/store/models/user';
import { SnackbarService } from '../../../shared/services/snackbar.service';
import { FileAction, FileActions, SelectDocumentPrice } from '../actions/file.action';
import { AppState } from '../models/app.models';
import { FileState } from '../models/file.models';
import { getFileState } from '../selectors/file.selectors';
import { isWatermarkAvailable } from '../../utils/file-extensions';

@Injectable()
export class FileRoutingEffects {

  public navigateAfterFileSelect = createEffect(() => this.storeActions.pipe(
    ofType(FileActions.FILE_UPDATED),
    tap(action => {
      if (isWatermarkAvailable(action.payload.file)) {
        this.router.navigate([AppRoutes.WatermarkSelection]);
      } else {
        this.router.navigate([AppRoutes.DocumentCredentials]);
      }
    }),
  ), {dispatch: false});

  public readonly navigateAfterPriceSelect = createEffect(() => this.storeActions.pipe(
    ofType(FileActions.SELECT_DOCUMENT_PRICE),
    switchMap(action => this.store.pipe(
      select(getIsAuthorized),
      takeWhile(authorized => !authorized, true),
      map(authorized => [action, authorized] as [SelectDocumentPrice, boolean])),
    ),
    switchMap(([action, authorized]) => this.store.pipe(
      select(limitExceededState),
      first(),
      map(exceeded => [action, authorized, exceeded] as
        [SelectDocumentPrice, boolean, LimitExceeded])),
    ),
    switchMap(([action, authorized, limitExceeded]) => this.store.pipe(
      select(getFileState),
      first(),
      map(file => [action, authorized, limitExceeded, file] as
        [SelectDocumentPrice, boolean, LimitExceeded, FileState])),
    ),
    tap(([action, authorized, limitExceeded, file]) => {
      if (authorized && file && file.file) {
        if (action.payload.is_free === true) {
          if (limitExceeded.isLimitExceeded) {
            this.snackbarService.queueSnackBar(Notifications.LimitExceeded,
              {triggerAction: 'FREE_LIMIT_WARNING_SHOWN'},
            );
            this.router.navigate([AppRoutes.DocumentProgress]);
          } else if (isWatermarkAvailable(file.file)) {
            this.router.navigate([AppRoutes.WatermarkSelection]);
          }
        } else {
          if (isWatermarkAvailable(file.file)) {
            this.router.navigate([AppRoutes.WatermarkSelection]);
          } else this.router.navigate([AppRoutes.DocumentCredentials]);
        }
      } else {
        this.router.navigate(
          [AppRoutes.SignUp],
          {queryParams: {fileFlow: true}},
        );
      }
    }),
  ), {dispatch: false});


  public readonly navigateAfterPatchedDocument = createEffect(() => this.storeActions.pipe(
    ofType(FileActions.PATCH_DOCUMENT),
    map(() => [AppRoutes.DocumentProgress]),
    tap(url => this.router.navigate(url)),
  ), {dispatch: false});

  constructor(
    private storeActions: Actions<FileAction>,
    private store: Store<AppState>,
    private router: Router,
    private snackbarService: SnackbarService,
  ) {
  }
}
