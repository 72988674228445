/* eslint-disable max-classes-per-file */

import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';

export enum VerifyEmailActions {
  VERIFY_EMAIL = '[Auth] Verify Email',
  VERIFY_EMAIL_SUCCESS = '[Auth] Verify Email Success',
  VERIFY_EMAIL_ERROR = '[Auth] Verify Email Error',
}

export class VerifyEmail implements Action {
  public readonly type = VerifyEmailActions.VERIFY_EMAIL;
  constructor(public payload: { code: string }) { }
}

export class VerifyEmailSuccess implements Action {
  public readonly type = VerifyEmailActions.VERIFY_EMAIL_SUCCESS;
}

export class VerifyEmailError implements Action {
  public readonly type = VerifyEmailActions.VERIFY_EMAIL_ERROR;
  constructor(public payload: MytitleError) { }
}

export type VerifyEmailAction = VerifyEmail | VerifyEmailSuccess | VerifyEmailError;
