import { createSelector } from '@ngrx/store';
import { getMyDocumentsLazyState } from '../my-documents.reducers';

export const getFolderState = createSelector(
  getMyDocumentsLazyState,
  state => state && state.folder,
);

export const getUpgradingFolder = createSelector(
  getFolderState,
  state => state && state.folder,
);

export const getFolderCheck = createSelector(
  getFolderState,
  state => state.folderCheck,
);

export const getFolderBeingMytitled = createSelector(
  getFolderState,
  state => state.folderBeingUpdated,
);
