import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Notifications } from 'src/app/shared/models/notifications';
import { SnackbarService } from '../../shared/services/snackbar.service';

@Component({
  selector: 'mt-drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => DropZoneComponent),
      multi: true,
    },
  ],
})
export class DropZoneComponent implements ControlValueAccessor {
  @ViewChild('fileInput', {static: false})
  public fileInput: ElementRef<HTMLInputElement>;

  public selectedFile: File;

  public isFileOver: boolean;

  /**
   * Emmits File when selected
   */
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  public select = new EventEmitter<File>();

  @Output()
  public isDragging = new EventEmitter<boolean>();

  @Input()
  private clickable = true;

  // ControlValueAccessor filled
  private onChangeHandler: (file: File) => void;
  private onTouchedHandler: () => void;

  constructor(
    private snackbarService: SnackbarService,
  ) {
  }

  @HostBinding('class.dragover')
  public get classDragover() {
    return this.isFileOver;
  }

  @HostBinding('class.selected')
  public get classSelected() {
    return !!this.selectedFile;
  }

  @HostListener('dragover', ['$event'])
  public onDragover(event: Event) {
    this.isFileOver = true;
    (event as DragEvent).dataTransfer.dropEffect = 'link';
    event.preventDefault();
    event.stopPropagation();
    this.isDragging.emit(this.isFileOver);
  }

  @HostListener('dragenter', ['$event'])
  public onDragenter(event: Event) {
    this.isFileOver = true;
    (event as DragEvent).dataTransfer.dropEffect = 'link';
    event.preventDefault();
    event.stopPropagation();
    this.isDragging.emit(this.isFileOver);
  }

  @HostListener('dragleave', ['$event'])
  public onDragleave(event: Event) {
    this.isFileOver = false;
    this.isDragging.emit(false);
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: Event) {
    this.fireTouched();
    this.isFileOver = false;
    this.isDragging.emit(this.isFileOver);
    event.preventDefault();
    event.stopPropagation();
    const item = (event as DragEvent).dataTransfer.items[0];
    try {
      if (item && item.webkitGetAsEntry().isFile) {
        this.fileChanged(item.getAsFile());
      }
      // TODO: This is attepted browser compatibility solution. Check webkitGetAsEntry availibility
      // instead.
    } catch (e) {
      this.fileChanged((event as DragEvent).dataTransfer.files[0]);
      // TODO: Create `asyncError` utility function
      setTimeout(() => {
        throw e;
      });
    }
  }

  //// ControlValueAccessor

  public writeValue(file: any): void {
    if (file instanceof File) {
      this.selectedFile = file;
    } else {
      this.selectedFile = null;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChangeHandler = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedHandler = fn;
  }

  //// ControlValueAccessor end

  /**
   * Callback for when file changes
   */
  public fileChanged(file: File) {
    if (file) {
      if (file.size === 0) {
        this.snackbarService.queueSnackBar(Notifications.FileEmpty);
        return;
      } else if (file.size > 5e8) {
        this.snackbarService.queueSnackBar(Notifications.MaxSize);
        return;
      }
      this.selectedFile = file;
      this.select.emit(file);
      if (this.onChangeHandler) {
        this.onChangeHandler(file);
      }
    }
    this.fileInput.nativeElement.value = null;
  }

  /**
   * Opens a system dialog to select file.
   */
  public selectFile() {
    this.fireTouched();
    this.fileInput.nativeElement.click();
  }

  public clicked(event: Event) {
    event.stopPropagation();
    if (this.clickable) this.selectFile();
  }

  private fireTouched() {
    if (this.onTouchedHandler) {
      this.onTouchedHandler();
    }
  }

}
