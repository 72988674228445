import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { VideoDialogComponent } from './video-dialog.component';

@NgModule({
    declarations: [
        VideoDialogComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
    ],
    exports: [
        VideoDialogComponent,
    ],
})
export class VideoDialogModule {}
