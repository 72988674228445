import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FileUploadingStatus, UploadFolderState } from './../reducers/upload-folder.reducer';

export const uploadFolderStateSelectorName = 'uploadFolder';

export const uploadFolderState = createFeatureSelector<UploadFolderState>(
  uploadFolderStateSelectorName);

export const areAllFoldersCreated = createSelector(
  uploadFolderState,
  state => {
    if (state.foldersCreated && state.totalFolders) {
      return state.foldersCreated === state.totalFolders;
    } else return false;
  },
);

export const getFolderFiles = createSelector(
  uploadFolderState,
  state => state.files,
);

export const areAllFilesUploaded = createSelector(
  uploadFolderState,
  state => {
    if (!state.files) return null;

    for (const file of state.files) {
      if (file.uploadingStatus !== FileUploadingStatus.SUCCESS) return false;
    }

    return true;
  },
);

export const filesInErrorState = createSelector(
  uploadFolderState,
  state => {
    if (!state.files) return null;
    return state.files.filter(x => x.uploadingStatus === FileUploadingStatus.ERROR);
  },
);

export const filesInWaitingState = createSelector(
  uploadFolderState,
  state => {
    if (!state.files) return null;
    return state.files.filter(x => x.uploadingStatus === FileUploadingStatus.WAITING);
  },
);

export const numberOfCurrentlyUploadedFiles = createSelector(
  uploadFolderState,
  state => state.currentlyUploading,
);

export const hasFileUploadingStarted = createSelector(
  uploadFolderState,
  state => state.fileUploadingStarted,
);

export const getAllUploadedFiles = createSelector(
  uploadFolderState,
  state => state.uploadedFiles,
);

export const hasCreatingFileOrdersStarted = createSelector(
  uploadFolderState,
  state => state.creatingFileOrdersStarted,
);

export const hasFilePatchingStarted = createSelector(
  uploadFolderState,
  state => state.filePatchingStarted,
);

export const allFileOrdersCreated = createSelector(
  uploadFolderState,
  state => state.creatingFileOrdersFinished,
);

export const allFilesPatched = createSelector(
  uploadFolderState,
  state => state.uploadedFiles ? state.uploadedFiles.length === state.filesPatched : null,
);

export const canUploadFolderRouteBeActivated = createSelector(
  uploadFolderState,
  state => !!state.files && !!state.folders,
);

export const hasUploadingProcessStarted = createSelector(
  uploadFolderState,
  state => state.uploadingProcessStarted,
);

export const hasUploadingProcessFinished = createSelector(
  uploadFolderState,
  state => state.uploadingProcessFinished,
);

export const getParentFolderGuid = createSelector(
  uploadFolderState,
  state => state.parentGuid,
);

export const getFolderCredentials = createSelector(
  uploadFolderState,
  state => state.credentials,
);
