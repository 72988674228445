/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import { ForgotPasswordVerifyParams } from 'src/app/client/models/users.modules';

export enum ResetPasswordConfirmActions {
  RESET_PASSWORD_CONFIRM = '[Auth] Reset Password Confirm',
  RESET_PASSWORD_CONFIRM_SUCCESS = '[Auth] Reset Password Confirm Success',
  RESET_PASSWORD_CONFIRM_ERROR = '[Auth] Reset Password Confirm Error',
}

export class ResetPasswordConfirm implements Action {
  public readonly type = ResetPasswordConfirmActions.RESET_PASSWORD_CONFIRM;
  constructor(public payload: ForgotPasswordVerifyParams) { }
}

export class ResetPasswordConfirmSuccess implements Action {
  public readonly type = ResetPasswordConfirmActions.RESET_PASSWORD_CONFIRM_SUCCESS;
}

export class ResetPasswordConfirmError implements Action {
  public readonly type = ResetPasswordConfirmActions.RESET_PASSWORD_CONFIRM_ERROR;
  constructor(public payload: MytitleError[]) { }
}

export type ResetPasswordConfirmAction = ResetPasswordConfirm
  | ResetPasswordConfirmSuccess
  | ResetPasswordConfirmError;
