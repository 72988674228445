import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { combineLatest, forkJoin, of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  first,
  map,
  mapTo,
  switchMap,
  tap,
} from 'rxjs/operators';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { GetCreditBalance } from 'src/app/auth/store/actions/getCreditBalance.actions';
import {
  getCreditBalance,
  getIsAuthorized, getIsValidAcademy,
  limitExceededState,
} from 'src/app/auth/store/selectors/user.selector';
import { BillingServiceClient } from 'src/app/client/services/billing-service.client';
import { MytitleServiceClient } from 'src/app/client/services/mytitle-service.client';
import {
  DeleteDocumentsAction,
  DeleteDocumentsActions,
  FinishDeletionDocumentsError,
  FinishDeletionDocumentsStart,
  FinishDeletionDocumentsSuccess,
} from 'src/app/pages/my-documents/store/actions/my-documents.actions';
import {
  ConfirmationDialogComponent,
} from 'src/app/partials/confirmation-dialog/confirmation-dialog/confirmation-dialog.component';
import { Notifications } from 'src/app/shared/models/notifications';
import { ErrorHelperService } from 'src/app/shared/services/error-helper.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import {
  FileAction,
  FileActions,
  FileUpdated,
  FileUpgrade,
  FileUpgradeStart,
  FileUpgradeSuccess,
  FileUploadError,
  FileUploadProgress,
  FileUploadStart,
  FileUploadSuccess,
  NotEnoughCredits,
  PatchDocument,
  PatchDocumentError,
  PatchDocumentSuccess,
} from '../actions/file.action';
import { CreateFileOrder } from '../actions/order-file.actions';
import { AppState } from '../models/app.models';
import {
    getDocument,
    getFile,
    getFileParentGuid,
    isUpgradingFile,
    upgradedFile,
} from '../selectors/file.selectors';
import {getFileCreditsValue} from "../../../shared/utils/get-file-credits-value";

@Injectable()
export class FileEffects {

  /**
   * Whenever the file is updated and user is authorized,
   * upload file.
   */

  public readonly uploadWhenReady = createEffect(() => this.storeActions.pipe(
    ofType<FileUpdated>(FileActions.FILE_UPDATED),
    switchMap(({ payload: { file, folder, is_free, uploadNow } }) => combineLatest([
        this.store.select(getIsAuthorized),
        this.store.select(limitExceededState),
      ])
        .pipe(
          // as soon as user is authorized an
          filter(([authorized, exceeded]) =>
            authorized && ((exceeded.isLimitExceeded === false && is_free) || !is_free)),
          // let's not consider user logging-off and on again to re-upload to different account
          filter(() => !!uploadNow),
          first(),
          mapTo(new FileUploadStart({ file, folder, is_free })),
        )),
  ));


  public fileUpload = createEffect(() => this.storeActions.pipe(
    ofType<FileUploadStart>(FileActions.FILE_UPLOAD_START),
    switchMap((action: FileUploadStart) => this.mytitleServiceClient.uploadFile(action.payload)
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              // Compute and show the % done:
              const percentDone = Math.round(100 * event.loaded / event.total);
              return new FileUploadProgress({ percentDone });
            case HttpEventType.Response:
              event.body.is_free = action.payload.is_free;
              return new FileUploadSuccess(event.body);
          }
          return null;
        }),
        filter(response => !!response),
        catchError((error: HttpErrorResponse) => of(new FileUploadError(error.error))),
      ),
    ),
  ));


  public readonly redirectOnUploadError = createEffect(() => this.storeActions.pipe(
    ofType<FileUploadError>(FileActions.FILE_UPLOAD_ERROR),
    tap(() => {
      this.snackbarService.queueSnackBar(Notifications.Unexpected);
      this.router.navigate([AppRoutes.MyDocuments]);
    }),
  ), { dispatch: false });


  public readonly fileUpgrade = createEffect(() => this.storeActions.pipe(
    ofType<FileUpgrade>(FileActions.FILE_UPGRADE),
    switchMap(() => this.store
        .select(getCreditBalance)
        .pipe(first())),
    filter(credits => {
      if (credits) {
        return true;
      } else {
        this.snackbarService.queueSnackBar(Notifications.NotEnoughCredits);
        return false;
      }
    }),
    switchMap(() => this.matDialog.open<ConfirmationDialogComponent, string>(
        ConfirmationDialogComponent, {
        data: Notifications.Upgrade })
        .afterClosed()
        .pipe(
          tap(confirmed => {
            if (confirmed) {
              this.router.navigate([AppRoutes.DocumentCredentials, {upgrade: true}]);
            }
          }),
        )),
  ), { dispatch: false });


  public fileUpgradeStart = createEffect(() => this.storeActions.pipe(
    ofType<FileUpgradeStart>(FileActions.FILE_UPGRADE_START),
    switchMap(action => this.billingService.createFileOrder(action.payload)
        .pipe(
          map(response => new FileUpgradeSuccess(response)),
          this.errorHelperService.raiseError(),
        )),
  ));


  public fileUpgradeSuccess = createEffect(() => this.storeActions.pipe(
    ofType<FileUpgradeSuccess>(FileActions.FILE_UPGRADE_SUCCESS),
    switchMap(() => this.store
        .select(getFileParentGuid)
        .pipe(first())),
    delay(2500),
    tap(folder => {
      if (folder) {
        this.router.navigate(
          [AppRoutes.MyDocuments],
          { queryParams: { folder } },
        );
      } else {
        this.router.navigate([AppRoutes.MyDocuments]);
      }
      this.snackbarService.queueSnackBar(Notifications.DocUpgradeSuccess);
    }),
    map(() => new GetCreditBalance()),
  ));


  public patchDocument = createEffect(() => this.storeActions.pipe(
    ofType<PatchDocument>(FileActions.PATCH_DOCUMENT),
    switchMap(action => forkJoin([
        of(action.payload),
        this.store.select(upgradedFile).pipe(first()),
        this.store.select(getDocument).pipe(first()),
      ])),
    filter(([upgradedDocGuid, newDocGuid]) => !!upgradedDocGuid || !!newDocGuid),
    switchMap(([payload, upgradedDoc, newDoc]) => {
      let guid = '';
      if (upgradedDoc && upgradedDoc.order_items && upgradedDoc.order_items.length &&
        upgradedDoc.order_items[0] && upgradedDoc.order_items[0].document_guid) {
          guid = upgradedDoc.order_items[0].document_guid;
        } else {
          if (newDoc) {
            guid = newDoc.guid;
          }
        }
        return this.mytitleServiceClient.patchDocument(guid, payload).pipe(
          map(result => new PatchDocumentSuccess(result)),
          catchError((error: HttpErrorResponse) => of(new PatchDocumentError(error.error))),
        );
    }),
  ));


  public readonly handleFileOrderError$ = createEffect(() => this.storeActions.pipe(
    ofType(FileActions.PATCH_DOCUMENT_ERROR),
    tap(() => {
      setTimeout(() => {
      this.snackbarService.queueSnackBar(Notifications.FileOrderError);
        this.router.navigate([AppRoutes.MyDocuments]);
      }, 2000);
    }),
  ), { dispatch: false });


  public readonly createOrderAfterPatchedDocument = createEffect(() => this.storeActions.pipe(
    ofType(FileActions.PATCH_DOCUMENT_SUCCESS),
    switchMap(() => this.store.select(getIsAuthorized).pipe(first())),
    filter(isAuthorized => isAuthorized),
    switchMap(() => forkJoin([
        this.store.select(getCreditBalance).pipe(first()),
        this.store.select(isUpgradingFile).pipe(first()),
        this.store.select(upgradedFile).pipe(first()),
        this.store.select(getFile).pipe(first()),
        this.store.select(getIsValidAcademy).pipe(first()),
      ])),
    map(([creditBalance, upgradingFile, isUpgradedFile, file, isAcademy]) => {
      const filesize = upgradingFile ? 0 : file.size
      const hasCredits = creditBalance >= getFileCreditsValue(filesize, isAcademy)
      if (upgradingFile && hasCredits) {
        return new FileUpgradeStart(isUpgradedFile);
      } else if (hasCredits) {
        return new CreateFileOrder();
      } else {
        return new NotEnoughCredits();
      }
    }),
  ));


  public readonly createFreeOrderAfterPriceSelect = createEffect(() => this.storeActions.pipe(
    ofType(FileActions.FILE_UPLOAD_SUCCESS),
    filter(action => action.payload.is_free === true),
    map(() => new CreateFileOrder()),
  ));


  public readonly redirectToCreditBundles = createEffect(() => this.storeActions.pipe(
    ofType<NotEnoughCredits>(FileActions.NOT_ENOUGH_CREDITS),
    map(() =>
      this.router.navigate([AppRoutes.CreditBundleChoice], { queryParams: { fileFlow: true } })),
  ), { dispatch: false });


  public finishDocumentsDeletion = createEffect(() => this.storeActions.pipe(
    ofType<FinishDeletionDocumentsStart>(DeleteDocumentsActions.FINISH_DELETION_DOCUMENTS_START),
    switchMap(action => this.mytitleServiceClient.finishDocumentsDeletion(action.payload)
      .pipe(
        map(result => new FinishDeletionDocumentsSuccess(result)),
        catchError((error: HttpErrorResponse) => of(new FinishDeletionDocumentsError(error.error))),
      ),
    ),
  ));

  constructor(
    private storeActions: Actions<FileAction | DeleteDocumentsAction>,
    private store: Store<AppState>,
    private router: Router,
    private mytitleServiceClient: MytitleServiceClient,
    private snackbarService: SnackbarService,
    private errorHelperService: ErrorHelperService,
    private billingService: BillingServiceClient,
    private matDialog: MatDialog,
  ) {
  }
}
