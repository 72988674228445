import { GOPAY_PAYMENT_COUNTRIES } from 'src/app/app/services/gopay-payment.service';
import { PaymentGateways } from 'src/app/client/models/payment.models';
import { environment } from 'src/environments/environment';

export function getPaymentGateway(): PaymentGateways {
  const currentLanguage = environment.language;

  if (GOPAY_PAYMENT_COUNTRIES.includes(currentLanguage)) {
    return PaymentGateways.GOPAY;
  } else {
    throw new Error(`Language ${currentLanguage} is not part of any payment
    gateway country definition. Please add it.`);
  }
}
