import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { ScrollModule } from '../../app/directives/scroll/scroll.module';
import { DropZoneModule } from '../drop-zone/drop-zone.module';
import { FolderUploadModule } from '../folder-upload/folder-upload.module';
import { UserIndicatorModule } from '../user-indicator/user-indicator.module';
import { NavbarMobileComponent } from './navbar-mobile/navbar-mobile.component';
import { NavbarComponent } from './navbar/navbar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatDividerModule,
    UserIndicatorModule,
    MatMenuModule,
    DropZoneModule,
    FolderUploadModule,
    ScrollModule,
  ],
  declarations: [
    NavbarComponent,
    NavbarMobileComponent,
  ],
  exports: [
    NavbarComponent,
    NavbarMobileComponent,
  ],
})
export class NavbarModule {
}
