/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';

import { MytitleError } from 'src/app/client/models/client.models';
import {
  DocumentActivityParams,
  DocumentActivityResponse,
} from 'src/app/client/models/mytitle-service.models';
import { ActivityData } from '../../partials/activity-dialog/activity-dialog.component';
import { DocumentActivityState } from '../reducers/document-activity.reducer';

export enum DocumentActivityActions {
  GET_FILE_ACTIVITY = '[MyDocuments] Get file activity',
  GET_FILE_ACTIVITY_SUCCESS = '[MyDocuments] Get file activity success',
  GET_FILE_ACTIVITY_ERROR = '[MyDocuments] Get file activity error',

  GET_FOLDER_ACTIVITY = '[MyDocuments] Get folder activity',
  GET_FOLDER_ACTIVITY_SUCCESS = '[MyDocuments] Get folder activity success',
  GET_FOLDER_ACTIVITY_ERROR = '[MyDocuments] Get folder activity error',

  OPEN_ACTIVITY_DIALOG = '[MyDocuments] Open activity dialog',
  ACTIVITY_DIALOG_CLOSED = '[MyDocuments] Activity dialog closed',
}

export class GetFileActivity implements Action {
  public readonly type = DocumentActivityActions.GET_FILE_ACTIVITY;
  constructor(public payload: DocumentActivityParams) {}
}

export class GetFileActivitySuccess implements Action {
  public readonly type = DocumentActivityActions.GET_FILE_ACTIVITY_SUCCESS;
  constructor(public payload: DocumentActivityResponse[]) {}
}

export class GetFileActivityError implements Action {
  public readonly type = DocumentActivityActions.GET_FILE_ACTIVITY_ERROR;
  constructor(public payload: MytitleError[]) {}
}

export class GetFolderActivity implements Action {
  public readonly type = DocumentActivityActions.GET_FOLDER_ACTIVITY;
  constructor(public payload: DocumentActivityParams) {}
}

export class GetFolderActivitySuccess implements Action {
  public readonly type = DocumentActivityActions.GET_FOLDER_ACTIVITY_SUCCESS;
  constructor(public payload: DocumentActivityResponse[]) {}
}

export class GetFolderActivityError implements Action {
  public readonly type = DocumentActivityActions.GET_FOLDER_ACTIVITY_ERROR;
  constructor(public payload: MytitleError[]) {}
}

export class OpenActivityDialog implements Action {
  public readonly type = DocumentActivityActions.OPEN_ACTIVITY_DIALOG;
  constructor(public payload: ActivityData) {}
}

export class ActivityDialogClosed implements Action {
  public readonly type = DocumentActivityActions.ACTIVITY_DIALOG_CLOSED;
  constructor(public payload: DocumentActivityState) {}
}

export type DocumentActivityAction =
| GetFileActivity
| GetFileActivitySuccess
| GetFileActivityError
| OpenActivityDialog
| ActivityDialogClosed
| GetFolderActivity
| GetFolderActivitySuccess
| GetFolderActivityError;
