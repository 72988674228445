import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import { RegisterAction, RegisterActions } from '../actions/register.action';

export interface RegisterState {
  fullName: string;
  email: string;
  password: string;
  country: string;
  registering: boolean;
  registered: boolean;
  registerError: MytitleError[];
}

const registerInitialState: RegisterState = {
  fullName: null,
  email: null,
  password: null,
  country: null,
  registering: false,
  registered: false,
  registerError: null,
};

export function registerReducer(
  state = registerInitialState,
  generic: Action,
): RegisterState {
  // Typing here because of strictFunctionTypes
  // prevents setting the `action` type
  const action = generic as RegisterAction;

  switch (action.type) {
    case RegisterActions.REGISTER: {
      return {
        ...state,
        ...action.payload,
        registering: true,
      };
    }

    case RegisterActions.REGISTER_ERROR: {
      return {
        ...state,
        registering: false,
        registerError: action.payload,
      };
    }
    case RegisterActions.REGISTER_SUCCESS: {
      return {
        ...state,
        registering: false,
        registered: true,
      };
    }

    case RegisterActions.REGISTER_CLEAN:
      return registerInitialState;
  }

  return state;
}
