import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { getColorTheme } from 'src/app/app/states/selectors/layout.selectors';
import {
  getCreditBalance,
  getIsAuthorized,
} from 'src/app/auth/store/selectors/user.selector';
import { AboutRoutes } from 'src/app/pages/about/about.routes.misc';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { UniversalService } from 'src/app/shared/universal/universal.service';
import { UploadService } from '../../../app/services/upload.service';

@Component({
  selector: 'mt-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {

  @Input() public initializeHeaderElement = true;
  @Input() public isSticky = false;
  @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger;
  public readonly isLoggedIn: Observable<boolean>;
  public readonly creditBalance: Observable<number>;
  public readonly appRoutes = AppRoutes;
  public readonly aboutRoutes = AboutRoutes;
  public readonly smallerScreen: boolean;
  public readonly mediumScreen: boolean;
  public readonly colorTheme: Observable<string>;
  public height: number;

  constructor(private store: Store<any>,
              private media: MediaMatcher,
              private uniService: UniversalService,
              private enviService: EnvironmentService,
              public router: Router,
              public uploadService: UploadService,
              ) {

    this.smallerScreen = this.media.matchMedia('(max-width: 767px)').matches;
    this.mediumScreen = this.media.matchMedia('(max-width: 991px)').matches;

    this.colorTheme = this.store.pipe(select(getColorTheme));
    this.isLoggedIn = this.store.pipe(select(getIsAuthorized));
    this.creditBalance = this.store.pipe(select(getCreditBalance));
  }

  public getBusiness() {
    if (!this.uniService.isPlatformServer()) {
      if (location.hostname.includes('localhost') || location.hostname.includes('dev')) {
        return 'https://business.dev2.mytitle.com';
      } else {
        return 'https://business.mytitle.com';
      }
    } else {
      return 'https://business.mytitle.com';
    }
  }

  public getBlog() {
    if (!this.uniService.isPlatformServer()) {
      if (this.enviService.isCzechEnvironment) {
        return 'https://mytitle.cz/blog/';
      } else {
        return 'https://mytitle.com/blog/';
      }
    } else {
      return 'https://mytitle.com/blog/';
    }
  }

  public closeMenu() {
    this.trigger.closeMenu();
  }

}
