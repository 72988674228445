import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mt-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss'],
})
export class VideoDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public text: string,
    private matDialogRef: MatDialogRef<VideoDialogComponent, boolean>,
  ) {}

  public close() {
    this.matDialogRef.close();
  }

}
