import { GopayPaymentCreated } from './gopay.models';

export enum PaymentGateways {
  GOPAY = 'gopay',
}

export interface CreatePaymentRequest {
  credit_order_guid: string;
  payment_gateway: PaymentGateways;
}

export interface CreatePaymentResponse {
  gopay?: GopayPaymentCreated;
}
