import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AppState } from 'src/app/app/states/models/app.models';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { UniversalService } from 'src/app/shared/universal/universal.service';

@Component({
  selector: 'mt-blog-section',
  templateUrl: './blog-section.component.html',
  styleUrls: ['./blog-section.component.scss'],
})
export class BlogSectionComponent implements OnDestroy {
  public selectedContainerId = 0;

  public carouselImages = [
    '/assets/blog-section/article-one.svg',
    '/assets/blog-section/article-two.svg',
    '/assets/blog-section/article-three.svg',
    '/assets/blog-section/article-four.svg',
  ];

  public carouselImagesMb = [
    '/assets/blog-section/article-mobile-one.png',
    '/assets/blog-section/article-mobile-two.png',
    '/assets/blog-section/article-mobile-three.png',
    '/assets/blog-section/article-mobile-four.png',
  ];

  public carouselHeadline = [
    '3 Reasons to Protect Your IP',
    'How to protect ideas and inventions?',
    'How to protect your artwork?',
    'How to protect your text and literary work?',
  ];

  public carouselHeadlineCs = [
    'Digitální certifikace vlastnictví dat',
    'Chraňte svou hudební tvorbu',
    'Blockchain pro ochranu vašeho vlastnictví',
    'Jak zabezpečit umělecké portfolio?',
  ];

  public hashtags = [
    '#intellectualproperty',
    '#infringement',
    '#artwork',
    '#textprotection',
  ];

  public hastagsCs = [
    '#digitalnicertifikace',
    '#hudba',
    '#blockchain',
    '#grafika',
  ];

  private ngDestroy = new Subject<void>();

  constructor(
    public store: Store<AppState>,
    private media: MediaMatcher,
    private enviService: EnvironmentService,
    private uniService: UniversalService,
  ) {
      if (!this.uniService.isPlatformServer()) {
        if (this.enviService.isCzechEnvironment) {
          this.carouselHeadline = this.carouselHeadlineCs;
          this.hashtags = this.hastagsCs;
        }
      }
      if (this.media.matchMedia('(max-width: 768px)').matches) {
        this.carouselImages = this.carouselImagesMb;
      }
  }

  public selectContainer(selected: any) {
    this.selectedContainerId = selected.index;
  }

  public ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
