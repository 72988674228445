import { Directive } from '@angular/core';
import { ScrollSectionDirective } from './scroll-section.directive';

/**
 * https://netbasal.com/anchors-away-how-to-create-smooth-scrolling-in-angular-a657e0ea9699
 */
@Directive({
  selector: '[mtScrollManager]',
})
export class ScrollManagerDirective {
  private sections = new Map<string | number, ScrollSectionDirective>();

  scroll(id: string | number) {
    this.sections.get(id)?.scrollToTargetAdjusted();
  }

  register(section: ScrollSectionDirective) {
    this.sections.set(section.id, section);
  }

  remove(section: ScrollSectionDirective) {
    this.sections.delete(section.id);
  }
}
