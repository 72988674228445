import { Injectable } from '@angular/core';
import { UniversalService } from '../universal/universal.service';
import { DataLayerEvent } from './data-layer.events';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {

  constructor(
    private universalService: UniversalService,
  ) { }

  public push(event: DataLayerEvent) {
    if (this.universalService.isPlatformServer()) {
      return;
    }
    const windowObj: any = window;
    if (windowObj?.dataLayer) windowObj.dataLayer.push(event);
  }
}
