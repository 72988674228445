import { Environment } from './models/environment.models';
import { AvailableLanguageMutations } from './models/language.models';

export const environment: Environment = {
  production: true,
  apiUrl: 'https://dev2.mytitle.com',
  language: AvailableLanguageMutations.ENGLISH,
  version: 'd54b23f975f8251b94f057f5d8fd771270bf64c9',
  googleClientId: '936658844308-24o7ksku00llr7jl5b09p18h2p3me3ov.apps.googleusercontent.com',
};
